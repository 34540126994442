<!-- The Modal -->
<div class="modal fade" id="myModalTm" data-backdrop="static" data-keyboard="false" >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content text-center" style="border-radius: 10px" >
        <div class="modal-body">
           <div class="form-group">
                <h2> Session Expiring!</h2>              
                  <h4>
                    Your session is about to expire in {{autoLoggOffTime}} seconds.
                  </h4><br />
                  <button class="btn btn-default" data-dismiss="modal" (click)="setLogoff()">Log Off</button>
                   &nbsp;&nbsp; <button class="btn btn-default" data-dismiss="modal" (click)="reSetLogoff()">Continue Working</button>              
           </div> 
        </div>
      </div>
    </div>
  </div>