import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var HeaderService = /** @class */ (function () {
    function HeaderService(http) {
        this.http = http;
        this.url = environment.apiBase;
        this.userId = localStorage.getItem('userId');
        this.newsCountSource = new BehaviorSubject(0);
        this.newsCount = this.newsCountSource.asObservable();
        this.companyNameSource = new BehaviorSubject('');
        this.companyName = this.companyNameSource.asObservable();
    }
    HeaderService.prototype.updatedNewsCount = function (newsCount) {
        this.newsCountSource.next(newsCount);
    };
    HeaderService.prototype.updatedCompanyName = function (companyName) {
        this.companyNameSource.next(companyName);
    };
    HeaderService.prototype.setNews = function (msg) {
        this.messages = msg;
    };
    HeaderService.prototype.getNews = function () {
        return this.messages;
    };
    HeaderService.prototype.getClient = function (clientId) {
        return this.http
            .get(this.url + 'api/Clients/' + clientId)
            .pipe(tap(function (res) { }));
    };
    HeaderService.prototype.getUnreadNews = function (id) {
        return this.http
            .get(this.url + 'api/News/GetUnReadNewsCount?userId=' + id)
            .pipe(tap(function (res) { }));
    };
    HeaderService.prototype.getPin = function (id, pin) {
        return this.http
            .get(this.url + 'api/Clients/Pin/' + id + '/' + pin)
            .pipe(tap(function (res) { }));
    };
    HeaderService.prototype.getChosenPin = function (id, chosenPin) {
        var headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.http
            .post(this.url + 'api/Clients/GetChosenPin/' + id + '/' + chosenPin, { headers: headers, responseType: 'text' })
            .pipe(tap(function (res) { }));
    };
    HeaderService.ngInjectableDef = i0.defineInjectable({ factory: function HeaderService_Factory() { return new HeaderService(i0.inject(i1.HttpClient)); }, token: HeaderService, providedIn: "root" });
    return HeaderService;
}());
export { HeaderService };
