import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';

import { LoaderService } from '../loader/loader.service';
import { Observable, pipe } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MemberService } from '../Adjuster/member/member.service';
import { TransactionService } from '../Admin/transaction/transaction.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private loaderService: LoaderService,
    private memberService: MemberService,
    private transactionService: TransactionService,
    private router: Router
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    let currentUser = localStorage.getItem('token');

    if (currentUser) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser}`
        }
      });
    }

    var a = this.memberService.getItemcall();
    var b = this.transactionService.getLoader();
    if (a == '1' || !b) {
    } else {
      this.showLoader();
    }
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.onEnd();
          }
        },
        (err: any) => {
          this.onEnd();
        }
      )
    );
  }
  private onEnd(): void {
    this.hideLoader();
  }
  private showLoader(): void {
    this.loaderService.show();
  }
  private hideLoader(): void {
    this.loaderService.hide();
  }
}
