import { Component, OnInit } from '@angular/core';
import { GeneratePasswordService } from './generatepassword.service';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from '../login/login.service';

@Component({
  selector: 'app-generatepassword',
  templateUrl: './generatepassword.component.html',
  styleUrls: ['./generatepassword.component.css']
})
export class GeneratepasswordComponent implements OnInit {
  // variables
  pwdDto: PwdDto = new PwdDto();
  submitted: boolean;
  error: any;
  userId;
  imgUrl: any;

  constructor(
    private loginService: LoginService,
    private route: ActivatedRoute,
    private router: Router,
    private generatePasswordService: GeneratePasswordService,
    private notifier: NotifierService
  ) {}

  ngOnInit() {
    let submainName = window.location.hostname.split(".")[0];
    this.loginService.getImageUrl(submainName).subscribe(res => {
      this.imgUrl = res;
    });
    this.userId = this.route.snapshot.queryParamMap.get('UserId');
    this.userId = this.userId.replace(/ /g, '+');
    this.validate(this.userId);
  }

  // On form submit
  onSubmit() {
    this.submitted = true;
    this.reset();
  }

  // Reset password API
  reset() {
    const body = {
      userId: localStorage.getItem('email'),
      value: this.pwdDto.newPwd
    };
    this.generatePasswordService.reset(body).subscribe(
      res => {
        this.pwdDto.newPwd='';
        this.notifier.notify(
          'success',
          'Password changed! Please login again.'
        );
        setTimeout(() => {
          localStorage.clear();
          this.router.navigate(['/login']);
        }, 2000);
      },
      error => {
        this.error = error;
        this.notifier.notify('error', error.text);
      }
    );
  }

  // Validate incoming user API
  validate(id) {
    this.generatePasswordService.validate(this.userId).subscribe(
      res => {
        if (res != null) {
          localStorage.setItem('email', res);
          this.router.navigate(['generatenewpassword']);
        }
      },
      error => {
        this.error = error;
        this.notifier.notify('error', error.error);

        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 2000);
      }
    );
  }
}

// Password DTO
export class PwdDto {
  oldPwd: string;
  newPwd: string;
  conNewPwd: string;
}
