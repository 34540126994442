import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';

@Injectable()
export class CommanUtils {

    constructor() { }

    public convertToInternational(number): any {
        let value = '';
        if (number == '' || number == null || number == undefined) {
        } else {
            const b = "-";
            let position = 3;
            const output = [number.slice(0, position), b, number.slice(position)].join('');
            position = 7;
            value = [output.slice(0, position), b, output.slice(position)].join('');
        }
        return value;
    }

    public onKeyChangeNumberFormat(event, number, index1: number, index2: number): any {
        let value = number;
        if (event.keyCode !== 8) {
            if (value.length == index1 || value.length == index2) {
                value += '-';
            }
        }
        return value;
    }

    public validateDate(date): boolean {
        const dateRegEx = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/\d{4}$/;
        return dateRegEx.test(date);
    }


    public aplhanumeric(item) {
        var pattern = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/

        if (item === '') {
            return false;
        } else {
            if (isNaN(item)) {
                if (pattern.test(item)) {
                    return true
                } else {
                    return false;
                }
            } else {
                return true
            }
        }
    }

    public specialCharater(item) {
        var pattern = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/
        if (item === '') {
            return false;
        } else {
            if (pattern.test(item)) {
                return true
            } else {
                return false;
            }
        }
    }

    public phoneNumberFormatValidation(item) {
        if (item.length == 12) {
            var phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
            if (phoneRegex.test(item)) {
                return false
            } else {
                return true
            }
        } else {
            return true
        }
    }
    public phoneChange(event, item) {
        if (event.keyCode !== 8) {
            if (item.length == 3 || item.length == 7) {
                item += '-';
            }
            return item
        }
    }

    public exportAsExcel(contentId, name): void {
        let fileName = name + '.xls';
        const blob = new Blob([document.getElementById(contentId).innerHTML], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
        });
        FileSaver.saveAs(blob, fileName);
    }

    public printFunction(contentId, title): void {
        let printContents, popupWin;
        printContents = document.getElementById(contentId).innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
        <html>
            <head>
                <title>`+ title + `</title>
                <style>
                //........Customized style.......
                </style>
            </head>
            <body onload="window.print();window.close()" style="text-align:center;">${printContents}</body>
        </html>`
        );
        popupWin.document.close();
    }

}