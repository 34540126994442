import { throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DrugLookupService = /** @class */ (function () {
    function DrugLookupService(http) {
        this.http = http;
        this.url = environment.apiBase;
    }
    DrugLookupService.prototype.getMemberDetails = function (memberId) {
        return this.http
            .get(this.url + 'api/Members?memberid=' + memberId)
            .pipe(catchError(this.handleError));
    };
    DrugLookupService.prototype.getDrugDetails = function (member, body) {
        return this.http
            .get(this.url +
            'api/Drugs/' +
            member +
            '?drugName=' +
            body.name +
            '&ndcNumber=' +
            body.ndcNumber +
            '&gpiNumber=' +
            body.gpiNumber)
            .pipe(catchError(this.handleError));
    };
    DrugLookupService.prototype.getCommonUses = function (body) {
        return this.http
            .get(this.url + 'api/Drugs/GetDrugUses?gpiNumber=' + body)
            .pipe(tap(function (res) { }));
    };
    DrugLookupService.prototype.postDrug = function (body) {
        return this.http
            .post(this.url + 'api/Drugs', body, { responseType: 'text' })
            .pipe(tap(function (res) { }));
    };
    DrugLookupService.prototype.updateDrug = function (body) {
        var headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.http
            .post(this.url + 'api/Drugs/UpdateDrugPreference', body, {
            headers: headers,
            responseType: 'text'
        })
            .pipe();
    };
    DrugLookupService.prototype.blockDrugs = function (body) {
        return this.http
            .put(this.url + 'api/Drugs', body, { responseType: 'text' })
            .pipe();
    };
    DrugLookupService.prototype.preferedDrugs = function (memberId) {
        return this.http
            .get(this.url + 'api/Drugs?memberId=' + memberId)
            .pipe(tap(function (res) { }));
    };
    DrugLookupService.prototype.deleteDrug = function (body) {
        var options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            body: body,
            responseType: 'text'
        };
        return this.http
            .delete(this.url + 'api/Drugs', options)
            .pipe(catchError(this.handleError));
    };
    DrugLookupService.prototype.handleError = function (error) {
        var errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = "Error: " + error.error.message;
        }
        else {
            // server-side error
            if (error.status >= 400 && error.status < 500) {
                errorMessage = 'Action failed due to bad request';
            }
            else if (error.status >= 500) {
                errorMessage = 'Action failed due to server side issue';
            }
        }
        return throwError(errorMessage);
    };
    DrugLookupService.prototype.getPdfDownload = function (body) {
        var query = '0?drugName=' +
            body.name +
            '&ndcNumber=' +
            body.ndcNumber +
            '&gpiNumber=' +
            body.gpiNumber;
        var value = {
            key: 0,
            value: query + '&clientId=' + localStorage.getItem('clientId')
        };
        return this.http
            .post(this.url + 'api/Drugs/DrugResultAsPdf', value, {
            responseType: 'blob'
        })
            .pipe(catchError(this.handleError));
    };
    DrugLookupService.ngInjectableDef = i0.defineInjectable({ factory: function DrugLookupService_Factory() { return new DrugLookupService(i0.inject(i1.HttpClient)); }, token: DrugLookupService, providedIn: "root" });
    return DrugLookupService;
}());
export { DrugLookupService };
