import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Subject, timer, Subscription } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { LoginService } from './login/login.service';

// jQuery declaration
declare var $;
@Component({
  selector: 'inactivity-timer',
  templateUrl: './inactive-timer.component.html',
  styles: []
})
export class InactivityTimerComponent implements OnDestroy, OnInit {
  @Input('parentData') adminPage: boolean;
  minutesDisplay;
  secondsDisplay;

  endTime = 30;
  autoLoggOffTime = 30;
  unsubscribe$: Subject<void> = new Subject();
  timerSubscription: Subscription;
  autoLoggOffTimer: Subscription;

  constructor(private authService: LoginService) {}

  ngOnInit() {
    this.resetTimer();
    this.authService.userActionOccured
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        if (this.timerSubscription) {
          this.timerSubscription.unsubscribe();
        }
        this.resetTimer();
      });
      
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }  
  resetTimer(endTime: number = this.endTime) {
    const interval = 1000;
    const duration = endTime * 60;   
    this.timerSubscription = timer(0, interval)
      .pipe(take(duration))
      .subscribe(
        value => this.render((duration - +value) * interval),
        err => {},
        () => {
          this.authService.logout();
        }
      );
  }

  private render(count) {
    
    this.secondsDisplay = this.getSeconds(count);
    this.minutesDisplay = this.getMinutes(count);    

    if (this.secondsDisplay === '30' && this.minutesDisplay === '00' && localStorage.getItem('token'))
    {
      this.autoLoggOffTime = 30;
      $('#myModalTm').modal('show');
      this.autoLoggOffTimer = timer(0, 1000).subscribe(t => {
        this.autoLoggOffTime -=1;
      });
    }
    else  if ( ( $('#myModalTm').hasClass('show') && this.autoLoggOffTime < 1)  || (this.secondsDisplay === '01' && this.minutesDisplay === '00' )) {
      this.setLogoff('0');
    }    
  }
  reSetLogoff(){
    $('#myModalTm').modal('hide');    
    if (this.autoLoggOffTimer) {
      this.autoLoggOffTimer.unsubscribe();
    } 
    this.autoLoggOffTime = 30;
    this.authService.notifyUserAction();   
  }
  setLogoff(islogOut:string='1'){
    $('#myModalTm').modal('hide');
    this.autoLoggOffTime = 30;
    if (this.autoLoggOffTimer) {
      this.autoLoggOffTimer.unsubscribe();
    }    
    this.authService.logout(); 
    localStorage.setItem('logOut', islogOut);   
  }
  private getSeconds(ticks: number) {
    const seconds = ((ticks % 60000) / 1000).toFixed(0);
    return this.pad(seconds);
  }

  private getMinutes(ticks: number) {
    const minutes = Math.floor(ticks / 60000);
    return this.pad(minutes);
  }

  private pad(digit: any) {
    return digit <= 9 ? '0' + digit : digit;
  }
 
}
