/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./unsubscribe.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./unsubscribe.component";
import * as i4 from "@angular/router";
import * as i5 from "./unsubscribe.service";
var styles_UnsubscribeComponent = [i0.styles];
var RenderType_UnsubscribeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UnsubscribeComponent, data: {} });
export { RenderType_UnsubscribeComponent as RenderType_UnsubscribeComponent };
function View_UnsubscribeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "bg-white divunsuscribe"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Dear User, your mobile number is unsubscribed from the CompPBM."]))], null, null); }
export function View_UnsubscribeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["."])), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["style", "padding: 10px;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UnsubscribeComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.success; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_UnsubscribeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-unsubscribe", [], null, null, null, View_UnsubscribeComponent_0, RenderType_UnsubscribeComponent)), i1.ɵdid(1, 114688, null, 0, i3.UnsubscribeComponent, [i4.ActivatedRoute, i4.Router, i5.UnsubscribeService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UnsubscribeComponentNgFactory = i1.ɵccf("app-unsubscribe", i3.UnsubscribeComponent, View_UnsubscribeComponent_Host_0, {}, {}, []);
export { UnsubscribeComponentNgFactory as UnsubscribeComponentNgFactory };
