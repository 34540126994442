import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ForgotpasswordService = /** @class */ (function () {
    function ForgotpasswordService(http) {
        this.http = http;
        this.url = environment.apiBase;
        this.mail = encodeURIComponent(localStorage.getItem('email'));
    }
    ForgotpasswordService.prototype.forgotpwd = function (mail) {
        return this.http.put(this.url + "api/Account/ForgotPassword?email=" + mail, '').pipe(tap(function (res) {
        }));
    };
    ForgotpasswordService.ngInjectableDef = i0.defineInjectable({ factory: function ForgotpasswordService_Factory() { return new ForgotpasswordService(i0.inject(i1.HttpClient)); }, token: ForgotpasswordService, providedIn: "root" });
    return ForgotpasswordService;
}());
export { ForgotpasswordService };
