/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./inactive-timer.component";
import * as i2 from "./login/login.service";
var styles_InactivityTimerComponent = [];
var RenderType_InactivityTimerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InactivityTimerComponent, data: {} });
export { RenderType_InactivityTimerComponent as RenderType_InactivityTimerComponent };
export function View_InactivityTimerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "div", [["class", "modal fade"], ["data-backdrop", "static"], ["data-keyboard", "false"], ["id", "myModalTm"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 13, "div", [["class", "modal-dialog modal-dialog-centered"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 12, "div", [["class", "modal-content text-center"], ["style", "border-radius: 10px"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 11, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 10, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Session Expiring!"])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, [" Your session is about to expire in ", " seconds. "])), (_l()(), i0.ɵeld(9, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "button", [["class", "btn btn-default"], ["data-dismiss", "modal"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setLogoff() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Log Off"])), (_l()(), i0.ɵted(-1, null, [" \u00A0\u00A0 "])), (_l()(), i0.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-default"], ["data-dismiss", "modal"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reSetLogoff() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Continue Working"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.autoLoggOffTime; _ck(_v, 8, 0, currVal_0); }); }
export function View_InactivityTimerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "inactivity-timer", [], null, null, null, View_InactivityTimerComponent_0, RenderType_InactivityTimerComponent)), i0.ɵdid(1, 245760, null, 0, i1.InactivityTimerComponent, [i2.LoginService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InactivityTimerComponentNgFactory = i0.ɵccf("inactivity-timer", i1.InactivityTimerComponent, View_InactivityTimerComponent_Host_0, { adminPage: "parentData" }, {}, []);
export { InactivityTimerComponentNgFactory as InactivityTimerComponentNgFactory };
