import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CardService } from './card.service';
@Component({
  selector: 'app-attention',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {
  // variables
  data = [];
  userId = '';
  memberCardTemplate: MemberCardTemplate = new MemberCardTemplate();
  logo: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private CardService: CardService
  ) {
    this.route.params.subscribe();
  }

  ngOnInit() {
    this.userId = this.route.snapshot.queryParamMap.get('UserId');
    if (this.userId === '') {
    } else {
      this.router.navigate(['card']);
    }
    this.getMemberCardTemplate();
  }

  // Get all card details API
  getMemberCardTemplate() {
    this.CardService.getMemberCardTemplate(this.userId).subscribe(res => {
      this.memberCardTemplate.clientLogo = res.clientLogo;
      this.memberCardTemplate.programName = res.programName;
      this.memberCardTemplate.clientName = res.clientName;
      this.memberCardTemplate.idNumber = res.idNumber;
      this.memberCardTemplate.memberFirstName = res.memberFirstName;
      this.memberCardTemplate.memberLastName = res.memberLastName;
      this.memberCardTemplate.rxGroup = res.rxGroup;
      this.memberCardTemplate.rxbin = res.rxbin;
      this.memberCardTemplate.pcnNumber = res.pcnNumber;
      this.memberCardTemplate.memberCode = res.memberCode;
      this.memberCardTemplate.pharmacyHelpDeskNumber =
        res.pharmacyHelpDeskNumber;
      this.memberCardTemplate.companyLogo = res.companyLogo;
      this.data = res;
      if (
        this.memberCardTemplate.clientLogo === '' ||
        this.memberCardTemplate.clientLogo === null
      ) {
        this.logo = true;
      } else {
        this.logo = false;
      }
    });
    var a = this.memberCardTemplate.pharmacyHelpDeskNumber;
    var b = '-';
    var position = 4;
    var output = [a.slice(0, position), b, a.slice(position)].join('');
    var position = 8;
    var output2 = [output.slice(0, position), b, output.slice(position)].join(
      ''
    );
    this.memberCardTemplate.pharmacyHelpDeskNumber = output2;
  }
}

// Membercard DTO
export class MemberCardTemplate {
  clientId: number;
  memberId: number;
  clientName: string;
  idNumber: string;
  memberFirstName: string;
  memberLastName: string;
  rxGroup: string;
  rxbin: string;
  pcnNumber: string;
  memberCode: string;
  pharmacyHelpDeskNumber: string;
  memberEmail: string;
  memberContactNumber: string;
  programName: string;
  clientLogo: string;
  companyLogo: string;
}
