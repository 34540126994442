import { Component, OnInit } from '@angular/core';
import { PhysicianLookupService } from './physician-lookup.service';
import { NotifierService } from 'angular-notifier';
import { DatePipe } from '@angular/common';
import { CommanUtils } from 'src/app/Utils/commanUtils.service';
import { saveAs } from 'file-saver/dist/FileSaver';
import { Groups } from '../shared/add-update-member/add-update-member.component';
import { ViewMemberDetialsService } from '../view-member/view-member.service';
import * as moment from 'moment';
import { MemberDetails } from '../view-member/view-member.component';
// jQuery declaration
declare var $;
@Component({
  selector: 'app-physician-lookup',
  templateUrl: './physician-lookup.component.html',
  styleUrls: ['./physician-lookup.component.css']
})

export class PhysicianLookupComponent implements OnInit {
  // variables
  zoom: number = 8;
  //  initial center position for the map
  lat: number = 40.730610;
  lng: number = -73.935242;
  physicianDto: PhysicianDto = new PhysicianDto();
  preferedPhysicianDto: PreferedPhysicianDto = new PreferedPhysicianDto();
  preferedDateDto: PreferedDates = new PreferedDates();
  specializationDto: Specialization = new Specialization();
  memberInfo: MemberDetailInfo = new MemberDetailInfo();
  isquickResposive = false;
  data = [];
  specializationList = [];
  preferedData = [];
  filterData;
  npi: any;
  preferedPhysiciansData;
  rowValue = [];
  showSerachResult: boolean = false;
  noPereferedData: boolean = false;
  noData: boolean = false;
  errorMessage: any;
  successMessage: any;
  memberId: number = 0;
  count: number = 0;
  // = localStorage.getItem('memberId') == null ? '35' : localStorage.getItem('memberId');
  adjusterId: string = localStorage.getItem('userId');
  adjusterName: string = localStorage.getItem('name');
  dateRequried: string = '';
  selectedItems = [];
  dropdownSettings = {};
  startDate3 = new Date();
  endDate3 = new Date();
  minDate: Date = new Date();
  indx: any;
  asc: number = 0;
  direction: number = 0;
  selectedActivity:any =0;
  dir: any = [];
  origin: any;
  destination: any;
  markerz : geoLocation = new geoLocation();
  op:boolean;
  blkPList = [];
  dId: any;
  blk: boolean = false;
  ids: any;
  med: boolean = false;
  preferedPhysicians;
  removeCheckModel: string = '';
  title: string;
  memberInfoDetials: MemberDetails = new MemberDetails();
  startDate1: Date = new Date();
  endDate1: Date = new Date();
  startDate2: Date = new Date();
  endDate2: Date = new Date();
  message: string = '';
  blkd:boolean = false;
  canEdit: boolean = true;
  constructor( private physicianLookupService: PhysicianLookupService,
    private notifier: NotifierService,
    private datePipe: DatePipe,
    private commanUtils: CommanUtils,
    private memberService: ViewMemberDetialsService
  ) {
    this.memberId = localStorage.getItem('memberId') == null ? 0 : parseInt(localStorage.getItem('memberId'));
  }

  ngOnInit() {
    $(document).ready(function () {
      $(window).scrollTop(0);
    });
    // this.route.params.subscribe(params => { this.memberId = params.id == undefined ? 0 : params.id });
    // this.memberId = this.memberProfileService.getMemberProfileId() == null ? 0 : this.memberProfileService.getMemberProfileId();
    // this.memberId = localStorage.getItem('memberId') == null ? 0 : parseInt(localStorage.getItem('memberId'));
    navigator.geolocation.getCurrentPosition((position) => {
    });
    if (this.memberId > 0) {
      this.changeTodefaultDates();
      this.getpreferedPhysiciansList();
      this.getMemberProfileInfo();
      this.getphyBlockedList();
    }
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'speciality',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };
  }

   // Get row details of physicians
   selectPhysicianRow(item) {
    this.blk = false;
    this.med = false;
    this.preferedPhysicians = item;
    this.removeCheckModel = 'physician';
    this.title = 'Doctor';
    this.preferedDateDto.startDate = item.validityStartDate;
    this.preferedDateDto.endDate = item.validityEndDate;
    // this.updateMyEndDate(this.startDate2)
  }
  // Reseponsive view function
  myserachFunction() {
    if (this.isquickResposive) {
      this.isquickResposive = false;
    } else {
      this.isquickResposive = true;
    }
  }

  // Get physicians details API
  getPhysicianDetails() {
    this.physicianLookupService.getPhysicianDetails(this.memberId, this.physicianDto).subscribe(res => {
      this.showSerachResult = true;
      this.data = res;
      if (this.data.length === 0) {
        this.noData = true;
        this.filterData = this.data;
      } else {
        this.noData = false;
        this.filterData = this.data;
      }
    },
      error => {

        this.errorMessage = error.error;
        this.notifier.notify('error', this.errorMessage);
      });
  }

  // Download the pdf for physicians
  getPDFDownload() {
    this.physicianLookupService.getPdfDownload(this.physicianDto).subscribe(res => {
      saveAs(res, 'physiciansList.pdf')
    })
  }

  // Create PreferedPhysician API
  createPreferedPhysician() {
    let startDate = this.datePipe.transform(this.startDate3, 'MM/dd/yyyy');
    let endDate = this.datePipe.transform(this.endDate3, 'MM/dd/yyyy');
    if (this.startDate3 == null) {
      this.notifier.notify('error', 'Please select From Date');
    } else if (this.endDate3 == null) {
      this.notifier.notify('error', 'Please select To Date');
    } else if (!this.commanUtils.validateDate(startDate) || !this.commanUtils.validateDate(endDate)) {
      this.notifier.notify('error', 'Please Provide Valid Date Fields');
    } else if (this.endDate3 <= this.startDate3) {
      this.notifier.notify('error', 'To Date Should be More than From Date!!');
    } else {
      this.preferedPhysicianDto.validityStartDate = startDate;
      this.preferedPhysicianDto.validityEndDate = endDate;
      this.preferedPhysicianDto.memberName = this.memberInfo.name;
      this.physicianLookupService.createPreferedPhysician(this.preferedPhysicianDto).subscribe(res => {
        this.successMessage = 'Physician added Successfully to preferred Physician list';
        this.notifier.notify('success', this.successMessage);

        this.getpreferedPhysiciansList();
        this.getPhysicianDetails();
        this.getphyBlockedList();
      }, error => {
        this.getpreferedPhysiciansList();
        this.getPhysicianDetails();
        this.getphyBlockedList();
        this.errorMessage = error.error;
        this.notifier.notify('error', this.errorMessage);
      });
    }
    $('#myModal1').modal('hide');
  }

  // Block PreferedPhysician API
  blockPreferedPhysician() {
    let startDate = this.datePipe.transform(this.startDate3, 'MM/dd/yyyy');
    let endDate = this.datePipe.transform(this.endDate3, 'MM/dd/yyyy');
    if (this.startDate3 == null) {
      this.notifier.notify('error', 'Please select From Date');
    } else if (this.endDate3 == null) {
      this.notifier.notify('error', 'Please select To Date');
    } else if (!this.commanUtils.validateDate(startDate) || !this.commanUtils.validateDate(endDate)) {
      this.notifier.notify('error', 'Please Provide Valid Date Fields');
    } else if (endDate !== startDate) {
      this.notifier.notify('error', 'To Date Should be same as From Date!!');
    } else {
      this.preferedPhysicianDto.validityStartDate = startDate;
      this.preferedPhysicianDto.validityEndDate = endDate;
      this.preferedPhysicianDto.memberName = this.memberInfo.name;
    this.physicianLookupService.blockPreferedPhysician(this.preferedPhysicianDto).subscribe(res => {
      this.successMessage = 'Successfully blocked physician';
      this.notifier.notify('success', this.successMessage);

      this.getpreferedPhysiciansList();
      this.getPhysicianDetails();
      this.getphyBlockedList();
    },
      error => {
        this.notifier.notify('error', error.error);
      }
    );
    }
    $('#modelBlockPhysician').modal('hide');
  }

  //  Post PreferedPhysician list API
  getpreferedPhysiciansList() {
    this.physicianLookupService.getPreferedPhysicians(this.memberId).subscribe(res => {
      this.preferedData = res;
      if (this.preferedData.length === 0) {
        this.noPereferedData = true;
      } else {
        this.noPereferedData = false;
        this.preferedPhysiciansData = this.preferedData;
      }
    },
      error => {
        this.notifier.notify('error', error.error);
      }
    );
  }
// Delete Drug row in blocked list
blkdDrow() {
  this.memberService.unblockPList(this.dId).subscribe(
    res => {
      this.notifier.notify('success', 'Drug unblocked successfully');
      this.getpreferedPhysiciansList();
      this.getphyBlockedList();
    },
    error => {
      this.notifier.notify('error', 'Failed to unblock.');
    }
  );
}
// Delete Phy row in blocked list
blkdProw() {
  this.memberService.unblockPList(this.dId).subscribe(
    res => {
      this.getPhysicianDetails()
      this.getpreferedPhysiciansList();
      this.getphyBlockedList();
      this.notifier.notify('success', 'Physician unblocked successfully');
    },
    error => {
      this.notifier.notify('error', error.error);
    }
  );
}

 // Delete function for all the selections
 removeBlock() {
    this.removePhysician();
}

  // Delete Physician API
  removePhysician() {
    const preferedPhysicians = JSON.stringify({
      id: this.preferedPhysicians.id,
      adjusterId: this.adjusterId,
      memberName: this.memberInfo.name,
      physicianName: this.preferedPhysicians.physicianName
    });
    this.physicianLookupService.deletePhysician(preferedPhysicians).subscribe(
      () => {
        this.message =
          'Physician deleted successfully from preferred Physician list';
        this.notifier.notify('success', this.message);  
        this.getpreferedPhysiciansList();
        this.getPhysicianDetails();
        this.getphyBlockedList();
      },
      () => {
        this.message = 'Failed To Delete Preferred Doctor!!';
        this.notifier.notify('error', this.message);
      }
    );
  }
    priorAuth() {
    // this.med = true
    // this.memberService.setDrugName(this.preferedDrugs.ndcNumber);
    // this.getClaimByNDCNumber(this.preferedDrugs.ndcNumber);
    this.updatePreferrenceDateRange();
  }
  setDoctorEdit() {
    this.startDate2 = this.preferedDateDto.startDate;
    this.endDate2 = this.preferedDateDto.endDate;
  }
  // UpdateBlockedPhy
  blkUpdateProw(s, e) {
    var isAfter = moment(s).isSame(e);

    if (!isAfter) {
      this.notifier.notify('error', 'To Date Should be same as From Date!!');
    } else {
      const body = {
        id: this.preferedPhysicianDto.id,
        physicianId: this.preferedPhysicianDto.physicianId,
        adjusterId: this.preferedPhysicianDto.adjusterId,
        memberId: this.memberId,
        memberName: name,
        physicianName: this.preferedPhysicianDto.physicianName,
        validityStartDate: s,
        validityEndDate: e,
        isBlocked: true,
        physicianDetails: this.preferedPhysicianDto,
        npinumber: this.npi,
        isDisabled: true,
        isChecked: true
      };
      this.memberService.updateBlockedPhy(body).subscribe(
        res => {
          this.notifier.notify('success', res);
          this.getpreferedPhysiciansList();
          this.getphyBlockedList();
        },
        error => {
          this.notifier.notify('error', error.error);
        }
      );
      $('#myModal11123').modal('hide');
    }
  }
  // Get all member details API
  getMemberProfileInfo() {
    this.physicianLookupService.getMemberDetails(this.memberId).subscribe(array => {
      array.forEach(res => {
        this.memberInfo.memberId = res.item2.memberId == null ? '' : res.item2.memberId;
        this.memberInfo.name = (res.item2.firstName == null ? '' : res.item2.firstName) + " " + (res.item2.lastName == null ? '' : res.item2.lastName);
        this.memberInfo.cardholderNumber = res.item1.cardholderNumber == null ? '' : res.item1.cardholderNumber;
        this.memberInfo.dateOfBirth = res.item2.dateOfBirth == null ? '' : this.datePipe.transform(res.item2.dateOfBirth, 'MM/dd/yyyy');
        if (res.item2.gender.includes('0')) {
          this.memberInfo.gender = 'Trans';
        } else if (res.item2.gender.includes(1)) {
          this.memberInfo.gender = 'Male';
        } else {
          this.memberInfo.gender = 'Female';
        }
        this.memberInfo.address1 = res.item2.address1 == null ? '' : res.item2.address1;
        this.memberInfo.address2 = res.item2.address2 == null ? '' : res.item2.address2;
        this.memberInfo.city = res.item2.city == null ? '' : res.item2.city;
        this.memberInfo.state = res.item2.state == null ? '' : res.item2.state;
        this.memberInfo.zip = res.item2.zipCode == null ? '' : res.item2.zipCode;
        this.memberInfo.phoneNumber = res.item2.phoneNumber == null ? '' : res.item2.phoneNumber;
        this.memberInfo.groupNumber = res.item2.groupNumber == null ? '' : res.item2.groupNumber;
        this.memberInfo.emailAddress = res.item2.emailAddress == null ? '' : res.item2.emailAddress;
        this.memberInfo.startDate = res.item2.startDate == null ? '----' : this.datePipe.transform(res.item2.startDate, 'MM/dd/yyyy');
        this.memberInfo.endDate = res.item2.endDate == null ? '----' : this.datePipe.transform(res.item2.endDate, 'MM/dd/yyyy');
        this.memberInfo.ffStartDate = res.item2.ffStartDate == null ? '----' : this.datePipe.transform(res.item2.ffStartDate, 'MM/dd/yyyy');
        this.memberInfo.ffEndDate = res.item2.ffEndDate == null ? '----' : this.datePipe.transform(res.item2.ffEndDate, 'MM/dd/yyyy');
        this.memberInfo.phnType = res.item2.phoneNumberOf == null ? '' : res.item2.phoneNumberOf;
        this.memberInfo.groups = res.item2.groups
        if (this.memberInfo.phnType === '1') {
          this.memberInfo.phnType = '(H)'
        } else if (this.memberInfo.phnType === '2') {
          this.memberInfo.phnType = '(W)'
        } else if (this.memberInfo.phnType === '3') {
          this.memberInfo.phnType = '(M)'
        } else {

        }
      });
      this.memberInfo.groups.forEach(item=>{
        if(item.ffId == 1){
          this.memberInfo.groupNumber = item.groupNumber
        }else{
          this.memberInfo.ffGroupNumber=item.groupNumber
        }
      })
      if (this.memberInfo.phoneNumber == '') {
        this.memberInfo.phnType = ''
      } else {
       this.memberInfo.phoneNumber = this.commanUtils.convertToInternational(this.memberInfo.phoneNumber)
      }
    });
  }

  // Select the row function
  selectedRow(item) {
    this.op=true;
    this.preferedPhysicianDto.physicianDetails = item
    this.preferedPhysicianDto.physicianName = item.name;
    this.preferedPhysicianDto.adjusterId = parseInt(this.adjusterId);
    this.preferedPhysicianDto.adjusterName = this.adjusterName;
    this.preferedPhysicianDto.memberId = this.memberId;
    this.preferedPhysicianDto.physicianId = item.id;

  }

  // Reset to default
  reset() {
    this.physicianDto.name = '';
    this.physicianDto.firstName = ''
    this.physicianDto.lastName = ''
    this.physicianDto.address = '';
    this.physicianDto.city = '';
    this.physicianDto.contact = '';
    this.physicianDto.npiNumber = '';
    this.physicianDto.state = '';
    this.physicianDto.zip = '';
    this.physicianDto.specialization = ''
    this.filterData = [];

    this.showSerachResult = false;

    this.selectedItems = [];

  }

  // Update the end date function
  updateMyEndDate(newDate) {
    const newD_ = Object.assign(newDate.value);
    this.endDate3 = new Date(newD_);
    this.endDate3.setDate(this.endDate3.getDate() + 90);
  }
  updateBlockedEndDate(newDate) {
    const newD_ = Object.assign(newDate.value);
    this.endDate3 = new Date(newD_);
    this.endDate3.setDate(this.endDate3.getDate());
  }

  // Change the date to default function
  changeTodefaultDates() {
    this.op = false;
    this.startDate3 = new Date();
    this.endDate3 = new Date();
    this.endDate3.setDate(this.startDate3.getDate() + 90);
  }

  changeTodefaultblockedDates() {
    this.op = false;
    this.startDate3 = new Date();
    this.endDate3 = new Date();
    this.endDate3.setDate(this.startDate3.getDate());

  }

  handlePDFClick() {
    this.getPDFDownload()
  }

  handleExcelClick(){
    this.exportAsXLSX();
  }
  // select dropdown function
  onChangeActivity(newSelection) {
    this.selectedActivity = newSelection;
    if (this.selectedActivity === '1') {
      this.exportAsXLSX();
    }
    if (this.selectedActivity === '2') {
      this.getPDFDownload()
    }
    this.selectedActivity = 0
  }

  // Export to excel function
  exportAsXLSX() {
    if (this.memberId > 0)
      this.commanUtils.exportAsExcel('exportable1', 'PhysicianList');
    else
      this.commanUtils.exportAsExcel('exportable1', 'PhysiciansList');
  }


  // Get all geolocation address
  getGeoLocation(item) {
    this.physicianDto.address = item.address
    this.physicianLookupService.getGeolocation(this.physicianDto.address).subscribe(res => {
      $('#googleMap').modal('show');
      this.markerz = res
    })
  }

  //  Change sort function to this:
  sort(property, i) {

    this.indx = i
    this.asc = 1;
    this.direction = 2;

    return this.data.sort((a, b) => {

      return this.compare(a[property], b[property])
    });

  }

  dsort(property, i) {
    this.indx = i
    this.asc = 0;
    this.direction = 1
    return this.data.sort((a, b) => {

      return this.compare(b[property], a[property])
      //  b[property].localeCompare(a[property]);
    });
  }

  compare(a: number | string, b: number | string) {
    return (a < b ? -1 : 1)
  }

  // End of sort

  // Validation starts
  validate(item) {
    this.count=0;
    for(let key in item) {
      if(item[key] !== '')
      {this.count++;}
      }
      
    if (this.count<2
      // item.city === '' && item.state === '' && item.zip === '' && item.firstName === '' && item.lastName === '' && item.specialization === '' && item.npinumber === ''
      ) {
      this.notifier.notify('error', "Please provide atleast two fields in quick search")
    } else {

      // if (item.city.length <= 0 && item.state.length > 0 && item.zip.length <= 0 && item.firstName.length <= 0 && item.lastName.length <= 0 && item.specialization.length <= 0) {
      //   this.notifier.notify('error', "Please provide state with any other field in quick search")
      // } else {
        //console.log(item);
        if (item.specialization.length > 0 && item.city.length <= 0 && item.state.length <= 0 && item.zip.length <= 0 && item.firstName.length <= 0 && item.lastName.length <= 0 && item.npiNumber.length <= 0) {
          if (item.specialization.length <= 1) {
            this.notifier.notify('error', "Please provide atleast first 2 letters of the specialization")
          } else {
            this.getPhysicianDetails()
          }
        } else {
          this.getPhysicianDetails()
          // this.getGeoLocation()
        }
      // }
    }
  }
  // validation ends

  // Phy Blocked List
  getphyBlockedList() {
    this.memberService.getBlockedPhyList(this.memberId).subscribe(res => {
      this.blkPList = res;      
    });
  }
  
  selectedPRow(item) {
    this.npi = item.npinumber;
    this.blk = true;
    this.dId = item.id;
    this.med = false;
    this.preferedPhysicianDto = item;
    this.removeCheckModel = 'Physicians';
    this.title = 'Physicians';
    this.preferedDateDto.startDate = item.validityStartDate;
    this.preferedDateDto.endDate = item.validityEndDate;
    this.setpBlked();
  }
  setpBlked() {
    this.startDate2 = this.preferedDateDto.startDate;
    this.endDate2 = this.preferedDateDto.endDate;
  }
    // Update preferred date range API
    updatePreferrenceDateRange() {
      let startDate = this.datePipe.transform(this.startDate2, 'MM/dd/yyyy');
      let endDate = this.datePipe.transform(this.endDate2, 'MM/dd/yyyy');
      var name =
        this.memberInfoDetials.memberDetailDto.firstName +
        this.memberInfoDetials.memberDetailDto.lastName;
      if (this.removeCheckModel.includes('physician')) {
        this.physData(startDate, endDate);
      } else if (this.removeCheckModel.includes('Physicians')) {
        this.blkUpdateProw(startDate, endDate);
      }
  
      //
    }
    physData(startDate, endDate) {
      var isAfter = moment(startDate).isSameOrAfter(endDate);
      if (isAfter) {
        this.notifier.notify('error', 'To Date Should be More than From Date!!');
      } else {
        const preferredPhysician = JSON.stringify({
          id: this.preferedPhysicians.id,
          adjusterId: this.adjusterId,
          memberId: this.memberId,
          memberName: name,
          physicianName: this.preferedPhysicians.physicianName,
          validityStartDate: startDate,
          validityEndDate: endDate
        });
        this.physicianLookupService.updatePhysician(preferredPhysician).subscribe(
          () => {
            this.message =
              'Successfully Updated Preferred Doctor/Physician Range!!';
            this.notifier.notify('success', this.message);
            $('#myModal11123').modal('hide');
            this.getpreferedPhysiciansList();
          this.getphyBlockedList();
          this.getPhysicianDetails()
          },
          error => {
            this.message = error.error;
            this.notifier.notify('error', this.message);
          }
        );
      }
    }

  markers: marker[] = [
    {
      lat: 17.4124395,
      lng: 78.3949815,
      label: 'A',
      draggable: true
    },
  ]
}

// markers interface
interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}

// Physician DTO
export class PhysicianDto {

  id: number

  name: string = ''

  firstName: string = ''

  lastName: string = ''

  npiNumber: string = ''

  specialization: string = ''

  address: string = ''

  contact: string = ''

  geoLocation: string = ''

  city: string = ''

  state: string = ''

  zip: string = ''

}

// PreferedPhysician Dto
export class PreferedPhysicianDto {

  id: number

  physicianId: number

  physicianName: string = ''

  memberId: number

  memberName: string = ''

  adjusterId: number

  adjusterName: string = ''

  validityStartDate: string = ''

  validityEndDate: string = ''

  physicianDetails: prefDetails


}

// PreferedPhysician Dto
export class prefDetails {
  npiNumber: string = ''
  address: string = ''
  city: string = ''
  name: string = ''
  phoneNumber: number
  specialization: string = ''
  state: string = ''
  zipCode: string = ''
}

// PreferedDate Dto
export class PreferedDates {

  startDate: Date

  endDate: Date

}

// Specialization DTO
export class Specialization {

  id: string = ''

  speciality: string = ''

  description: string = ''

}

// Member details DTO
export class MemberDetailInfo {

  memberId: number

  cardholderNumber: string = ''

  name: string = ''

  gender: string = ''

  emailAddress: string = ''

  phoneNumber: string = ''

  dateOfBirth: string = ''

  groupNumber: string = ''
  ffGroupNumber:string=''

  startDate: string = ''

  endDate: string = ''

  address1: string = ''

  address2: string = ''

  phnType: string = ''

  city: string = ''
  state: string = ''
  zip: string = ''
  groups: Groups[]
  ffStartDate = ''
  ffEndDate = ''


}

// Direction DTO
export class Direction {
  origin
  destination
}
// GeoLocation DTO
export class geoLocation {
  centerLatitude;
  centerLongitude;
  radius: number;
}