
<div id="body"  [ngClass]= "{'aside-collapse' : isMenuCollapsed}">
    <app-header [parentData]="newRole" [parentData1]="newRole1" (isMenuCollapsed)="UpdateMenu($event)" *ngIf="showHead"></app-header>
    <div [attr.id]="showHead ? 'page-content-wrapper': '' ">
      <div [ngClass]= "{'container-fluid' : showHead}">
        <inactivity-timer ></inactivity-timer>
        <app-loader></app-loader>
        <router-outlet></router-outlet>
      </div>     
      <app-footer *ngIf="showHead"></app-footer>   
    </div>    
</div>

<!-- The Modal -->
<div class="modal fade" id="myModalz1" >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content text-center" style="border-radius: 10px" >
            <div class="modal-body">

               <div class="form-group">
                    <h4> Session Timed out. Please login again!</h4>
               </div>
               <div class="row">
                <div class="col">
                  <div class="text-center">
                    <button class="btn btn-primary" data-dismiss="modal" (click)="close()" >Ok</button>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
