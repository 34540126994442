import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate
} from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AdjusterGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate() {
    if (
      (localStorage.getItem('token') &&
        localStorage.getItem('roleId') === '3') ||
      localStorage.getItem('roleId') === '1' ||
      localStorage.getItem('roleId') === '2' ||
      localStorage.getItem('roleId') === '6' ||
      localStorage.getItem('roleId') === '4' ||
      localStorage.getItem('roleId') === '5' 
    ) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/']);
    return false;
  }
}
