import { OnInit } from '@angular/core';
import { LoginService } from './login.service';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { LoaderService } from '../loader/loader.service';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(notifier, loaderService, formBuilder, loginService, router) {
        this.notifier = notifier;
        this.loaderService = loaderService;
        this.formBuilder = formBuilder;
        this.loginService = loginService;
        this.router = router;
        // variables
        this.hide = false;
        this.submitted = false;
        this.rollId = localStorage.getItem('rollId');
        this.errorStatus = false;
        this.locked = false;
        this.btn = false;
        this.isFromPreAppoved = localStorage.getItem('isFromPreAppoved');
    }
    LoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        var submainName = window.location.hostname.split(".")[0];
        this.loginService.getImageUrl(submainName).subscribe(function (res) {
            _this.imgUrl = res;
        });
        $(function () {
            $('#noSpacesField').bind('input', function () {
                $(this).val(function (_, v) {
                    return v.replace(/\s+/g, '');
                });
            });
        });
        this.registerForm = this.formBuilder.group({
            email: ['', [Validators.required]],
            password: ['', [Validators.required, Validators.minLength(8)]]
        });
    };
    Object.defineProperty(LoginComponent.prototype, "f", {
        get: function () {
            return this.registerForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    // Onsubmit (login function)
    LoginComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        //  stop here if form is invalid
        if (this.registerForm.invalid) {
            this.errorStatus = false;
            return;
        }
        // document.getElementById('myModal').style.display='block'
        this.loginService.login(this.registerForm.value).subscribe(function (res) {
            if (localStorage.getItem('navFlag') != undefined || localStorage.getItem('navFlag') === '1') {
                _this.router.navigate(['adjuster/memberprofile']);
            }
            else {
                if (res.roleId == 1) {
                    if (!res.isPwdVerified) {
                        _this.router.navigate(['changepassword']);
                        // document.getElementById('myModal').style.display='none'
                    }
                    else {
                        _this.loaderService.show();
                        _this.router.navigate(['admin/home']);
                    }
                    // document.getElementById('myModal').style.display='none'
                }
                else if (res.roleId == 2) {
                    // for first time user
                    if (!res.isPwdVerified) {
                        _this.router.navigate(['changepassword']);
                        // document.getElementById('myModal').style.display='none'
                    }
                    else {
                        _this.router.navigate(['client/home']);
                        // document.getElementById('myModal').style.display='none'
                    }
                }
                else {
                    if (!res.isPwdVerified) {
                        _this.router.navigate(['changepassword']);
                        // document.getElementById('myModal').style.display='none'
                    }
                    else {
                        if (_this.isFromPreAppoved) {
                            _this.router.navigate(['adjuster/PendingAuthorizations']);
                        }
                        else {
                            _this.router.navigate(['adjuster/home']);
                        }
                    }
                    // document.getElementById('myModal').style.display='none'
                }
            }
        }, function (error) {
            _this.errorStatus = true;
            _this.error = error.error;
            // document.getElementById('myModal').style.display='none'
            _this.notifier.notify('error', _this.error.message);
            _this.loginError = _this.error.message;
            _this.locked = _this.error.isAccountLocked;
            if (_this.locked) {
                $('#myModalz').modal('show');
            }
        });
    };
    LoginComponent.prototype.validatez = function () {
        var reg = /^\w+([-+.']\w+)*@\w+([-. ]\w+)*\.\w+([-. ]\w+)*$/;
        if (reg.test(this.registerForm.value.email.trim())) {
            this.emailErrz = false;
        }
        else {
            this.emailErrz = true;
        }
    };
    // Google captch function
    LoginComponent.prototype.resolved = function (captchaResponse) {
        this.googleResponse = captchaResponse;
        if (this.googleResponse != null && this.registerForm.value) {
            this.btn = true;
        }
        else {
            this.btn = false;
        }
    };
    return LoginComponent;
}());
export { LoginComponent };
