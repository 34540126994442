import {Component} from '@angular/core';
import { RegisterPortalAdminService } from './registerportaladmin.service';
import { NotifierService } from 'angular-notifier';

@Component({
    selector: 'app-register-portal-admin',
    templateUrl: './registerportaladmin.component.html',
    styleUrls: ['./registerportaladmin.component.css']
  })
export class RegisterPortalAdminComponent  {  

username: any;
firstName: any;
lastName: any;
passCode: any;
formSubmitAttempt: boolean;
message: any;

constructor(private registerPortalAdminService: RegisterPortalAdminService,private notifier:NotifierService) {
}


onSubmit(formControl: any) {
  this.formSubmitAttempt = true;

  if(!formControl.form.valid) {
       return;
  } 
  const data = {
    firstName: this.firstName,
    lastName: this.lastName,
    email :this.username, 
    passCode: this.passCode
  };
  
  this.registerPortalAdminService.registerPortalAdmin(data).subscribe(res => {       
    this.formSubmitAttempt = false;    
      this.message = res.message;      
      this.notifier.notify('success', 'Portal Admin is created successfully!');
  },
  err => {
    this.notifier.notify('error', err.error);
  });
}
}