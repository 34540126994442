import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap, retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClaimHistoryService {
  url = environment.apiBase;

  constructor(private http: HttpClient) {}
  getMemberDetails(memberId): Observable<any> {
    return this.http
      .get(this.url + 'api/Members?memberid=' + memberId)
      .pipe(catchError(this.handleError));
  }

  getClaimHistoryByDates(memberId, startDate, endDate): Observable<any> {
    return this.http
      .get(
        this.url +
          'api/Claims/' +
          memberId +
          '/ClaimsHistory?sdate=' +
          startDate +
          '&edate=' +
          endDate
      )
      .pipe(catchError(this.handleError));
  }
  getPDFGenerated(memberId, startDate, endDate): Observable<any> {
    const body = {
      key: 0,
      value:
        '/' +
        memberId +
        '/ClaimsHistory?sdate=' +
        startDate +
        '&edate=' +
        endDate  +'&clientId='+localStorage.getItem('clientId') 
    };

    return this.http
      .post(this.url + 'api/Claims/ClaimHistoryAsPdf', body, {
        responseType: 'blob'
      })
      .pipe(catchError(this.handleError));
  }

  getClaimByPin(body): Observable<any> {
    return this.http
      .post(this.url + 'api/Claims/ValidateAutoApproveClaimByPin', body)
      .pipe();
  }

  getClaimHistory(memberId): Observable<any> {
    return this.http
      .get(this.url + 'api/Claims/' + memberId + '/ClaimsHistory')
      .pipe(catchError(this.handleError));
  }
  saveCliamsHistory(body): Observable<any> {
    return this.http
      .post(this.url + 'api/Claims/SaveClaimHistory', body, {
        responseType: 'text'
      })
      .pipe(tap(res => {}));
  }

  getSavedClaimHistoryByMemberId(memberId): Observable<any> {
    return this.http
      .get(this.url + 'api/Claims/' + memberId + '/GetSavedClaimHistory')
      .pipe(tap((res: Response) => res));
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error

      if (error.status >= 400 && error.status < 500) {
        errorMessage = 'Action failed due to bad request';
      } else if (error.status >= 500) {
        errorMessage = 'Action failed due to server side issue';
      }
    }
    return throwError(errorMessage);
  }
}
