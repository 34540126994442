import { Component, OnDestroy, OnInit } from '@angular/core';
@Component({
  selector: 'service-down',
  templateUrl: './service-down.component.html',
  styles: []
})
export class ServiceDown implements  OnInit {
  constructor() {

  }

  ngOnInit() {

  }

}