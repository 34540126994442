import { NgModule } from '@angular/core';
import { NumberDirective } from './Utils/numbers-only.directive';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { NewsComponent } from './Client/news/news.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { FocusDirective } from './Utils/focus.directive';
import { AlphaDirective } from './Utils/alpha-only.directive';
import { OrderrByPipe } from './Utils/orderBy.pipe';
import { MomentDatePipe } from './Utils/momentDate.pipe';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { MaxLengthDirective } from './Utils/maxLength.directive';
import { NgxPaginationModule } from 'ngx-pagination';
import { ContactusComponent } from './contactus/contactus.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FileUploadComponent } from './file-upload/file-upload.component' 
import { DragDropDirective } from './file-upload/drag-drop.directive';
import { ReportingComponent } from './reporting/reporting.component';
import { DateFormat } from './Utils/date-format';
import { DemoMaterialModule } from './material-module';
import { DateAdapter, MatSelectModule } from '@angular/material';
import {NgSelectizeModule } from 'ng-selectize';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { TelerikReportingModule } from '@progress/telerik-angular-report-viewer';
import { from } from 'rxjs';
const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'middle',
      distance: 12
    },
    vertical: {
      position: 'top',
      distance: 12,
      gap: 10
    }
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};

@NgModule({
  imports: [
    NotifierModule.withConfig(customNotifierOptions),
    FroalaEditorModule,
    FroalaViewModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    AngularEditorModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    DemoMaterialModule,
    MatSelectModule,
    NgSelectizeModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule
  ],
  providers: [    
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    MomentDatePipe
  ],
  declarations: [
    FocusDirective,
    NumberDirective,
    AlphaDirective,
    NewsComponent,
    ContactusComponent,
    OrderrByPipe,
    MomentDatePipe,
    MaxLengthDirective,
    FileUploadComponent,
    DragDropDirective,
    ReportingComponent
  ],
  exports: [NumberDirective, AlphaDirective, FocusDirective, MaxLengthDirective,MomentDatePipe,FileUploadComponent,
    DragDropDirective]
})
export class SharedModule {}
