import { BrowserModule } from '@angular/platform-browser';
import { NgModule, enableProdMode } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderComponent } from './loader/loader.component';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { ReactiveFormsModule } from '@angular/forms';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { ConfirmEqualValidatorDirective } from './Utils/confirmPassword.directive';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
// import { LoaderInterceptorService} from './helpers/loader.interceptor'
import { DemoMaterialModule } from './material-module';
import { GeneratepasswordComponent } from './generatepaasword/generatepassword.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DatePipe } from '@angular/common';
import {MatTooltipModule} from '@angular/material/tooltip';
import { AgmCoreModule } from '@agm/core';
import { CardComponent } from './card/card.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { DateFormat } from './Utils/date-format';
import { DateAdapter, MatSelectModule } from '@angular/material';
import { InactivityTimerComponent } from './inactive-timer.component';
import { ServiceDown } from './service-down.component';
import { CommanUtils } from './Utils/commanUtils.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AgmDirectionModule } from 'agm-direction';
import { PreApprovedComponent } from './preApproved/preApproved.component';
import { MaxLengthDirective } from './Utils/maxLength.directive';
import { NumberDirective } from './Utils/numbers-only.directive';
import { SharedModule } from './shared. module';
import { RegisterComponent } from './register/register.component';
import { RegisterPortalAdminComponent } from './registerportaladmin/registerportaladmin.component';

import {NgSelectizeModule } from 'ng-selectize';
// import { ViewerComponent } from './viewer/viewer.component';
// import { MatTimepickerModule } from 'mat-timepicker';
enableProdMode();
const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'middle',
      distance: 12
    },
    vertical: {
      position: 'top',
      distance: 60,
      gap: 20
    }
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 1
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    ChangepasswordComponent,
    ForgotpasswordComponent,
    ConfirmEqualValidatorDirective,
    LoaderComponent,
    GeneratepasswordComponent,
    CardComponent,
    UnsubscribeComponent,
    RegisterComponent,
    InactivityTimerComponent,
    ServiceDown,
    PreApprovedComponent,
    // ViewerComponent       
    RegisterPortalAdminComponent
    // NumberDirective,
    // MaxLengthDirective
  ],
  imports: [
    // AdminModule,
    NgxPaginationModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    RecaptchaModule.forRoot(),
    ReactiveFormsModule,
    DemoMaterialModule,
    NotifierModule.withConfig(customNotifierOptions),
    NgMultiSelectDropDownModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB8cdNJfMkCmUAhDCvHnfWyaISgHpFxCSQ'
    }),
    AgmDirectionModule,
    MatSelectModule,
    Ng2SearchPipeModule,
    SharedModule,
    NgSelectizeModule,
    MatTooltipModule
    // MatTimepickerModule
    
  ],
  providers: [
    { provide: DateAdapter, useClass: DateFormat },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    CommanUtils,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
