import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PhysicianLookupService {
  data: any;
  url = environment.apiBase;
  constructor(private http: HttpClient) {}

  getPhysicianDetails(member, body): Observable<any> {
    return this.http
      .get(
        this.url +
          'api/Physicians/' +
          member +
          '?firstName=' +
          body.firstName +
          '&LastName=' +
          body.lastName +
          '&speciality=' +
          body.specialization +
          '&city=' +
          body.city +
          '&state=' +
          body.state +
          '&zipCode=' +
          body.zip +
          '&npiNumber=' +
          body.npiNumber
      )
      .pipe();
  }

  createPreferedPhysician(body): Observable<any> {
    return this.http
      .post(this.url + 'api/Physicians', body, { responseType: 'text' })
      .pipe();
  }

  getPreferedPhysicians(memberId): Observable<any> {
    return this.http
      .get(this.url + 'api/Physicians?memberId=' + memberId)
      .pipe();
  }

  blockPreferedPhysician(body): Observable<any> {
    return this.http
      .put(this.url + 'api/Physicians', body, { responseType: 'text' })
      .pipe();
  }

  getSpecializationsList(): Observable<any> {
    return this.http.get(this.url + 'api/Physicians/Specialization').pipe();
  }

  getGeolocation(body): Observable<any> {
    return this.http
      .get(this.url + 'api/Physicians/GeoLocation?address=' + body)
      .pipe();
  }

  getMemberDetails(memberId): Observable<any> {
    return this.http.get(this.url + 'api/Members?memberid=' + memberId).pipe();
  }

  deletePhysician(body): Observable<any> {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: body,
      responseType: 'text' as 'text'
    };
    return this.http
      .delete(this.url + 'api/Physicians', options)
      .pipe(tap((res: Response) => res));
  }

  updatePhysician(body): Observable<any> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http
      .post(this.url + 'api/Physicians/UpdatePhysicianPreference', body, {
        headers,
        responseType: 'text'
      })
      .pipe();
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      if (error.status >= 400 && error.status < 500) {
        errorMessage = 'Action failed due to bad request';
      } else if (error.status >= 500) {
        errorMessage = 'Service Not Available! Please Try Later!!!';
      }
    }
    return throwError(errorMessage);
  }

  getPdfDownload(body): Observable<any> {
    const query =
      '0?firstName=' +
      body.firstName +
      '&LastName=' +
      body.lastName +
      '&speciality=' +
      body.specialization +
      '&city=' +
      body.city +
      '&state=' +
      body.state +
      '&zipCode=' +
      body.zip;
    const value = {
      key: 0,
      value: query +'&clientId='+localStorage.getItem('clientId') 
    };
    return this.http
      .post(this.url + 'api/Physicians/PhysicianResultAsPdf', value, {
        responseType: 'blob'
      })
      .pipe(catchError(this.handleError));
  }
}
