<div id="body">
    <div class="reset-page">
        <div class="form">
            <div class="login-logo form-group"><img class="img-responsive img-fluid" style="cursor: pointer;width: 125px;" src="{{imgUrl}}"></div>
            <div class="title-secondary">
                <div>Forgot your Password?</div>

                <div class="divreset-options text-left title-secondary">

                    <div style="margin-bottom:10px;">
                        <input name="changeresetpasswordtype" (blur)="validateEmail(email)"  type="text"  value="ThroughEmail"  [(ngModel)]="email"  name="email"  id="chkEmail" placeholder="Email">

                    </div>
                    <div *ngIf="emailErr">
                        <div style="font-size: 80%;color: #dc3545;">Please enter a valid email</div>
                    </div>
                    <div class="reset-options-desc">
                        We will send you the instructions to your email
            </div>
        </div>
        <div class="form-label-group" style="margin-bottom:30px;">
            <re-captcha (resolved)="resolved($event)" siteKey="6LfexH0UAAAAABkLfl_wv-VfW6FBWvjAwsUu_XIa">
            </re-captcha>
        </div>
        <div class="divreset-buttons">
            <!-- <button id="btnResetPwdType" class="btncontinue">Continue</button> -->
            <button class="btn btn-primary" [disabled]="!btn && !emailErr" (click)="forget()">Continue</button>
        </div>

        <div class="title-secondary">
            <div>or</div>
            <div>Continue with <a [routerLink]="['/']"><b>Sign In</b></a></div>
        </div>
    </div>


</div>
</div>

<notifier-container></notifier-container>