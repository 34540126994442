
<div class="container-fluid"><div class="form-group" id="memberCardPrint" style="width:100%;text-align: center;">
     <div style="padding:10px;">
    <div class="divCard">
            <div class="membercard-logo form-groupo" *ngIf="logo"><img style="width:124px;height:52px;" class="img-responsive img-fluid"
                src="{{memberCardTemplate.companyLogo}}"></div>
                <div class="membercard-logo form-groupo" *ngIf="!logo"><img class="img-responsive img-fluid"
                  src="{{memberCardTemplate.clientLogo}}" style="margin-right: 10px;width:124px;height:52px;"><img  style="width:124px;height:52px;" class="img-responsive img-fluid"
                  src="{{memberCardTemplate.companyLogo}}"></div>
        <div style="font-size:14px;margin-top: 15px;padding: 0 30px;">
        <div class="form-group" style="font-weight:bold;font-size: 15px;color:#136ab6;">
            <div>{{memberCardTemplate.clientName}}</div>
            <div>{{memberCardTemplate.programName}}</div>

        </div>
        <div class="form-group " style="font-size: 14px;">
        <div class="row text-left">
         <div class="col-md-6 " style="padding-top:6%;">
            <div class="m-1">ID Number:  <label><b>{{memberCardTemplate.idNumber}}</b></label></div>
            <div>First Name: <label><b>{{memberCardTemplate.memberFirstName}}</b></label></div>
            <div>Last Name: <label><b>{{memberCardTemplate.memberLastName}}</b></label></div>
        </div>
        <div class="col-md-6">
                <div class="m-1">RX GROUP: <label ><b>{{memberCardTemplate.rxGroup}}</b></label></div>
                <div class="m-1">RX BIN: <label><b>{{memberCardTemplate.rxbin}}</b></label></div>
                <div class="m-1">PCN: <label><b>{{memberCardTemplate.pcnNumber}}</b></label></div>
                <div >PERSON CODE: <label><b>{{memberCardTemplate.memberCode}}</b></label></div>
        </div>
        </div>
        </div>

    </div>
    <div>
            <div style="display: inline-block;background-color: #136ab6;width: 100%;padding: 10px;border-radius: 0px 0 10px 10px;color: #fff;">Pharmacy Help Desk: <span><b>{{memberCardTemplate.pharmacyHelpDeskNumber}}</b></span> (24hrs / 7days per week)</div>

    </div>
    </div>
    </div>
  </div>
</div>