import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {
  url = environment.apiBase;
  constructor(private http: HttpClient) {}

  reset(data) {
    return this.http
      .put(this.url + 'api/Account/ResetPassword', data, {
        responseType: 'text'
      })
      .pipe(tap(res => {}));
  }
}
