import { Component, HostListener, Compiler, OnInit } from '@angular/core';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  ActivatedRoute
} from '@angular/router';
import { LoaderService } from './loader/loader.service';
import { LoginService } from './login/login.service';

// jQuery declaration
declare var $;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  // Variables
  showHead: boolean = false;
  showHeads: boolean = false;
  subPath;
  userId: string = '';
  sout = '0';
  newRole: boolean;
  newRole1: boolean;
  countz = 0;
  initializedInInit = false;
  isMenuCollapsed:boolean=false;
  memPId;
  constructor(
    private routez: ActivatedRoute,
    private authService: LoginService,
    private router: Router,
    private loaderService: LoaderService,
    private loginService: LoginService,
    private _compiler: Compiler
  ) //private localStorageService: LocalStorageService
  {
  
    //  on route change to '/login', set the variable showHead to false
    this.userId = this.routez.snapshot.queryParamMap.get('UserId');
  
    
 
    // console.log('onLocalStorageEvent',this.countz)
    router.events.forEach(event => { 
      
      if (event instanceof NavigationEnd) {
        this.subPath = event['url'];
        var left_text = this.subPath
          .substring(this.subPath.indexOf('?'))
          .replace(/}/g, '');
        var x = left_text.substring(1);
        x = x.substring(0, x.indexOf('/'));
        if (x === 'admin') {
          let submainName = window.location.hostname.split('.')[0];
          this.loginService.getImageUrl(submainName).subscribe(res => {
            this.loginService.updatedImgSource(res);
          });
        } else {
          this.loginService
            .getImageUrlClientId(localStorage.getItem('clientId'))
            .subscribe(res => {
              this.loginService.updatedImgSource(res);
            });
        }
        this.userId = left_text.substr(1, 6);
        
        this.loaderService.hide();
        if (event['url']) {
          if (x === 'adjuster') {
            this.newRole = true;
          
          } else {
            this.newRole = false;            
           
          }
          if (x === 'client') {
            this.newRole1 = true;
            localStorage.setItem('flag', '1');
          } else {
            this.newRole1 = false;
            localStorage.setItem('flag', '0');
          }
        }
        if (
          event['url'] == '/serviceDown' ||
          event['url'] == '/' ||
          event['url'] == '/login' ||
          event['url'] == '/forgotpassword' ||
          event['url'] == '/changepassword' ||
          event['url'] == '/generatenewpassword' ||
          event['url'] == '/card' ||
          event['url'] == '/unsubscribe' ||
          event['url'] == '/PreApprovedClaim' ||
          event['url'] == '/memberinfo' ||
          event['url'] == '/portaladmin'
        ) {
          this.showHead = false;
        } else {
          this.showHead = true;
        }
        if (
          event['url'] == '/admin/home' ||
          event['url'] == '/admin/news' ||
          event['url'] == '/admin/addClient'
        ) {
          this.showHeads = false;
        } else {
          this.showHeads = true;
        }
        if (event['url'] != '/login') {
        }
        if (!this.showHead) {
        } else {
          let currentUser = localStorage.getItem('token');
          this.userId.trim();
          if (
            this.userId === 'UserId' ||
            this.userId === 'genera' ||
            this.userId === 'card' ||
            this.userId === 'unsubs'
          ) {
          } else {
            if (currentUser) {
            } else {
              $('#myModalz1').modal('show');
              this.router.navigate(['/']);
            }
          }
        }
      }


      if (event instanceof NavigationStart) {
         var str =event['url'];
        if (str && str.indexOf('?profil=')>0){        
        var str1 = str.substring(str.indexOf('?')+1,str.length);
        this.memPId = str1.substring(str1.indexOf('profil') + 7,str1.length);
        localStorage.setItem('memberId',this.memPId);
        if(this.memPId){
          localStorage.setItem('navFlag', '1');
        }        
      }
        if (event['url'] == '/') {
          let currentUser = localStorage.getItem('token');
          let role = localStorage.getItem('roleId');
          if (currentUser) {
            if (role === '3' || role === '4' || role === '5' || role === '6') {
              this.router.navigate(['adjuster/home']);
            }
            if (role === '2') {
              this.router.navigate(['client/home']);
            }
            if (role === '1') {
              this.router.navigate(['admin/home']);
            }
          }
        }
        $('#mobileMenu').removeClass('asideExpend');          
      }
    });
  }
  // @HostListener('window:beforeunload', ['$event'])
  // doSomething($event) {
  //      this.countz = parseInt(localStorage.getItem('pageCount_'));
  //      console.log('PageCount while closing'+ localStorage.getItem('pageCount_'));
  //     if (this.countz > 0) {
  //       this.countz = this.countz - 1;
  //       localStorage.setItem('pageCount_', JSON.stringify(this.countz));
  //       console.log('PageCount after close'+ localStorage.getItem('pageCount_'));
  //     } else {
  //       localStorage.clear();
  //     }
  // }

  // ngOnDestroy() {
  //   this.countz = parseInt(localStorage.getItem('pageCount'));
  //   if (this.countz > 0) {
  //     this.countz = this.countz - 1;
  //     localStorage.setItem('pageCount', JSON.stringify(this.countz));
  //   } else {
  //     localStorage.clear();
  //   }
  // }

  // @HostListener('window:beforeunload', ['$event'])
  // initializingPageCount($event) {

  // }

  // @HostListener('window:beforeunload', ['$event'])
  // doSomethings($event) {
  //   this.countz = parseInt(localStorage.getItem('pageCount'));
  //   if (this.countz > 0) {
  //     if (this.initializedInInit) {
  //       this.countz = this.countz - 1;
  //       localStorage.setItem('pageCount', JSON.stringify(this.countz));
  //     }
  //   } else {
  //     localStorage.clear();
  //   }
  // }

  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  @HostListener('document:mouseover', ['$event'])
  resetTimer() {
    let currentUser = localStorage.getItem('token');
    this.authService.notifyUserAction();
    if (!this.showHead) {
    } else {
      if (currentUser) {
      } else {
        if (
          this.userId === 'UserId' ||
          this.userId === 'genera' ||
          this.userId === 'card' ||
          this.userId === 'unsubs'

        ) {
        } else {
          if (localStorage.getItem('logOut') === '1') {
            this.router.navigate(['/login']);
            setTimeout(() => {
              location.reload();
            }, 1000);
          } else {
            $('#myModalz1').modal('show');
            this.router.navigate(['/']);
          }
        }
      }
    }
  }
  UpdateMenu($event){
    this.isMenuCollapsed =$event;
  }
  close() {
    $('.modal').modal('hide'); //  closes all active pop ups.
    $('.modal-backdrop').remove(); //  removes the grey overlay.
  }

  add() { }

  ngAfterContentInit() {
    //this.memPId = this.route.snapshot.queryParamMap.get('profil');
    // if (!localStorage.pageCount || parseInt(localStorage.pageCount) === 0) {
    //   this.localStorageService.storeOnLocalStorage(1);
    // }
    // let a = parseInt(localStorage.getItem('pageCounts'));
    // a = a + 1;
    // console.log('lkjsafLKANSFSDfSD', a);
    // window.localStorage.setItem('pageCounts', JSON.stringify(a));
    // let pageCount = parseInt(localStorage.getItem('pageCount'));
    // if (!pageCount) {
    //   pageCount = 0;
    // }
    // localStorage.setItem('pageCount', (pageCount + 1).toString());
    // if (pageCount === parseInt(localStorage.getItem('pageCount'))) {
    //   this.initializedInInit = true;
    // } else {
    //   this.initializedInInit = false;
    // }
  }

  ngOnInit() {
    this._compiler.clearCache();
    // localStorage.openpages = Date.now();
    // console.log('onInit');
    // let pageCount = parseInt(localStorage.getItem('pageCount'));
    // if (!pageCount) {
    //   pageCount = 0;
    // }
    // localStorage.setItem('pageCount', (pageCount + 1).toString());
    // console.log('PageCount before timeout'+ localStorage.getItem('pageCount'));
    // setTimeout(() => {
    //   console.log('PageCount after timeout'+ localStorage.getItem('pageCount'));
    //   if (pageCount === parseInt(localStorage.getItem('pageCount'))) {
    //     this.initializedInInit = true;
    //   } else {
    //     this.initializedInInit = false;
    //   }
    // }, 1000);
    // var onLocalStorageEvent = function(e) {
    //   if (e.key == 'openpages') {
    //     // tslint:disable-next-line: radix
    //     let pageCount_ = parseInt(localStorage.pageCount);
    //     pageCount_ = pageCount_ + 1;
    //     localStorage.pageCount = pageCount_;
    //     console.log(pageCount_);
    //     localStorage.page_available = Date.now();
    //     console.log('open', localStorage.page_available);
    //   }
    //   if (e.key == 'page_available') {
    //     console.log('already', localStorage.openpages);
    //   }
    // };
    // window.addEventListener('storage', onLocalStorageEvent, false);
    //   var a = 1;
    //   var flag
    //   let value = parseInt(localStorage.getItem('counts'))
    //   if(isNaN(value) && value>0){
    //     localStorage.setItem('counts',JSON.stringify(1))
    //   }
    //   localStorage.openpages = Date.now();
    //   console.log('start', localStorage.openpages);
    //   flag = true
    //   localStorage.xyzxz =  flag
    //   var onLocalStorageEvent = function(e) {
    //     if (e.key == 'openpages') {
    //       localStorage.page_available = Date.now();
    //       console.log('open', localStorage.page_available);
    //     }
    //     if (e.key == 'page_available') {
    //       a = a + 1;
    //       flag = false
    //       localStorage.xyzxz =  flag
    //       localStorage.setItem('counts', JSON.stringify(a));
    //       console.log('already', localStorage.openpages );
    //     }
    //   };
    //   window.addEventListener('storage', onLocalStorageEvent, false);
    //   // console.log('onLocalStorageEvent',this.countz)
    // }
  }
}
