import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var AdjusterGuard = /** @class */ (function () {
    function AdjusterGuard(router) {
        this.router = router;
    }
    AdjusterGuard.prototype.canActivate = function () {
        if ((localStorage.getItem('token') &&
            localStorage.getItem('roleId') === '3') ||
            localStorage.getItem('roleId') === '1' ||
            localStorage.getItem('roleId') === '2' ||
            localStorage.getItem('roleId') === '6' ||
            localStorage.getItem('roleId') === '4' ||
            localStorage.getItem('roleId') === '5') {
            // logged in so return true
            return true;
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/']);
        return false;
    };
    AdjusterGuard.ngInjectableDef = i0.defineInjectable({ factory: function AdjusterGuard_Factory() { return new AdjusterGuard(i0.inject(i1.Router)); }, token: AdjusterGuard, providedIn: "root" });
    return AdjusterGuard;
}());
export { AdjusterGuard };
