var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone';
/**
* A moment timezone pipe to support parsing based on time zone abbreviations
* covers all cases of offset variation due to daylight saving.
*
* Same API as DatePipe with additional timezone abbreviation support
* Official date pipe dropped support for abbreviations names from Angular V5
*/
var MomentDatePipe = /** @class */ (function (_super) {
    __extends(MomentDatePipe, _super);
    function MomentDatePipe() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MomentDatePipe.prototype.transform = function (value, format, timezone) {
        if (format === void 0) { format = 'mediumDate'; }
        if (timezone === void 0) { timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; }
        if (!value) {
            return;
        }
        if (value.toString().indexOf('Z') === -1 && value.toString().indexOf('+') === -1) {
            value += 'Z';
        }
        var timezoneOffset = moment(value).tz(timezone).format('Z');
        var timezoneAbbr = moment.tz(timezone).zoneAbbr();
        var localDateTime = _super.prototype.transform.call(this, value, format, timezoneOffset);
        return localDateTime + " " + timezoneAbbr;
    };
    return MomentDatePipe;
}(DatePipe));
export { MomentDatePipe };
