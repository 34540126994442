import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DrugLookupService {
  data: any;
  url = environment.apiBase;
  selectedMemberId;
  constructor(private http: HttpClient) {}

  getMemberDetails(memberId): Observable<any> {
    return this.http
      .get(this.url + 'api/Members?memberid=' + memberId)
      .pipe(catchError(this.handleError));
  }

  getDrugDetails(member, body): Observable<any> {
    return this.http
      .get(
        this.url +
          'api/Drugs/' +
          member +
          '?drugName=' +
          body.name +
          '&ndcNumber=' +
          body.ndcNumber +
          '&gpiNumber=' +
          body.gpiNumber
      )
      .pipe(catchError(this.handleError));
  }

  getCommonUses(body): Observable<any> {
    return this.http
      .get(this.url + 'api/Drugs/GetDrugUses?gpiNumber=' + body)
      .pipe(tap(res => {}));
  }

  postDrug(body): Observable<any> {
    return this.http
      .post(this.url + 'api/Drugs', body, { responseType: 'text' })
      .pipe(tap(res => {}));
  }

  updateDrug(body): Observable<any> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http
      .post(this.url + 'api/Drugs/UpdateDrugPreference', body, {
        headers,
        responseType: 'text'
      })
      .pipe();
  }

  blockDrugs(body): Observable<any> {
    return this.http
      .put(this.url + 'api/Drugs', body, { responseType: 'text' })
      .pipe();
  }

  preferedDrugs(memberId): Observable<any> {
    return this.http
      .get(this.url + 'api/Drugs?memberId=' + memberId)
      .pipe(tap(res => {}));
  }

  deleteDrug(body): Observable<any> {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: body,
      responseType: 'text' as 'text'
    };
    return this.http
      .delete(this.url + 'api/Drugs', options)
      .pipe(catchError(this.handleError));
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      if (error.status >= 400 && error.status < 500) {
        errorMessage = 'Action failed due to bad request';
      } else if (error.status >= 500) {
        errorMessage = 'Action failed due to server side issue';
      }
    }
    return throwError(errorMessage);
  }

  getPdfDownload(body): Observable<any> {
    const query =
      '0?drugName=' +
      body.name +
      '&ndcNumber=' +
      body.ndcNumber +
      '&gpiNumber=' +
      body.gpiNumber;
    const value = {
      key: 0,
      value: query +'&clientId='+localStorage.getItem('clientId')
    };
    return this.http
      .post(this.url + 'api/Drugs/DrugResultAsPdf', value, {
        responseType: 'blob'
      })
      .pipe(catchError(this.handleError));
  }
}
