<div class="servicedown-page">
        <div class="divserverDown">
                <div class="col-md-12 text-center ">
                    <div class="form-group title-primary">Internal server error</div>
                    <div class="form-group">Please contact Administrator</div>
                    <div class="form-group"><a [routerLink]="['/']" class="btn btn-link">Back to Home</a></div>
               </div>
        </div>
</div>
<style>
    .servicedown-page{
        padding:10px;
    }
    .divserverDown{
    border-radius: 5px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.5);
    max-width: 500px;
    background-color: #fff;
    padding:10px;
   margin: 5% auto auto auto;
}
.title-primary{
  margin-bottom: 16px;
  font-size: 24px;
  color:#136ab2;
}
</style>