<div class="changepassword-page">
    <form name="form" id="resetForm" autocomplete="off" (ngSubmit)="f.form.valid && !newPwdErr && !newOPwdErr && onSubmit()" #f="ngForm" novalidate>
        <div class="form">
            <div class="login-logo form-group"><img class="img-responsive img-fluid" style="cursor: pointer;width: 125px;" src="{{imgUrl}}"></div>
            <div class="row" *ngIf="flag">
                <input type="password" class="form-control txt" id="existingpassword" name="oldPwd" [(ngModel)]="pwdDto.oldPwd" #oldPwd="ngModel" [ngClass]="{ 'is-invalid': f.submitted && oldPwd.invalid }" placeholder="Old Password" required />
                <div *ngIf="f.submitted && oldPwd.invalid" class="invalid-feedback">
                    <div *ngIf="oldPwd.errors.required">Old password is required</div>
                </div>
                <div *ngIf="!oldPwd.invalid && oldErr" style="width:100%;">
                    <div *ngIf="oldErr" style="font-size: 80%;display: inline-block;color: #dc3545;">Old Password is incorrect
                    </div>
                </div>

            </div>
            <div class="row">
                <input type="password" id="newpassword" class="form-control txt" name="newPwd" [(ngModel)]="pwdDto.newPwd" #newPwd="ngModel" (blur)="checkPwd()" [ngClass]="{ 'is-invalid': f.submitted && newPwd.invalid }" placeholder="New Password" pattern='(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[\d])(?=.*?[^\sa-zA-Z0-9]).{8,}$'
                    minlength="8" required />
                <div *ngIf="f.submitted && newPwd.invalid" class="invalid-feedback">
                    <div *ngIf="newPwd.errors.required">New password is required</div>
                    <div *ngIf="newPwd.errors.pattern && !newPwd.errors.required">
                        Password should be minimum of 8 characters, must include 1 upper case, 1 lower case, 1 digit and 1 special character.
                    </div>

                </div>

                <div *ngIf=" !newPwd.invalid && newOPwdErr" style="font-size: 80%;color: #dc3545;">
                    Old password and New password cannot be same.
                </div>


            </div>

            <div class="row">
                <input type="password" id="confirmpassword" class="form-control txt" name="conNewPwd" [(ngModel)]="pwdDto.conNewPwd" #conNewPwd="ngModel" [ngClass]="{ 'is-invalid': f.submitted && conNewPwd.invalid }" placeholder="Confirm Password" appConfirmValidator="newPwd"
                    required />
                <div *ngIf="f.submitted && conNewPwd.invalid" class="invalid-feedback">
                    <div *ngIf="conNewPwd.errors.required">Confirm Password is required</div>
                    <div *ngIf="conNewPwd.errors.noEqual && !conNewPwd.errors.required">
                        The New Password and Confirm Password do not match </div>
                </div>

                <div *ngIf=" !conNewPwd.invalid && newPwdErr" style="font-size: 80%;color: #dc3545;">
                    The New Password and Confirm Password do not match
                </div>


            </div>
            <div class="row divbuttons">
                <div class="col-sm-6">
                    <button id="btnResetPassword" type="submit" class="btncontinue" name="command" (click)="validate()" value="Submit">Save</button>
                </div>
                <div class="col-sm-6">
                    <button formnovalidate="formnovalidate" (click)="manageLink()" class="btncontinue" type="reset" name="command" value="Cancel">Cancel</button>
                </div>
            </div>
            <div class="row" style="padding-top:8px;font-size: 14px;">
                <div class="text-left">
                    <span class="PasswordNote"><b>Note:</b> Password should be minimum of 8 characters,must include 1
                        upper case, 1 lower case, 1 digit and 1 special character.</span>
                </div>
            </div>
        </div>
        <input name="__RequestVerificationToken" type="hidden" value="CfDJ8BowgskRhzhAnv1ZHuooAUNaO0gtZgStKMRN6jsCT02H8pdS7uN7DNimLkaM_tEe1NWkP8UYg5W02-zvGzAr6T5tEI_qRTshrNuwmol-aTwWgxmLMqMODuo_7JPl8WIUtkHz1EEQTqtU6AGBNFgLBN8Nfr9T0JmNSfRtPnPy7dn4IM2HhqMYkZ8zHjaIUQFDgw">
    </form>

</div>

<notifier-container></notifier-container>