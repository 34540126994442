import { Component, OnInit } from '@angular/core';
import { DrugLookupService } from './drug-lookup.service';
import { NotifierService } from 'angular-notifier';
import { DatePipe } from '@angular/common';
import { CommanUtils } from 'src/app/Utils/commanUtils.service';
import * as moment from 'moment';
import { Groups } from '../shared/add-update-member/add-update-member.component';
import { MemberDetails } from '../view-member/view-member.component';
import { ViewMemberDetialsService } from '../view-member/view-member.service';
// jQuery declaration
declare var $;
@Component({
  selector: 'app-drug-lookup',
  templateUrl: './drug-lookup.component.html',
  styleUrls: ['./drug-lookup.component.css']
})
export class DrugLookupComponent implements OnInit {
  // Variables
  data = [];
  dId: any;
  memberDetailInfo: MemberDetailInfo = new MemberDetailInfo();
  drugDto: DrugDto = new DrugDto();
  prefDrugDto: PrefDrugDto = new PrefDrugDto();
  blockDrugDto: BlockDrugDto = new BlockDrugDto();
  preferedDatesDto: PreferedDates = new PreferedDates();
  memberInfoDetials: MemberDetails = new MemberDetails();
  employees;
  filterData;
  rowValue = [];
  isquickResposive = false;
  showSerachResult = false;
  noData = false;
  errorMessage: any;
  successMessage: any;
  StartDate: string;
  EndDate: string;
  memberId: number = 0;
  endDate3 = new Date();
  blkd:boolean = false;
  // = localStorage.getItem('memberId') == null ? 82 : localStorage.getItem('memberId');
  memberName: any;
  adjusterId: string = localStorage.getItem('userId');
  adjusterName: string = localStorage.getItem('name');
  minDate: Date = new Date();
  indx: any;
  asc: number = 0;
  direction: number = 0;
  selectedActivity: any;
  expErr: boolean = false;
  op;
  uses: any;
  preferedDrugsData: any;
  drugListResult: boolean = false;
  today = new Date();
  pre: boolean;
  canEdit: boolean = true;
  blkDList = [];
  blk: boolean = false;
ids: any;
med: boolean = false;
preferedDrugs;
removeCheckModel: string = '';
title: string;
startDate1: Date = new Date();
  endDate1: Date = new Date();
  startDate2: Date = new Date();
  endDate2: Date = new Date();
  preferedDateDto: PreferedDateDto = new PreferedDateDto();
  message: string = '';
  memberInfo: MemberDetailInfo = new MemberDetailInfo();
  constructor(
    private drugLookupService: DrugLookupService,
    private commanUtils: CommanUtils,
    private notifier: NotifierService,
    private datePipe: DatePipe,
    private medicinService: DrugLookupService,
    private memberService: ViewMemberDetialsService
  ) {
    this.memberId =
      localStorage.getItem('memberId') == null
        ? 0
        : parseInt(localStorage.getItem('memberId'));
  }

  ngOnInit() {
    $(document).ready(function() {
      $(window).scrollTop(0);
    });
    if (this.memberId === 0) {
    } else {
      this.changeTodefaultDates();
      this.getMemberDetail();
      this.getPreferedMedicins();
      this.getDrugBlockedList();
    }
    if(localStorage.getItem('roleId')=='4' || localStorage.getItem('roleId')=='5'){
      this.canEdit=false;
    }
  }

  // Function for responsive screen
  myserachFunction() {
    if (this.isquickResposive) {
      this.isquickResposive = false;
    } else {
      this.isquickResposive = true;
    }
  }

  // Show all results function
  showSearchResultFunction() {
    this.showSerachResult = true;
  }

  // Reset input box when we press reset button
  reset() {
    this.drugDto.name = '';
    this.drugDto.ndcNumber = '';
    this.drugDto.gpiNumber = '';
    this.filterData = [];
    this.showSerachResult = false;
  }

  // Get the member details through this API
  getMemberDetail() {
    this.drugLookupService.getMemberDetails(this.memberId).subscribe(array => {
      array.forEach(res => {
        this.memberDetailInfo.memberId = res.item2.memberId;
        this.memberDetailInfo.name =
          (res.item2.firstName == null ? '' : res.item2.firstName) +
          ' ' +
          (res.item2.lastName == null ? '' : res.item2.lastName);
        this.memberDetailInfo.cardholderNumber =
          res.item1.cardholderNumber == null ? '' : res.item1.cardholderNumber;
        this.memberDetailInfo.dateOfBirth =
          res.item2.dateOfBirth == null
            ? ''
            : this.datePipe.transform(res.item2.dateOfBirth, 'MM/dd/yyyy');
        if (res.item2.gender.includes('0')) {
          this.memberDetailInfo.gender = 'Trans';
        } else if (res.item2.gender.includes(1)) {
          this.memberDetailInfo.gender = 'Male';
        } else {
          this.memberDetailInfo.gender = 'Female';
        }
        this.memberDetailInfo.address1 =
          res.item2.address1 == null ? '' : res.item2.address1;
        this.memberDetailInfo.address2 =
          res.item2.address2 == null ? '' : res.item2.address2;
        this.memberDetailInfo.city =
          res.item2.city == null ? '' : res.item2.city;
        this.memberDetailInfo.state =
          res.item2.state == null ? '' : res.item2.state;
        this.memberDetailInfo.zip =
          res.item2.zipCode == null ? '' : res.item2.zipCode;
        this.memberDetailInfo.phoneNumber =
          res.item2.phoneNumber == null ? '' : res.item2.phoneNumber;
        this.memberDetailInfo.groupNumber =
          res.item2.groupNumber == null ? '' : res.item2.groupNumber;
        this.memberDetailInfo.emailAddress =
          res.item2.emailAddress == null ? '' : res.item2.emailAddress;
        this.memberDetailInfo.startDate =
          res.item2.startDate == null
            ? '----'
            : this.datePipe.transform(res.item2.startDate, 'MM/dd/yyyy');
        this.memberDetailInfo.endDate =
          res.item2.endDate == null
            ? '---- '
            : this.datePipe.transform(res.item2.endDate, 'MM/dd/yyyy');
        this.memberDetailInfo.ffStartDate =
          res.item2.ffStartDate == null
            ? '----'
            : this.datePipe.transform(res.item2.ffStartDate, 'MM/dd/yyyy');
        this.memberDetailInfo.ffEndDate =
          res.item2.ffEndDate == null
            ? '---- '
            : this.datePipe.transform(res.item2.ffEndDate, 'MM/dd/yyyy');
        this.memberDetailInfo.phntype =
          res.item2.phoneNumberOf == null ? '' : res.item2.phoneNumberOf;
        JSON.stringify(this.memberDetailInfo.phntype);
        this.memberDetailInfo.groups = res.item2.groups;
        if (this.memberDetailInfo.phntype === '1') {
          this.memberDetailInfo.phntype = '(H)';
        } else if (this.memberDetailInfo.phntype === '2') {
          this.memberDetailInfo.phntype = '(W)';
        } else if (this.memberDetailInfo.phntype === '3') {
          this.memberDetailInfo.phntype = '(M)';
        } else {
        }
      });
      this.memberDetailInfo.groups.forEach(item => {
        if (item.ffId == 1) {
          this.memberDetailInfo.groupNumber = item.groupNumber;
        } else {
          this.memberDetailInfo.ffGroupNumber = item.groupNumber;
        }
      });
      if (this.memberDetailInfo.phoneNumber === '') {
        this.memberDetailInfo.phntype = '';
      } else {
        this.memberDetailInfo.phoneNumber = this.commanUtils.convertToInternational(
          this.memberDetailInfo.phoneNumber
        );
      }
    });
  }

  // Get all the drug details through this API
  getDrugDetails() {
    this.drugLookupService
      .getDrugDetails(this.memberId, this.drugDto)
      .subscribe(
        res => {
          this.showSerachResult = true;
          this.data = res;
          if (this.data.length == 0) {
            this.filterData = this.data;
            this.noData = true;
          } else {
            this.noData = false;
            this.filterData = this.data;
          }
        },
        error => {
          this.errorMessage = error;
          this.notifier.notify('error', this.errorMessage);
        }
      );
  }

  // Download PDF through this API
  getPDFDownload() {
    this.drugLookupService.getPdfDownload(this.drugDto).subscribe(res => {
      saveAs(res, 'DrugsList.pdf');
    });
  }

  getCommonUses(e) {
    this.drugLookupService.getCommonUses(e).subscribe(res => {
      $('#uses').modal('show');
      this.uses = res;
    });
  }

  // Post the drug for respective dates through this API
  postprefDrug() {
    this.prefDrugDto.validityStartDate = this.datePipe.transform(
      this.preferedDatesDto.startDate,
      'MM/dd/yyyy'
    );
    this.prefDrugDto.validityEndDate = this.datePipe.transform(
      this.preferedDatesDto.endDate,
      'MM/dd/yyyy'
    );
    this.prefDrugDto.memberName = this.memberDetailInfo.name;
    this.drugLookupService.postDrug(this.prefDrugDto).subscribe(
      () => {
        this.successMessage =
          'Drug added Successfully to preferred Medication list';
        this.notifier.notify('success', this.successMessage);
        this.getDrugDetails();
        this.getPreferedMedicins();
      },
      error => {
        this.errorMessage = error.error;
        this.notifier.notify('error', this.errorMessage);
      }
    );
  }

  // Block the particular drug API
  blockDrug() {
    this.blockDrugDto.validityStartDate = this.datePipe.transform(
      this.preferedDatesDto.startDate,
      'MM/dd/yyyy'
    );
    this.blockDrugDto.validityEndDate = this.datePipe.transform(
      this.preferedDatesDto.endDate,
      'MM/dd/yyyy'
    );
    if (
      this.blockDrugDto.validityStartDate > this.blockDrugDto.validityEndDate
    ) {
      this.notifier.notify('error', 'To date should be more then From date');
    } else {
      this.drugLookupService.blockDrugs(this.blockDrugDto).subscribe(
        () => {
          this.successMessage = 'The medication has been blocked successfully';
          this.notifier.notify('success', this.successMessage);
          this.getDrugDetails();
          this.getDrugBlockedList();
        },
        error => {
          this.errorMessage = error.error;
          this.notifier.notify('error', this.errorMessage);
        }
      );
    }
  }

  // Function to cinvert data into excel file
  exportAsXLSX() {
    if (this.memberId > 0)
      this.commanUtils.exportAsExcel('exportable1', 'DrugsList');
    else this.commanUtils.exportAsExcel('exportable1', 'DrugsList');
  }

  // Select the row to be updated
  selectedRow(item) {
    this.op = '1';
    this.prefDrugDto.gpiNumber = item.gpiNumber;
    this.prefDrugDto.ndcNumber = item.ndcNumber;
    this.prefDrugDto.memberId = this.memberId;
    this.prefDrugDto.memberName = this.memberName;
    this.prefDrugDto.adjusterId = parseInt(this.adjusterId);
    this.prefDrugDto.adjusterName = this.adjusterName;
    this.prefDrugDto.memberId = this.memberId;
    this.prefDrugDto.drugName = item.name;
  }

   // selected row
   selectedRow1(item) {
    this.ids = item.id;
    this.blk = true;
    this.dId = item.id;
    this.med = false;
    this.blockDrugDto = item;
    this.removeCheckModel = 'Medications';
    this.title = 'Medications';
    this.preferedDateDto.startDate = item.validityStartDate;
    this.preferedDateDto.endDate = item.validityEndDate;
    this.setBlked();
  }
  updateMyEndDate(newDate) {
    const newD_ = Object.assign(newDate.value);
    this.endDate3 = new Date(newD_);
    this.endDate3.setDate(this.endDate3.getDate() + 90);
  }
  // Delete Drug row in blocked list
  blkdDrow() {
    this.memberService.unblockDList(this.dId).subscribe(
      res => {
        this.notifier.notify('success', 'Drug unblocked successfully');
        this.getDrugBlockedList();
        this.getDrugDetails();
      },
      error => {
        this.notifier.notify('error', 'Failed to unblock.');
      }
    );
  }


  // Select the row to be blocked
  selectedRowBlock(item) {
    this.op = '2';
    this.blockDrugDto.gpiNumber = item.gpiNumber;
    this.blockDrugDto.ndcNumber = item.ndcNumber;
    this.blockDrugDto.memberId = this.memberId;
    this.blockDrugDto.memberName = this.memberDetailInfo.name;
    this.blockDrugDto.adjusterId = parseInt(this.adjusterId);
    this.blockDrugDto.adjusterName = this.adjusterName;
    this.blockDrugDto.memberId = this.memberId;
    this.blockDrugDto.drugName = item.name;
    this.changeTodefaultDrugDates();
    //this.getDrugBlockedList();
  }

  handlePDFClick() {
    this.getPDFDownload();
  }

  handleExcelClick() {
    this.exportAsXLSX();
  }

  // On select method change
  onChangeActivity(newSelection) {
    this.selectedActivity = newSelection;
    if (this.selectedActivity === '1') {
      this.exportAsXLSX();
    }
    if (this.selectedActivity === '2') {
      // this.exportAsPDF();
      this.getPDFDownload();
    }
  }

  //  Change sort function to this:
  sort(property, i) {
    this.indx = i;
    this.asc = 1;
    this.direction = 2;
    return this.data.sort((a, b) => {
      return this.compare(a[property], b[property]);
    });
  }

  dsort(property, i) {
    this.indx = i;
    this.asc = 0;
    this.direction = 1;
    return this.data.sort((a, b) => {
      return this.compare(b[property], a[property]);
      //  b[property].localeCompare(a[property]);
    });
  }

  compare(a: number | string, b: number | string) {
    return a < b ? -1 : 1;
  }
  // End of sort method

  // Date validation starts
  dates2(e) {
    let a = moment(e.startDate, 'YYYY-MM-DDTHH:mm:ss.SSS').format('YYYY-MM-DD');
    let b = moment(e.endDate, 'YYYY-MM-DDTHH:mm:ss.SSS').format('YYYY-MM-DD');
    if (this.op === '1') {
      let isAfter = moment(a).isSameOrAfter(b);
      if (isAfter) {
        this.notifier.notify('error', 'To date should be more then From date');
        this.expErr = true;
      } else {
        this.expErr = false;
        this.postprefDrug();
      }
    } else {
      let isAfter = moment(b).isSameOrAfter(a);
      if (isAfter) {
        this.expErr = false;
        this.blockDrug();
      } else {
        this.notifier.notify('error', 'To date should be more then From date');
        this.expErr = true;
      }
    }
  }

  updateMyDate(newdate) {
    const newD_ = Object.assign(newdate.value);
    this.preferedDatesDto.endDate = new Date(newD_);
    this.preferedDatesDto.endDate.setDate(
      this.preferedDatesDto.endDate.getDate() + 90
    );
  }

  changeTodefaultDates() {
    this.preferedDatesDto.startDate = new Date();
    this.preferedDatesDto.endDate = new Date();
    this.preferedDatesDto.endDate.setDate(
      this.preferedDatesDto.startDate.getDate() + 90
    );
  }
  changeTodefaultDrugDates() {
    this.preferedDatesDto.startDate = new Date();
    this.preferedDatesDto.endDate = new Date();
    this.preferedDatesDto.endDate.setDate(
      this.preferedDatesDto.startDate.getDate()
    );
  }
  // End of date validation

  // Validation function
  validate() {
    if (
      this.drugDto.name === '' &&
      this.drugDto.gpiNumber === '' &&
      this.drugDto.ndcNumber === ''
    ) {
      this.notifier.notify('error', 'Please provide any one of the field name');
    } else {
      this.showSerachResult = true;
      this.getDrugDetails();
    }
  }
  // Validation ends
  //  compare(a.employer, b.employer, isAsc);


  // Get prefered drug API
  getPreferedMedicins() {
    this.medicinService.preferedDrugs(this.memberId).subscribe(res => {
      if (res.length === 0) {
        this.pre = true;
        this.drugListResult = false;
        this.preferedDrugsData = res;
      } else {
        this.drugListResult = true;
        this.preferedDrugsData = res;
        this.preferedDrugsData.map(obj => {
          if (moment(this.today).isAfter(obj.validityEndDate)) {
            obj.isblur = true;
          } else {
            obj.isblur = false;
          }
          return obj;
        });
      }
    });
  }

 // Update preferred date range API
 updatePreferrenceDateRange() {
  let startDate = this.datePipe.transform(this.startDate2, 'MM/dd/yyyy');
  let endDate = this.datePipe.transform(this.endDate2, 'MM/dd/yyyy');
  var name =
    this.memberInfoDetials.memberDetailDto.firstName +
    this.memberInfoDetials.memberDetailDto.lastName;
   if (this.removeCheckModel.includes('drug')) {
    this.MedData(startDate, endDate);
  } else if (this.removeCheckModel.includes('Medications')) {
    this.blkUpdateDrow(startDate, endDate);
  }
}
// Delete function for all the selections
removeBlock() {
    this.removeDrug();
}

  // Delete Drug API
  removeDrug() {
    const preferredDrugs = JSON.stringify({
      id: this.preferedDrugs.id,
      adjusterId: this.adjusterId,
      memberName: this.memberInfo.name,
      drugName: this.preferedDrugs.drugName
    });
    this.medicinService.deleteDrug(preferredDrugs).subscribe(
      () => {
        this.message =
          'Drug deleted successfully from preferred Medication list';
        this.notifier.notify('success', this.message);
        this.getDrugDetails();
        this.getPreferedMedicins();
      },
      () => {
        this.message = 'Failed To Delete Preferred Medicine!!';
        this.notifier.notify('error', this.message);
      }
    );
  }

  // UpdateBlockedDrug
  blkUpdateDrow(s, e) {
    var isAfter = moment(s).isSame(e);

    if (!isAfter) {
      this.notifier.notify(
        'error',
        'To Date Should be More than/older than From Date!!'
      );
    } else {
      const body = {
        id: this.ids,
        ndcNumber: this.blockDrugDto.ndcNumber,
        drugName: this.blockDrugDto.drugName,
        gpiNumber: this.blockDrugDto.gpiNumber,
        memberId: this.blockDrugDto.memberId,
        memberName: this.blockDrugDto.memberName,
        adjusterId: this.blockDrugDto.adjusterId,
        adjusterName: this.blockDrugDto.adjusterName,
        validityStartDate: s,
        validityEndDate: e,
        isBlocked: true,
        isDisabled: true,
        isChecked: true
      };

      this.memberService.updateBlockedDrug(body).subscribe(res => {
        this.notifier.notify('success', res);
        this.getDrugBlockedList();
      });
      $('#myModal11123').modal('hide');
    }
  }
// Drug Blocked List
getDrugBlockedList() {
  this.memberService.getBlockedDrugList(this.memberId).subscribe(res => {
    this.blkDList = res;
  });
}
MedData(startDate, endDate) {
  var isAfter = moment(startDate).isSameOrAfter(endDate);
  if (isAfter) {
    this.notifier.notify('error', 'To Date Should be More than From Date!!');
  } else {
    const preferredDrugs = JSON.stringify({
      id: this.preferedDrugs.id,
      gpiNumber: this.preferedDrugs.gpiNumber,
      ndcNumber: this.preferedDrugs.ndcNumber,
      adjusterId: this.adjusterId,
      memberId: this.memberId,
      drugName: this.preferedDrugs.drugName,
      memberName: name,
      validityStartDate: startDate,
      validityEndDate: endDate,
      createdDate: this.preferedDrugs.createdDate,
      isBlocked: this.preferedDrugs.isChecked,
      isChecked: this.preferedDrugs.isChecked
    });
    this.medicinService.updateDrug(preferredDrugs).subscribe(
      () => {
        this.message =
          'Successfully Updated Preferred Medication/Drug Range!!';
        this.notifier.notify('success', this.message);
        $('#myModal11123').modal('hide');
        this.getDrugDetails();
        this.getPreferedMedicins();
        //this.getMemberActivity();
      },
      error => {
        this.message = error.error;
        this.notifier.notify('error', this.message);
      }
    );
  }
}
  // Set doctor details to default
  setDoctorEdit() {
    this.startDate2 = this.preferedDateDto.startDate;
    this.endDate2 = this.preferedDateDto.endDate;
  }
  // Set details to default
  setBlked() {
    this.startDate2 = this.preferedDateDto.startDate;
    this.endDate2 = this.preferedDateDto.endDate;
  }
 // Get row details fo the drug
 selectDrugRow(item) {
  this.blk = false;
  this.med = true;
  this.ids = item.id;
  this.preferedDrugs = item;

  this.removeCheckModel = 'drug';
  this.title = 'Drug';
  var day = new Date();
  this.startDate2 = null;
  this.startDate2 = day;
  this.preferedDateDto.startDate = item.validityStartDate;
  this.preferedDateDto.endDate = item.validityEndDate;
  // this.updateMyEndDate(this.startDate2)
}



}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

export class MemberDetailInfo {
  groups: Groups[];
  ffGroupNumber;
  memberId: number;
  cardholderNumber: String = '';
  name: String = '';
  gender: String = '';
  emailAddress: String = '';
  phoneNumber: String = '';
  dateOfBirth: String = '';
  groupNumber: String = '';
  startDate: String = '';
  endDate: String = '';
  address1: String = '';
  phntype: string = '';
  address2: String = '';
  city: string = '';
  state: string = '';
  zip: string = '';
  ffStartDate = '';
  ffEndDate = '';
}

export class DrugDto {
  name: String = '';
  ndcNumber: String = '';
  gpiNumber: String = '';
  cardHolderNumber: String = '';
}

export class PrefDrugDto {
  gpiNumber: number;
  ndcNumber: number;
  drugName: String = '';
  memberId: number;
  memberName: String = '';
  adjusterId: number;
  adjusterName: String = '';
  validityStartDate: string = '';
  validityEndDate: string = '';
}

export class BlockDrugDto {
  gpiNumber: number;
  ndcNumber: number;
  drugName: String = '';
  memberId: number;
  memberName: String = '';
  adjusterId: number;
  adjusterName: String = '';
  validityStartDate: string = '';
  validityEndDate: string = '';
}

export class PreferedDates {
  startDate: Date;
  endDate: Date;
}
export class PreferedDateDto {
  startDate: Date;
  endDate: Date;
}