import { throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ClaimHistoryService = /** @class */ (function () {
    function ClaimHistoryService(http) {
        this.http = http;
        this.url = environment.apiBase;
    }
    ClaimHistoryService.prototype.getMemberDetails = function (memberId) {
        return this.http
            .get(this.url + 'api/Members?memberid=' + memberId)
            .pipe(catchError(this.handleError));
    };
    ClaimHistoryService.prototype.getClaimHistoryByDates = function (memberId, startDate, endDate) {
        return this.http
            .get(this.url +
            'api/Claims/' +
            memberId +
            '/ClaimsHistory?sdate=' +
            startDate +
            '&edate=' +
            endDate)
            .pipe(catchError(this.handleError));
    };
    ClaimHistoryService.prototype.getPDFGenerated = function (memberId, startDate, endDate) {
        var body = {
            key: 0,
            value: '/' +
                memberId +
                '/ClaimsHistory?sdate=' +
                startDate +
                '&edate=' +
                endDate + '&clientId=' + localStorage.getItem('clientId')
        };
        return this.http
            .post(this.url + 'api/Claims/ClaimHistoryAsPdf', body, {
            responseType: 'blob'
        })
            .pipe(catchError(this.handleError));
    };
    ClaimHistoryService.prototype.getClaimByPin = function (body) {
        return this.http
            .post(this.url + 'api/Claims/ValidateAutoApproveClaimByPin', body)
            .pipe();
    };
    ClaimHistoryService.prototype.getClaimHistory = function (memberId) {
        return this.http
            .get(this.url + 'api/Claims/' + memberId + '/ClaimsHistory')
            .pipe(catchError(this.handleError));
    };
    ClaimHistoryService.prototype.saveCliamsHistory = function (body) {
        return this.http
            .post(this.url + 'api/Claims/SaveClaimHistory', body, {
            responseType: 'text'
        })
            .pipe(tap(function (res) { }));
    };
    ClaimHistoryService.prototype.getSavedClaimHistoryByMemberId = function (memberId) {
        return this.http
            .get(this.url + 'api/Claims/' + memberId + '/GetSavedClaimHistory')
            .pipe(tap(function (res) { return res; }));
    };
    ClaimHistoryService.prototype.handleError = function (error) {
        var errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = "Error: " + error.error.message;
        }
        else {
            // server-side error
            if (error.status >= 400 && error.status < 500) {
                errorMessage = 'Action failed due to bad request';
            }
            else if (error.status >= 500) {
                errorMessage = 'Action failed due to server side issue';
            }
        }
        return throwError(errorMessage);
    };
    ClaimHistoryService.ngInjectableDef = i0.defineInjectable({ factory: function ClaimHistoryService_Factory() { return new ClaimHistoryService(i0.inject(i1.HttpClient)); }, token: ClaimHistoryService, providedIn: "root" });
    return ClaimHistoryService;
}());
export { ClaimHistoryService };
