<div class="row" *ngIf="errDisplay">
    <div class="col-md-12">
        <div class="jumbotron text-center">
            <h1>{{errMsgs}}</h1>
        </div>
    </div>
</div>

<div class="container-fluid" id="body" *ngIf="!display && !errDisplay">
    <div class="loginPage">
        <div class="row">

            <div class="login-right-sec col-md-12">
                <div class="title desktop">Enter your PIN</div>
                <div class="modal-body">
                    <form>

                        <div class="form-group">
                            <div class="input-group">
                                <input placeholder="PIN" onpaste="return false;" onCopy="return false" onCut="return false" onDrag="return false" onDrop="return false" [(ngModel)]="password" cMaxLength="4" name="clasim" numbersOnly class="form-control" [type]="hide ? 'text' : 'password'"
                                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                <div class="input-group-append" (click)="hide = !hide">
                                    <span class="input-group-text" style="cursor: pointer; background: #453f40; border:none; color: white; position:absolute;top:0;right: -1px;padding: 0 4px;height:34px;">
                                        <mat-icon matSuffix class="md-18 pointer-hand">
                                            {{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                                    </span>
                                </div>
                            </div>
                            <div *ngIf="errorStatus && !locked" style="margin-top: 2px;color: red">
                                {{loginError}}
                            </div>

                        </div>

                        <div class="form-group">
                            <button [disabled]="password == ''" class="btn btn-primary btn-block" style="font-size: 14px;" (click)="login()">Submit</button>
                        </div>

                    </form>

                </div>
            </div>
        </div>
    </div>
</div>

<!-- The Modal -->
<div class="modal fade" id="myModalz">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content text-center">
            <!-- Modal Header -->
            <!-- <div class="modal-header">
              <h4 class="modal-title">Alert</h4>
              <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div> -->

            <!-- Modal body -->
            <div class="modal-body">
                <div class="row text-center">
                    <div class="col">
                        <h4>Alert</h4><br> {{loginError}}
                    </div>
                </div>
                <div class="row text-center">
                    <div class="col">
                        <button class="btn btn-primary" style="margin-top: 40px;" data-dismiss="modal">Ok</button>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>



<div *ngIf="display && !errDisplay">
    <!-- //*ngIf="type == 'approve'" -->
    <div class="row" *ngIf="display2 === 1">
        <div class="col-md-12">
            <div class="jumbotron text-center">
                <H1>{{msg}}</H1>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="display2 === 2">
        <div class="col-md-12">
            <div class="jumbotron text-center">
                <H1>{{msg}}</H1>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="display2 === 3">
        <div class="col-md-12">
            <div class="jumbotron text-center">
                <H1>{{msg}}</H1>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="display2 === 4">
        <div class="col-md-12">
            <div class="jumbotron text-center">
                <h1>{{msg}}</h1>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="display2 === 6">
        <div class="col-md-12">
            <div class="jumbotron text-center">
                <h1>{{msg}}</h1>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="display2 === 7">
        <div class="col-md-12">
            <div class="jumbotron text-center">
                <h1>{{msg}} {{errorMsg}}</h1>
                <h1>{{msg1}} {{errorMsg1}}</h1>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="display2 === 5">
        <div class="col-md-12">
            <div class="jumbotron text-center">
                <h1 *ngIf="opt!=2">{{errorMsg}}</h1>
                <h1 *ngIf="opt===2">{{errorMsg1}}</h1>
            </div>
        </div>
    </div>
</div>

<!-- The Modal -->
<div class="modal" id="block">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <!-- Modal body -->
            <div class="modal-body">
                <div class="text-center">
                    <h4>Do you want to block?</h4><br />
                    <button id="abc" class="btn btn-default" style="margin-right:15px;" data-dismiss="modal" (click)="blockDrug()">Yes</button><button class="btn btn-default" data-dismiss="modal" (click)="resetDate()">No</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- The Modal -->
<div class="modal" id="addpre">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header " style="background: #136ab2;
color: white;">
                <h4 class="modal-title " *ngIf="opt != 4 && opt != 5">Add to Preferred List</h4>
                <h4 class="modal-title " *ngIf="opt === 4">Approve Claim</h4>

                <h4 class="modal-title " *ngIf="opt === 5">Block Drug</h4>
                <button type="button" id="abc2" style="color: white" class="close" data-dismiss="modal" (click)="resetDate()">&times;</button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                <form name="form" (ngSubmit)="f.form.valid " #f="ngForm">
                    <div class="modal-body-content">
                        <div class="form-group">
                            <div class="col-md-12">
                                <label class="textbx-lable">Select Date Range</label>
                            </div>

                            <div class="row">
                                <div class="col-md-12 form-group-mobile">
                                    <mat-form-field>
                                        <input matInput  [matDatepicker]="picker1"  [(ngModel)]="preferedDatesDto.startDate" ng-model-options="{ timezone: 'UTC' }"  placeholder="From
                Date" required   (dateChange)="dateChange()"   name="startDate" id="startDate" #startDate="ngModel" (dateChange)="updateMyDate($event)">
                                            <mat-datepicker-toggle matSuffix [for]="picker1">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #picker1></mat-datepicker>
                                                    <mat-error *ngIf="startDate.invalid && (startDate.dirty || startDate.touched)">
                                                        Please enter the date in mm/dd/yyyy format
                                                    </mat-error>
                                    </mat-form-field>


                                    <mat-form-field>
                                        <input matInput [min]="preferedDatesDto.startDate" [matDatepicker]="picker2"  [(ngModel)]="preferedDatesDto.endDate" ng-model-options="{ timezone: 'UTC' }"  placeholder="To
                Date" required name="etartDate"   id="etartDate" #endDate="ngModel">
                                            <!-- <mat-error *ngIf="endDate.errors.required">End Date is required</mat-error> -->
                                            <mat-datepicker-toggle matSuffix [for]="picker2">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #picker2></mat-datepicker>
                                                    <mat-error *ngIf="endDate.invalid && (endDate.dirty || endDate.touched)">
                                                        Please enter the date in mm/dd/yyyy format</mat-error>
                                    </mat-form-field>

                        </div>
                    </div>

            </div>
        </div>
        <div class="modal-button">
            <button class="btn btn-primary btn-sm" type="button" data-dismiss="modal" *ngIf="opt === 1" (click)="validateDate(preferedDatesDto)" [disabled]="f.invalid">OK</button>
            <button class="btn btn-primary btn-sm" type="button" data-dismiss="modal" *ngIf="opt === 2" (click)="validateDate(preferedDatesDto)" [disabled]="f.invalid">OK</button>
            <button class="btn btn-primary btn-sm" type="button" data-dismiss="modal" *ngIf="opt === 3" (click)="validateDate(preferedDatesDto)" [disabled]="f.invalid">OK</button>
            <button class="btn btn-primary btn-sm" type="button" data-dismiss="modal" *ngIf="opt === 4" (click)="validateDate(preferedDatesDto)" [disabled]="f.invalid">OK</button>
            <button class="btn btn-primary btn-sm" type="button" data-dismiss="modal" *ngIf="opt === 5" (click)="validateDate(preferedDatesDto)" [disabled]="f.invalid">OK</button>
        </div>
        </form>
    </div>
</div>
</div>
</div>

<notifier-container></notifier-container>