import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GeneratePasswordService {
  url = environment.apiBase;
  constructor(private http: HttpClient) {}

  reset(data) {
    return this.http
      .put(this.url + 'api/Account/ResetPassword', data, {
        responseType: 'text'
      })
      .pipe(tap(res => {}));
  }

  validate(id): Observable<any> {
    const token = {
      userId: 'string',
      value: id
    };
    return this.http
      .post(this.url + 'api/Account/ValidateUser/ValidateUser', token, {
        responseType: 'text'
      })
      .pipe(tap(res => {}));
  }

  validateClaim(id): Observable<any> {
    return this.http
      .get(this.url + 'api/Claims/ValidateAutoApproveClaim?id=' + id)
      .pipe(tap(res => {}));
  }
}
