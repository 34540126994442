import { Component, OnInit } from '@angular/core';
import { ChangePasswordService } from './changepassword.service';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { LoginService } from '../login/login.service';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {
  // variables
  pwdDto: PwdDto = new PwdDto();
  submitted: boolean;
  error: any;
  role: boolean = false;
  value;
  flag: boolean = false;
  pwd = localStorage.getItem('pwd');
  oldErr: boolean = false;
  newPwdErr: boolean = false;
  newOPwdErr: boolean = false;
  imgUrl: any;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private changePasswordService: ChangePasswordService,
    private notifier: NotifierService
  ) {}

  ngOnInit() {
    let submainName = window.location.hostname.split(".")[0];
    this.loginService.getImageUrl(submainName).subscribe(res => {
      this.imgUrl = res;
    });
    if (localStorage.getItem('roleId') != null) {
      this.role = true;
    } else {
      this.role = false;
    }
    if (localStorage.getItem('token')) {
    } else {
      this.router.navigate(['/']);
    }

    this.value = localStorage.getItem('first');

    if (this.value == 'false') {
      this.flag = false;
    } else {
      this.flag = true;
    }
  }

  // On form submit
  onSubmit() {
    this.submitted = true;
    if (this.flag) {
      if (this.pwdDto.oldPwd === this.pwdDto.newPwd) {
        this.newOPwdErr = true;
      } else {
        this.login();
      }
    } else {
      this.reset();
    }
  }

  login() {
    var body = {
      email: localStorage.getItem('email').trim(),
      password: this.pwdDto.oldPwd
    };
    this.loginService.login(body).subscribe(
      res => {
        this.pwdDto.oldPwd='';
        this.newOPwdErr = false;
        this.oldErr = false;
        this.reset();
      },
      error => {
        this.oldErr = true;
      }
    );
  }

  // Reset Password API
  reset() {
    const body = {
      userId: localStorage.getItem('email').trim(),
      value: this.pwdDto.newPwd
    };
    this.changePasswordService.reset(body).subscribe(
      res => {
        this.pwdDto.newPwd='';
        this.notifier.notify(
          'success',
          'Password has been changed! Login again.'
        );
        setTimeout(() => {
          this.router.navigate(['/']);
          this.clear();
        }, 2000);
      },
      error => {
        this.error = error;

        this.notifier.notify('error', error.error);
      }
    );
    // localStorage.clear();
  }

  clear() {
    localStorage.clear();
  }

  // Manage redirection
  manageLink() {
    if (!this.flag) {
      this.router.navigate(['/login']);
    } else {
      if (localStorage.getItem('roleId') == '1') {
        this.router.navigate(['admin/home']);
      } else if (localStorage.getItem('roleId') == '2') {
        this.router.navigate(['client/home']);
      } else {
        this.router.navigate(['adjuster/home']);
      }
    }
  }

  // Validation starts
  //  validzxc() {
  //    if (this.pwd === this.pwdDto.oldPwd) {
  //      this.oldErr = false
  //    }
  //    else {
  //      this.oldErr = true
  //    }
  //  }

  validate() {
    if (this.pwdDto.newPwd === this.pwdDto.conNewPwd) {
      this.newPwdErr = false;
    } else {
      this.newPwdErr = true;
    }
  }

  checkPwd() {
    if (this.flag) {
      if (this.pwdDto.oldPwd === this.pwdDto.newPwd) {
        this.newOPwdErr = true;
      } else {
        this.newOPwdErr = false;
      }
    }
  }
  // validation ends
}

// Password DTO
export class PwdDto {
  oldPwd: string;
  newPwd: string;
  conNewPwd: string;
}
