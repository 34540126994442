import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  url = environment.apiBase;
  userId = localStorage.getItem('userId');
  private newsCountSource = new BehaviorSubject<Number>(0);
  newsCount = this.newsCountSource.asObservable();

  private companyNameSource = new BehaviorSubject<string>('');
  companyName = this.companyNameSource.asObservable();

  constructor(private http: HttpClient) {}

  updatedNewsCount(newsCount: Number) {
    this.newsCountSource.next(newsCount);
  }

  updatedCompanyName(companyName: string) {
    this.companyNameSource.next(companyName);
  }

  messages;
  setNews(msg) {
    this.messages = msg;
  }
  getNews() {
    return this.messages;
  }
  getClient(clientId): Observable<any> {
    return this.http
      .get(
        this.url + 'api/Clients/' + clientId
      )
      .pipe(tap(res => {}));
  }
  getUnreadNews(id): Observable<any> {
    return this.http
      .get(this.url + 'api/News/GetUnReadNewsCount?userId=' + id)
      .pipe(tap(res => {}));
  }
  getPin(id, pin): Observable<any> {
    return this.http
      .get(this.url + 'api/Clients/Pin/' + id + '/' + pin)
      .pipe(tap(res => {}));
  }
  getChosenPin(id, chosenPin): Observable<any> {

    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http
      .post(this.url + 'api/Clients/GetChosenPin/' + id + '/' + chosenPin, { headers, responseType: 'text' })
      .pipe(tap(res => { }));
  }
}
