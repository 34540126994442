
export const environment = {
  production: true,
  apiBase:"https://comppbmprodapi.azurewebsites.net/",
  //apiBase:"https://dev-compdmepbmapi.azurewebsites.net/",  
  //apiBase: 'https://comppbmuatapi.azurewebsites.net/',
  //apiBase: 'http://localhost:908/',
  //apiBase: 'http://localhost:8088/',
 time:300000
};

