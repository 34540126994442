import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap, retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CardService {
  data: any;
  url = environment.apiBase;
  constructor(private http: HttpClient) {}
  getMemberCardTemplate(Id): Observable<any> {
    return this.http
      .get(this.url + 'api/MemberCard/GetCard?id=' + Id)
      .pipe(catchError(this.handleError));
  }
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      if (error.status >= 400 && error.status < 500) {
        errorMessage = 'Action failed due to bad request';
      } else if (error.status >= 500) {
        errorMessage = 'Action failed due to server side issue';
      }
    }
    return throwError(errorMessage);
  }
}
