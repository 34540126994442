import { Component, OnInit, ViewChild } from '@angular/core';
import { ViewMemberDetialsService } from './view-member.service';
import { DatePipe } from '@angular/common';
import { DrugLookupService } from '../drug-lookup/drug-lookup.service';
import { PhysicianLookupService } from '../physician-lookup/physician-lookup.service';
import { MemberService } from '../member/member.service';
import { Router, ActivatedRoute, Params  } from '@angular/router';
import * as moment from 'moment';
import { NotifierService } from 'angular-notifier';
import { ClaimHistoryService } from '../claims-history/claims-history.service';
import { FormGroup } from '@angular/forms';
import { CommanUtils } from 'src/app/Utils/commanUtils.service';
import { Groups } from '../shared/add-update-member/add-update-member.component';
import { MatDatepicker } from '@angular/material';
import {
  PrefDrugDto,
  BlockDrugDto
} from '../drug-lookup/drug-lookup.component';
import { PreferedPhysicianDto, PhysicianDto } from '../physician-lookup/physician-lookup.component';
import { ExcelService } from 'src/app/Utils/excel.service';

// jQuery declaration
declare var $;

@Component({
  selector: 'app-view-member',
  templateUrl: './view-member.component.html',
  styleUrls: ['./view-member.component.css']
})
export class ViewMemberComponent implements OnInit {
  //  variables
  panelOpenState = false;
  p: number = 1;
  p1: number = 1;
  p2: number = 1;
  p3: number = 1;
  p4: number = 1;
  p5: number = 1;
  p6: number = 1;
  p7: number = 1;
  p8: number = 1;
  p9: number = 1;
  p10: number = 1;
  p33: number = 1;
  p44: number = 1;
  @ViewChild('picker61') picker: MatDatepicker<Date>;
  @ViewChild('picker61DI') picker1: MatDatepicker<Date>;
  preferedPhysicianDto: PreferedPhysicianDto = new PreferedPhysicianDto();
  blockDrugDto: BlockDrugDto = new BlockDrugDto();
  memberInfo: MemberDetailInfo = new MemberDetailInfo();
  memberInfoDetials: MemberDetails = new MemberDetails();
  rejectionDetails: Rejections = new Rejections();
  memberNoteObject: MemberNotesObject = new MemberNotesObject();
  preferedDateDto: PreferedDateDto = new PreferedDateDto();
  claimsdDto: ClaimsdDto = new ClaimsdDto();
  saveClaimsHistoryDto: SaveClaimsHistoryDto = new SaveClaimsHistoryDto();
  memberDto;
  memberDetailDto;
  memberId;
  adjusterId;
  adjusterName: string = localStorage.getItem('name');
  preferedPhysiciansData: any;
  preferedDrugsData: any;
  selectedActivity: string = '';
  message: string = '';
  description: string = '';
  id: number;
  startDate1: Date = new Date();
  endDate1: Date = new Date();
  startDate2: Date = new Date();
  endDate2: Date = new Date();
  startDate3: Date = new Date();
  endDate3: Date = new Date();
  editable: boolean = false;
  drugListResult: boolean = false;
  doctorListResult: boolean = false;
  memberNotesResult: boolean = false;
  memberActivityResult: boolean = false;
  memberRejectionResult: boolean = false;
  claimHistoryResult: boolean = false;
  isSubscribe:boolean=false;
  removeCheckModel: string = '';
  preferedPhysicians;
  preferedDrugs;
  memberNotesList = [];
  currentActivitysList = [];
  memberRejectionList = [];
  claimHistoryList = [];
  today = new Date();
  title: string;
  noteTitle: string = 'Add New';
  claimRecordsResult: boolean;
  claimRecordsList: any;
  filterData: any;
  registerForm: FormGroup;
  submitted: boolean;
  lastNameRequried: boolean = false;
  firstNameRequried: boolean;
  genderRequried: boolean;
  claimHistoryLog: any;
  cardHolder: any;
  med: boolean = false;
  groupNumbers: any;
  newNotes: boolean;
  blkDList = [];
  blkPList = [];
  dId: any;
  isNNum: boolean;
  noteErr: string;
  isFname: boolean;
  fnameErr: string;
  lnameErr: string;
  isLname: boolean;
  EnameErr: string;
  isEname: boolean;
  phneErr: boolean;
  phnMsg: string;
  phnMsg1: string;
  phneErr1: boolean;
  emailErrz: boolean;
  msg: string;
  num: string;
  pharma :any;
  pre: boolean;
  FFNum: boolean;
  OnNum: boolean;
  maxDate = new Date(2099, 0, 1);
  minDate = new Date(1900, 0, 1);
  expErr: boolean;
  expErrFF: boolean;
  maxDate1 = new Date();
  error: any;
  searchText;
  searchTexts;
  searchBlockedMedTexts;
  searchBlockedPhyTexts;
  dateErr: boolean;
  dateMsg: string;
  blkNum = 1;
  dateErr1: boolean;
  dateMsg1: string;
  blk: boolean = false;
  blkd:boolean = false;
  prd: boolean = false;
  ids: any;
  npi: any;
  data: any;
  pdta = [];
  rID: any;
  adjData: any;
  adjId: any;
  adjsData: any;
  adjsId: any;
  canEdit: boolean = true;
  memPId: string;
  activeMemberInfo: any = {};
  physicianInfo:any = {};
  physicianData: PhysicianDto  = new PhysicianDto();
  isInit : boolean = true;
  filterDataHistory: any;
  noDataHistory = false;
  toDate: boolean = false;
  memberDetailInfo: MemberDetailInfo = new MemberDetailInfo();
  constructor(
    private router: Router,
    private excelService: ExcelService,
    private memberService: ViewMemberDetialsService,
    private datePipe: DatePipe,
    private doctorSercice: PhysicianLookupService,
    private medicinService: DrugLookupService,
    private memberProfileService: MemberService,
    private notifier: NotifierService,
    private claimHistoryService: ClaimHistoryService,
      private commanUtils: CommanUtils,
      private route: ActivatedRoute,
    private memService: MemberService
  ) {
    // this.route.params.subscribe(params => { this.memberId = params.id });
    // this.memberService.setMemberProfileId(this.memberId);
    this.memberId = parseInt(localStorage.getItem('memberId'));
  }

    ngOnInit() {
        let memPId = localStorage.getItem('memberId');
        localStorage.setItem('navFlag', '1');
        // console.log("memPId",this.memPId, localStorage.getItem('navFlag'))
        let currentUser = localStorage.getItem('token');
        if (currentUser) {
            this.router.navigate(['adjuster/memberprofile']);
        } else {
            this.router.navigate(['/']);
        }
    $(document).ready(function() {
      $(window).scrollTop(0);
    });
    // this.memberId = this.memberService.getMemberProfileId() == null ? 0 : this.memberService.getMemberProfileId();
    if(localStorage.getItem('roleId')=='4' || localStorage.getItem('roleId')=='5'){
      this.canEdit=false;
    }
    if (
      localStorage.getItem('roleId') === '1' ||
      localStorage.getItem('roleId') === '2'
    ) {
      this.adjusterId = localStorage.getItem('adjId');
    } else {
      this.adjusterId = localStorage.getItem('userId');
    }

    if (this.memberId == 0) {
      this.router.navigate(['adjuster/home']);
    } else {
      this.selectedActivity = '1';
      this.getMemberProfileInfo();
      this.getPreferedDoctors();
      this.getPreferedMedicins();
      this.getMemberPreferredDoctor();
      this.getMemberNotes();
      this.getMemberActivity();
      //this.getMemberRejections('', '');
      this.getClaimRecords();
      this.getMemberClaimHistory();
      this.getDrugBlockedList();
      this.getphyBlockedList();
      // this.getMemberDetail();
      this.memberService.getLastestClaim(this.memberId).subscribe(res => {
        if (res.pharmacy === null) {
          this.pharma = {};
        } else {
          this.pharma = res.pharmacy;
        }
      });
    }
  }

  // Drug Blocked List
  getDrugBlockedList() {
    this.memberService.getBlockedDrugList(this.memberId).subscribe(res => {
      this.blkDList = res;
      //  this.blkDList.forEach(item => {
      //    item.createdDate = moment(item.createdDate, 'YYYY-MM-DDTHH:mm:ss.SSS').format('MM/DD/YYYY');
      //    item.validityStartDate = moment(item.validityStartDate, 'YYYY-MM-DDTHH:mm:ss.SSS').format('MM/DD/YYYY');
      //    item.validityEndDate = moment(item.validityEndDate, 'YYYY-MM-DDTHH:mm:ss.SSS').format('MM/DD/YYYY');
      //  })
    });
  }

  // Phy Blocked List
  getphyBlockedList() {
    this.memberService.getBlockedPhyList(this.memberId).subscribe(res => {
      this.blkPList = res;
      //  this.blkPList.forEach(item => {
      //    item.createdOn = moment(item.createdOn, 'YYYY-MM-DDTHH:mm:ss.SSS').format('MM/DD/YYYY');
      //    item.validityStartDate = moment(item.validityStartDate, 'YYYY-MM-DDTHH:mm:ss.SSS').format('MM/DD/YYYY');
      //    item.validityEndDate = moment(item.validityEndDate, 'YYYY-MM-DDTHH:mm:ss.SSS').format('MM/DD/YYYY');
      //  })
    });
  }

  // selected row
  selectedRow(item) {
    this.ids = item.id;
    this.blk = true;
    this.dId = item.id;
    this.med = false;
    this.blockDrugDto = item;
    this.removeCheckModel = 'Medications';
    this.title = 'Medications';
    this.preferedDateDto.startDate = item.validityStartDate;
    this.preferedDateDto.endDate = item.validityEndDate;
    this.setBlked();
  }

  selectedPRow(item) {
    this.npi = item.npinumber;
    this.blk = true;
    this.dId = item.id;
    this.med = false;
    this.preferedPhysicianDto = item;
    this.removeCheckModel = 'Physicians';
    this.title = 'Physicians';
    this.preferedDateDto.startDate = item.validityStartDate;
    this.preferedDateDto.endDate = item.validityEndDate;
    this.setpBlked();
  }

  // Delete Drug row in blocked list
  blkdDrow() {
    this.memberService.unblockDList(this.dId).subscribe(
      res => {
        this.notifier.notify('success', 'Drug unblocked successfully');
        this.getDrugBlockedList();
        this.getMemberActivity();
      },
      error => {
        this.notifier.notify('error', 'Failed to unblock.');
      }
    );
  }

  // UpdateBlockedDrug
  blkUpdateDrow(s, e) {
    var isAfter = moment(s).isSame(e);

    if (!isAfter) {
      this.notifier.notify(
        'error',
        'To Date Should be More than/older than From Date!!'
      );
    } else {
      const body = {
        id: this.ids,
        ndcNumber: this.blockDrugDto.ndcNumber,
        drugName: this.blockDrugDto.drugName,
        gpiNumber: this.blockDrugDto.gpiNumber,
        memberId: this.blockDrugDto.memberId,
        memberName: this.blockDrugDto.memberName,
        adjusterId: this.blockDrugDto.adjusterId,
        adjusterName: this.blockDrugDto.adjusterName,
        validityStartDate: s,
        validityEndDate: e,
        isBlocked: true,
        isDisabled: true,
        isChecked: true
      };

      this.memberService.updateBlockedDrug(body).subscribe(res => {
        this.notifier.notify('success', res);
        this.getDrugBlockedList();
      });
      $('#myModal11123').modal('hide');
    }
  }

  // UpdateBlockedPhy
  blkUpdateProw(s, e) {
    var isAfter = moment(s).isSame(e);

    if (!isAfter) {
      this.notifier.notify('error', 'To Date Should be same as From Date!!');
    } else {
      const body = {
        id: this.preferedPhysicianDto.id,
        physicianId: this.preferedPhysicianDto.physicianId,
        adjusterId: this.preferedPhysicianDto.adjusterId,
        memberId: this.memberId,
        memberName: name,
        physicianName: this.preferedPhysicianDto.physicianName,
        validityStartDate: s,
        validityEndDate: e,
        isBlocked: true,
        physicianDetails: this.preferedPhysicianDto,
        npinumber: this.npi,
        isDisabled: true,
        isChecked: true
      };
      this.memberService.updateBlockedPhy(body).subscribe(
        res => {
          this.notifier.notify('success', res);
          this.getphyBlockedList();
        },
        error => {
          this.notifier.notify('error', error.error);
        }
      );
      $('#myModal11123').modal('hide');
    }
  }

  // Delete Phy row in blocked list
  blkdProw() {
    this.memberService.unblockPList(this.dId).subscribe(
      res => {
        this.getphyBlockedList();
        this.getMemberActivity();
        this.notifier.notify('success', 'Physician unblocked successfully');
      },
      error => {
        this.notifier.notify('error', error.error);
      }
    );
  }

  // Edit member profile
  editMemberProfile() {
    this.editable = true;
    if (this.memberInfoDetials.memberDetailDto.gender.includes('Male')) {
      this.memberInfo.gender = '1';
    } else if (
      this.memberInfoDetials.memberDetailDto.gender.includes('Female')
    ) {
      this.memberInfo.gender = '2';
    } else if (
      this.memberInfoDetials.memberDetailDto.gender.includes('Trans')
    ) {
      this.memberInfo.gender = '0';
    }
    this.memberInfoDetials.memberDetailDto.startDate =
      this.memberInfoDetials.memberDetailDto.startDate == null
        ? ''
        : moment(
            this.memberInfoDetials.memberDetailDto.startDate,
            'MM/DD/YYYY'
          ).format('YYYY-MM-DDTHH:mm:ss.SSS');
    this.memberInfoDetials.memberDetailDto.endDate =
      this.memberInfoDetials.memberDetailDto.endDate == null
        ? ''
        : moment(
            this.memberInfoDetials.memberDetailDto.endDate,
            'MM/DD/YYYY'
          ).format('YYYY-MM-DDTHH:mm:ss.SSS');
    this.memberInfoDetials.memberDetailDto.ffStartDate =
      this.memberInfoDetials.memberDetailDto.ffStartDate == null
        ? ''
        : moment(
            this.memberInfoDetials.memberDetailDto.ffStartDate,
            'MM/DD/YYYY'
          ).format('YYYY-MM-DDTHH:mm:ss.SSS');
    this.memberInfoDetials.memberDetailDto.ffEndDate =
      this.memberInfoDetials.memberDetailDto.ffEndDate == null
        ? ''
        : moment(
            this.memberInfoDetials.memberDetailDto.ffEndDate,
            'MM/DD/YYYY'
          ).format('YYYY-MM-DDTHH:mm:ss.SSS');
    this.memberInfoDetials.memberDetailDto.dateOfBirth =
      this.memberInfoDetials.memberDetailDto.dateOfBirth == null
        ? ''
        : moment(
            this.memberInfoDetials.memberDetailDto.dateOfBirth,
            'MM/DD/YYYY'
          ).format('YYYY-MM-DDTHH:mm:ss.SSS');
    this.memberInfoDetials.memberDetailDto.depositDateTime =
      this.memberInfoDetials.memberDetailDto.depositDateTime == null
        ? ''
        : moment(
            this.memberInfoDetials.memberDetailDto.depositDateTime,
            'MM/DD/YYYY'
          ).format('YYYY-MM-DDTHH:mm:ss.SSS');
    this.memberInfoDetials.memberDetailDto.accountBalanceDate =
      this.memberInfoDetials.memberDetailDto.accountBalanceDate == null
        ? ''
        : moment(
            this.memberInfoDetials.memberDetailDto.accountBalanceDate,
            'MM/DD/YYYY'
          ).format('YYYY-MM-DDTHH:mm:ss.SSS');
    this.memberInfoDetials.memberDetailDto.dateOfInjury =
      this.memberInfoDetials.memberDetailDto.dateOfInjury == null
        ? ''
        : moment(
            this.memberInfoDetials.memberDetailDto.dateOfInjury,
            'MM/DD/YYYY'
          ).format('YYYY-MM-DDTHH:mm:ss.SSS');
    // this.getMemberGroups();
  }

  // Delete function for all the selections
  removeBlock() {
    if (this.removeCheckModel.includes('physician')) {
      this.removePhysician();
    } else if (this.removeCheckModel.includes('drug')) {
      this.removeDrug();
    } else if (this.removeCheckModel.includes('note')) {
      this.removeNote();
    } else if (this.removeCheckModel.includes('claimHistoryLogs')) {
      this.removeClaimHistoryLog();
    }
  }

  // Delete claim history log API
  removeClaimHistoryLog() {
    this.memberProfileService
      .deleteMemberClaimHistoryLog(this.claimHistoryLog.id)
      .subscribe(
        () => {
          this.message = 'Successfully Deleted Saved Claim History Log!!';
          this.notifier.notify('success', this.message);
          this.getMemberClaimHistory();
        },
        () => {
          this.message = 'Failed To Deleted Saved Claim History Log!!';
          this.notifier.notify('success', this.message);
        }
      );
  }

  // Delete Physician API
  removePhysician() {
    const preferedPhysicians = JSON.stringify({
      id: this.preferedPhysicians.id,
      adjusterId: this.adjusterId,
      memberName: this.memberInfo.name,
      physicianName: this.preferedPhysicians.physicianName
    });
    this.doctorSercice.deletePhysician(preferedPhysicians).subscribe(
      () => {
        this.message =
          'Physician deleted successfully from preferred Physician list';
        this.notifier.notify('success', this.message);
        this.getPreferedDoctors();
      },
      () => {
        this.message = 'Failed To Delete Preferred Doctor!!';
        this.notifier.notify('error', this.message);
      }
    );
  }

  // Delete Drug API
  removeDrug() {
    const preferredDrugs = JSON.stringify({
      id: this.preferedDrugs.id,
      adjusterId: this.adjusterId,
      memberName: this.memberInfo.name,
      drugName: this.preferedDrugs.drugName
    });
    this.medicinService.deleteDrug(preferredDrugs).subscribe(
      () => {
        this.message =
          'Drug deleted successfully from preferred Medication list';
        this.notifier.notify('success', this.message);
        this.getPreferedMedicins();
      },
      () => {
        this.message = 'Failed To Delete Preferred Medicine!!';
        this.notifier.notify('error', this.message);
      }
    );
  }

  toggle() {
    this.picker.open();
  }
  toggle1() {
    this.picker1.open();
  }

  // Update Member details API
  saveMemberProfile() {
    var dob;
    var sDate;
    var eDate;
    var ffsDate;
    var ffeDate;
    var actDate;
    var dpDate;
    var doi;

    dob = moment(
      this.memberInfoDetials.memberDetailDto.dateOfBirth,
      'YYYY-MM-DD hh:mm:ss A Z'
    ).format('MM/DD/YYYY');
    if (
      this.memberInfoDetials.memberDetailDto.startDate === null ||
      this.memberInfoDetials.memberDetailDto.startDate === 'Invalid date' ||
      this.memberInfoDetials.memberDetailDto.startDate === ''
    ) {
      sDate = '';
    } else {
      sDate = moment(
        this.memberInfoDetials.memberDetailDto.startDate,
        'YYYY-MM-DD hh:mm:ss A Z'
      ).format('MM/DD/YYYY');
    }
    if (
      this.memberInfoDetials.memberDetailDto.endDate === null ||
      this.memberInfoDetials.memberDetailDto.endDate === 'Invalid date' ||
      this.memberInfoDetials.memberDetailDto.endDate === ''
    ) {
      eDate = '';
    } else {
      eDate = moment(
        this.memberInfoDetials.memberDetailDto.endDate,
        'YYYY-MM-DD hh:mm:ss A Z'
      ).format('MM/DD/YYYY');
    }
    if (
      this.memberInfoDetials.memberDetailDto.ffStartDate === null ||
      this.memberInfoDetials.memberDetailDto.ffStartDate === 'Invalid date' ||
      this.memberInfoDetials.memberDetailDto.ffStartDate === ''
    ) {
      ffsDate = '';
    } else {
      ffsDate = moment(
        this.memberInfoDetials.memberDetailDto.ffStartDate,
        'YYYY-MM-DD hh:mm:ss A Z'
      ).format('MM/DD/YYYY');
    }
    if (
      this.memberInfoDetials.memberDetailDto.ffEndDate === null ||
      this.memberInfoDetials.memberDetailDto.ffEndDate === 'Invalid date' ||
      this.memberInfoDetials.memberDetailDto.ffEndDate === ''
    ) {
      ffeDate = '';
    } else {
      ffeDate = moment(
        this.memberInfoDetials.memberDetailDto.ffEndDate,
        'YYYY-MM-DD hh:mm:ss A Z'
      ).format('MM/DD/YYYY');
    }
    if (
      this.memberInfoDetials.memberDetailDto.accountBalanceDate === null ||
      this.memberInfoDetials.memberDetailDto.accountBalanceDate ===
        'Invalid date' ||
      this.memberInfoDetials.memberDetailDto.accountBalanceDate === ''
    ) {
      actDate = '';
    } else {
      actDate = moment(
        this.memberInfoDetials.memberDetailDto.accountBalanceDate,
        'YYYY-MM-DD hh:mm:ss A Z'
      ).format('MM/DD/YYYY');
    }
    if (
      this.memberInfoDetials.memberDetailDto.depositDateTime === null ||
      this.memberInfoDetials.memberDetailDto.depositDateTime ===
        'Invalid date' ||
      this.memberInfoDetials.memberDetailDto.depositDateTime === ''
    ) {
      dpDate = '';
    } else {
      dpDate = moment(
        this.memberInfoDetials.memberDetailDto.depositDateTime,
        'YYYY-MM-DD hh:mm:ss A Z'
      ).format('MM/DD/YYYY');
    }
    if (
      this.memberInfoDetials.memberDetailDto.dateOfInjury === null ||
      this.memberInfoDetials.memberDetailDto.dateOfInjury === '' ||
      this.memberInfoDetials.memberDetailDto.dateOfInjury === 'Invalid date'
    ) {
      doi = '';
    } else {
      doi = moment(
        this.memberInfoDetials.memberDetailDto.dateOfInjury,
        'YYYY-MM-DD hh:mm:ss A Z'
      ).format('MM/DD/YYYY');
    }

    if (
      this.isEname ||
      this.isFname ||
      this.isLname ||
      this.dateErr ||
      this.dateErr1 ||
      this.emailErrz ||
      this.phneErr ||
      this.phneErr1 ||
      this.expErr ||
      this.expErrFF
    ) {
    } else {
      if (
        this.memberInfoDetials.memberDetailDto.ffGroupNumber === '' ||
        this.memberInfoDetials.memberDetailDto.ffGroupNumber === null
      ) {
        this.memberInfoDetials.memberDetailDto.ffStartDate = '';
        this.memberInfoDetials.memberDetailDto.ffEndDate = '';
      } else {
        // this.memberInfoDetials.memberDetailDto.ffStartDate = moment(this.memberInfoDetials.memberDetailDto.ffStartDate, 'YYYY-MM-DDTHH:mm:ss.SSS').format('MM/DD/YYYY');
        // this.memberInfoDetials.memberDetailDto.ffEndDate = moment(this.memberInfoDetials.memberDetailDto.ffEndDate, 'YYYY-MM-DDTHH:mm:ss.SSS').format('MM/DD/YYYY');
      }
      if (
        this.memberInfoDetials.memberDetailDto.groupNumber === '' ||
        this.memberInfoDetials.memberDetailDto.groupNumber === null
      ) {
        this.memberInfoDetials.memberDetailDto.startDate = '';
        this.memberInfoDetials.memberDetailDto.endDate = '';
      } else {
        // this.memberInfoDetials.memberDetailDto.startDate = moment(this.memberInfoDetials.memberDetailDto.startDate, 'YYYY-MM-DDTHH:mm:ss.SSS').format('MM/DD/YYYY');
        // this.memberInfoDetials.memberDetailDto.endDate = moment(this.memberInfoDetials.memberDetailDto.endDate, 'YYYY-MM-DDTHH:mm:ss.SSS').format('MM/DD/YYYY');
      }
      // this.memberInfoDetials.memberDetailDto.dateOfBirth = moment(this.memberInfoDetials.memberDetailDto.dateOfBirth, 'YYYY-MM-DDTHH:mm:ss.SSS').format('MM/DD/YYYY');

      if (!this.memberInfoDetials.memberDetailDto.newMsaaccount) {
        this.memberInfoDetials.memberDetailDto.depositDateTime = '';
        this.memberInfoDetials.memberDetailDto.accountBalanceDate = '';
      }
      //  if (this.memberInfoDetials.memberDetailDto.dateOfInjury == 'Invalid date' || this.memberInfoDetials.memberDetailDto.dateOfInjury === null) {
      //    this.memberInfoDetials.memberDetailDto.dateOfInjury = ''
      //  }
      //  if (this.memberInfoDetials.memberDetailDto.depositDateTime == 'Invalid date' || this.memberInfoDetials.memberDetailDto.depositDateTime === null) {
      //    this.memberInfoDetials.memberDetailDto.depositDateTime = ''
      //  }
      //  if (this.memberInfoDetials.memberDetailDto.accountBalanceDate == 'Invalid date' || this.memberInfoDetials.memberDetailDto.accountBalanceDate === null) {
      //    this.memberInfoDetials.memberDetailDto.accountBalanceDate = ''
      //  }

      const memberDetails = {
        memberDto: {
          id: this.memberInfoDetials.memberDto.id,
          cardholderNumber: this.memberInfoDetials.memberDto.cardholderNumber,
          memberNumber: this.memberInfoDetials.memberDto.memberNumber,
          isActive: this.memberInfoDetials.memberDto.isActive,
          isFromPdmi: this.memberInfoDetials.memberDto.isFromPdmi,
          clientId: this.memberInfoDetials.memberDto.clientId,
          adjusterId: this.memberInfoDetials.memberDto.adjusterId ,
          isUnsubscribed:this.memberInfoDetials.memberDto.isUnsubscribed        
        },
        memberDetailDto: {
          id: this.memberInfoDetials.memberDetailDto.id,
          memberId: this.memberInfoDetials.memberDetailDto.memberId,
          firstName: this.memberInfoDetials.memberDetailDto.firstName,
          lastName: this.memberInfoDetials.memberDetailDto.lastName,
          gender: this.memberInfo.gender,
          emailAddress: this.memberInfoDetials.memberDetailDto.emailAddress,
          phoneNumber:
            this.memberInfoDetials.memberDetailDto.phoneNumber == null
              ? ''
              : this.memberInfoDetials.memberDetailDto.phoneNumber.replace(
                  /-/g,
                  ''
                ),
          phoneNumberOf: this.memberInfoDetials.memberDetailDto.phoneNumberOf,
          dateOfBirth: dob,
          address1: this.memberInfoDetials.memberDetailDto.address1 ==null? '': this.memberInfoDetials.memberDetailDto.address1  ,
          address2: this.memberInfoDetials.memberDetailDto.address2 ==null? '':this.memberInfoDetials.memberDetailDto.address2,
          city: this.memberInfoDetials.memberDetailDto.city == null ? '': this.memberInfoDetials.memberDetailDto.city,
          state: this.memberInfoDetials.memberDetailDto.state == null ? '': this.memberInfoDetials.memberDetailDto.state,
          zipCode: this.memberInfoDetials.memberDetailDto.zipCode == null ? '': this.memberInfoDetials.memberDetailDto.zipCode,
          ssn: this.memberInfoDetials.memberDetailDto.ssn,
          employer: this.memberInfoDetials.memberDetailDto.employer,
          employerPhoneNumber:
            this.memberInfoDetials.memberDetailDto.employerPhoneNumber == null
              ? ''
              : this.memberInfoDetials.memberDetailDto.employerPhoneNumber.replace(
                  /-/g,
                  ''
                ),
          employerPhoneNumberOf: this.memberInfoDetials.memberDetailDto
            .employerPhoneNumberOf,
          newMsaaccount: this.memberInfoDetials.memberDetailDto.newMsaaccount,
          accountBalance: this.memberInfoDetials.memberDetailDto.accountBalance,
          accountBalanceDate: actDate,
          treatingPhysician: this.memberInfoDetials.memberDetailDto
            .treatingPhysician,
          groupNumber: this.memberInfoDetials.memberDetailDto.groupNumber,
          ffGroupNumber: this.memberInfoDetials.memberDetailDto.ffgroupNumber,
          startDate: sDate,
          endDate: eDate,
          isActive: this.memberInfoDetials.memberDetailDto.isActive,
          groups: this.memberInfoDetials.memberDetailDto.groups,
          rxGroup: this.memberInfoDetials.memberDetailDto.rxGroup,
          rxBin: this.memberInfoDetials.memberDetailDto.rxBin,
          pcn: this.memberInfoDetials.memberDetailDto.pcn,
          ffStartDate: ffsDate,
          ffEndDate: ffeDate,
          depositDateTime: dpDate,
          lowfunds: this.memberInfoDetials.memberDetailDto.lowfunds,
          claimNumber: this.memberInfoDetials.memberDetailDto.claimNumber,
          clientClaimNumber: this.memberInfoDetials.memberDetailDto.clientClaimNumber,
          dateOfInjury: doi
        }
      };
      if(this.isSubscribe==true){
        this.memberProfileService.updateSMSSubscribe(memberDetails.memberDto.id,memberDetails.memberDetailDto.phoneNumber).subscribe(a=>{})
      }
      this.memberProfileService.updateMemberDetails(memberDetails).subscribe(
        res => {
          this.message = 'Successfully Updated Profile!!';
          this.notifier.notify('success', this.message);
          this.getMemberProfileInfo();
          this.getPreferedMedicins();
          this.editable = false;
        },
        error => {
          this.error = error;

          this.notifier.notify('error', this.error.error);
        }
      );
    }
  }
  PopUpFillHistory(){
    $('#startDate3').val('');
    $('#endDate3').val('');
    this.endDate3=null;
    this.getClaimHistory();
    
  }

  // Save the user notes API
  saveNote() {
    if (this.description.length == 0) {
      this.isNNum = true;
      this.noteErr = 'Please enter Note!!';
    } else {
      var pattern = /^[!@#$%^&*()_+\-=\[\]{};':'\\|,.<>\/?]*$/;
      var pat2 = /^[0-9]*$/;
      if (pat2.test(this.description)) {
        this.isNNum = true;
        this.noteErr =
          'You entered only numbers. Please Enter AlphaNumeric value';
      } else {
        if (pattern.test(this.description)) {
          this.isNNum = true;
          this.noteErr =
            'You entered only special characters. Please Enter AlphaNumeric value';
        } else {
          this.isNNum = false;
          $('#AddNoteModal').modal('hide');
          const memberNoteCreation = JSON.stringify({
            description: this.description,
            adjusterId: this.adjusterId,
            memberId: this.memberId
          });
          this.memberProfileService
            .saveMemberNote(memberNoteCreation)
            .subscribe(
              () => {
                this.message = 'Adjuster note added successfully';
                this.notifier.notify('success', this.message);
                this.description = '';
                this.getMemberNotes();
                this.getMemberActivity();
                this.noteTitle = 'Add New';
              },
              () => {
                this.message = 'Failed To Add Note';
                this.notifier.notify('error', this.message);
                this.noteTitle = 'Add New';
              }
            );
        }
      }
    }
  }

  // Edit the user notes API
  editNote() {
    if (this.description.length == 0) {
      this.notifier.notify('error', 'Please Enter Note!!');
    } else {
      var pattern = /^[!@#$%^&*()_+\-=\[\]{};':'\\|,.<>\/?]*$/;
      var pat2 = /^[0-9]*$/;

      if (pat2.test(this.description)) {
        this.isNNum = true;
        this.noteErr =
          'You Entered only numbers. Please Enter AlphaNumeric value';
      } else {
        if (pattern.test(this.description)) {
          this.isNNum = true;
          this.noteErr =
            'You Entered only special characters. Please Enter AlphaNumeric value';
        } else {
          this.isNNum = false;
          const memberNote = JSON.stringify({
            id: this.memberNoteObject.id,
            description: this.description,
            adjusterId: this.adjusterId,
            adjusterName: this.adjusterName,
            memberId: this.memberId,
            adjusterActionTaken: this.memberNoteObject.adjusterActionTaken,
            actionItem: this.memberNoteObject.actionItem
          });
          this.memberProfileService.updateMemberNote(memberNote).subscribe(
            () => {
              this.message = 'Adjuster note updated successfully';
              this.notifier.notify('success', this.message);
              $('#AddNoteModal').modal('hide');
              this.getMemberActivity();
              this.getMemberNotes();
              this.noteTitle = 'Add New';
              this.resetNoteBox();
            },
            () => {
              this.message = 'Failed to Update Note!!';
              this.notifier.notify('error', this.message);
            }
          );
        }
      }
    }
  }

  // Update preferred date range API
  updatePreferrenceDateRange() {
    let startDate = this.datePipe.transform(this.startDate2, 'MM/dd/yyyy');
    let endDate = this.datePipe.transform(this.endDate2, 'MM/dd/yyyy');
    var name =
      this.memberInfoDetials.memberDetailDto.firstName +
      this.memberInfoDetials.memberDetailDto.lastName;
    if (this.removeCheckModel.includes('physician')) {
      this.physData(startDate, endDate);
    } else if (this.removeCheckModel.includes('drug')) {
      this.MedData(startDate, endDate);
    } else if (this.removeCheckModel.includes('Physicians')) {
      this.blkUpdateProw(startDate, endDate);
    } else if (this.removeCheckModel.includes('Medications')) {
      this.blkUpdateDrow(startDate, endDate);
    }

    //
  }

  physData(startDate, endDate) {
    var isAfter = moment(startDate).isSameOrAfter(endDate);
    if (isAfter) {
      this.notifier.notify('error', 'To Date Should be More than From Date!!');
    } else {
      const preferredPhysician = JSON.stringify({
        id: this.preferedPhysicians.id,
        adjusterId: this.adjusterId,
        memberId: this.memberId,
        memberName: name,
        physicianName: this.preferedPhysicians.physicianName,
        validityStartDate: startDate,
        validityEndDate: endDate
      });
      this.doctorSercice.updatePhysician(preferredPhysician).subscribe(
        () => {
          this.message =
            'Successfully Updated Preferred Doctor/Physician Range!!';
          this.notifier.notify('success', this.message);
          $('#myModal11123').modal('hide');
          this.getPreferedDoctors();
          this.getMemberActivity();
        },
        error => {
          this.message = error.error;
          this.notifier.notify('error', this.message);
        }
      );
    }
  }

  MedData(startDate, endDate) {
    var isAfter = moment(startDate).isSameOrAfter(endDate);
    if (isAfter) {
      this.notifier.notify('error', 'To Date Should be More than From Date!!');
    } else {
      const preferredDrugs = JSON.stringify({
        id: this.preferedDrugs.id,
        gpiNumber: this.preferedDrugs.gpiNumber,
        ndcNumber: this.preferedDrugs.ndcNumber,
        adjusterId: this.adjusterId,
        memberId: this.memberId,
        drugName: this.preferedDrugs.drugName,
        memberName: name,
        validityStartDate: startDate,
        validityEndDate: endDate,
        createdDate: this.preferedDrugs.createdDate,
        isBlocked: this.preferedDrugs.isChecked,
        isChecked: this.preferedDrugs.isChecked
      });
      this.medicinService.updateDrug(preferredDrugs).subscribe(
        () => {
          this.message =
            'Successfully Updated Preferred Medication/Drug Range!!';
          this.notifier.notify('success', this.message);
          $('#myModal11123').modal('hide');
          this.getPreferedMedicins();
          this.getMemberActivity();
        },
        error => {
          this.message = error.error;
          this.notifier.notify('error', this.message);
        }
      );
    }
  }
  UpdatePreferedDrugList(item) {
    if (this.preferedDrugs.isChecked) {
      this.preferedDrugs.isChecked = false;
    } else {
      this.preferedDrugs.isChecked = true;
    }

    let startDate = this.datePipe.transform(this.startDate2, 'MM/dd/yyyy');
    let endDate = this.datePipe.transform(this.endDate2, 'MM/dd/yyyy');
    const body = {
      id: this.preferedDrugs.id,
      gpiNumber: this.preferedDrugs.gpiNumber,
      ndcNumber: this.preferedDrugs.ndcNumber,
      adjusterId: this.adjusterId,
      memberId: this.memberId,
      drugName: this.preferedDrugs.drugName,
      memberName: name,
      validityStartDate: startDate,
      validityEndDate: endDate,
      createdDate: this.preferedDrugs.createdDate,
      isDisabled: this.preferedDrugs.isChecked,
      isChecked: this.preferedDrugs.isChecked
    };

    this.memberService.updateDrugList(body).subscribe(
      res => {
        this.notifier.notify('success', res);
        this.getPreferedMedicins();
      },
      error => {
        this.notifier.notify('error', error.error);
      }
    );
  }
  // Delete the user notes API
  removeNote() {
    this.memberProfileService
      .deleteMemberNote(this.memberNoteObject.id)
      .subscribe(
        () => {
          this.message = 'Adjuster note Deleted successfully';
          this.notifier.notify('success', this.message);
          this.getMemberNotes();
          this.getMemberActivity();
          // this.resetNoteBox();
          this.noteTitle = 'Add New';
        },
        error => {
          this.notifier.notify('error', error.error);
        }
      );
  }

  // Selection change trigger
  onChangeActivity(newSelection) {
    this.selectedActivity = newSelection;
    this.getMemberActivity();
  }

  // Reset the notes box
  resetNoteBox() {
    this.description = '';
    this.id = 0;
    // this.memberNoteObject.id = 0;
  }

  // Get notes details by row
  selectNoteDetials(note) {
    this.memberNoteObject = note;
    this.newNotes = false;
    this.description = this.memberNoteObject.description;
    this.removeCheckModel = 'note';
    this.noteTitle = 'Edit';
    this.isNNum = false;
  }

  // Get row details of physicians
  selectPhysicianRow(item) {
    this.blk = false;
    this.med = false;
    this.preferedPhysicians = item;
    this.removeCheckModel = 'physician';
    this.title = 'Doctor';
    this.preferedDateDto.startDate = item.validityStartDate;
    this.preferedDateDto.endDate = item.validityEndDate;
    // this.updateMyEndDate(this.startDate2)
  }

  // Get row details fo the drug
  selectDrugRow(item) {
    this.blk = false;
    this.med = true;
    this.ids = item.id;
    this.preferedDrugs = item;

    this.removeCheckModel = 'drug';
    this.title = 'Drug';
    var day = new Date();
    this.startDate2 = null;
    this.startDate2 = day;
    this.preferedDateDto.startDate = item.validityStartDate;
    this.preferedDateDto.endDate = item.validityEndDate;
    // this.updateMyEndDate(this.startDate2)
  }

  // get all row details
  selectReportRow(item) {
    this.claimRecordsList = item;
  }

  // Get claim log items and initialize it
  selectClaimHistoryLogItems(item) {
    this.claimHistoryLog = item;
    this.removeCheckModel = 'claimHistoryLogs';
  }

  // Date validtion
  dateValidation() {
    if (this.startDate1 == undefined && this.endDate1 == undefined) {
      this.notifier.notify(
        'error',
        'Please enter the From date in mm/dd/yyyy format or Please enter the to date in mm/dd/yyyy format'
      );
      return false;
    } else if (this.startDate1 == undefined) {
      this.notifier.notify(
        'error',
        'Please enter the From date in mm/dd/yyyy format'
      );
      return false;
    } else if (this.endDate1 == undefined) {
      this.notifier.notify(
        'error',
        'Please enter the From date in mm/dd/yyyy format'
      );
      return false;
    } else if (this.startDate1 == undefined || this.endDate1 == undefined) {
      this.notifier.notify(
        'error',
        'Please Select DatesPlease enter the Start date in mm/dd/yyyy format or Please enter the End date in mm/dd/yyyy format!!'
      );
      return false;
    } else if (this.endDate1 > this.today) {
      this.notifier.notify(
        'error',
        'Please select To date as same or Less than Today'
      );
      return false;
    } else if (this.startDate1 > this.today) {
      this.notifier.notify(
        'error',
        'Please select From date as same or Less than Today'
      );
      return false;
    } else if (this.endDate1 < this.startDate1) {
      this.notifier.notify(
        'error',
        'Please select To date is same or More than From Date'
      );
      return false;
    }
    return true;
  }

  // Date function to add end date
  updateMyEndDate(newDate) {
    const newD_ = Object.assign(newDate.value);
    this.endDate2 = new Date(newD_);
    this.endDate2.setDate(this.endDate2.getDate() + 90);
  }

  // Get preferef doctors API
  getPreferedDoctors() {
    this.doctorSercice.getPreferedPhysicians(this.memberId).subscribe(res => {
      if (res.length === 0) {
        this.doctorListResult = false;
        this.preferedPhysiciansData = res;
      } else {
        this.doctorListResult = true;
        this.preferedPhysiciansData = res;
        //  this.preferedPhysiciansData= res
        this.preferedPhysiciansData.map(obj => {
          if (moment(this.today).isAfter(obj.validityEndDate)) {
            obj.isblur = true;
          } else {
            obj.isblur = false;
          }
          return obj;
        });
      }
    });
  }

  // Get prefered drug API
  getPreferedMedicins() {
    this.medicinService.preferedDrugs(this.memberId).subscribe(res => {
      if (res.length === 0) {
        this.pre = true;
        this.drugListResult = false;
        this.preferedDrugsData = res;
      } else {
        this.drugListResult = true;
        this.preferedDrugsData = res;
        this.preferedDrugsData.map(obj => {
          if (moment(this.today).isAfter(obj.validityEndDate)) {
            obj.isblur = true;
          } else {
            obj.isblur = false;
          }
          return obj;
        });
      }
    });
  }

  // Get prfered doctor API
  getMemberPreferredDoctor() {
    this.memberProfileService
      .getMemberTreatedPhysican(this.memberId)
      .subscribe(res => {
        this.memberInfo.physicianId = res.id;
        this.memberInfo.physicinName =
          res.physicianName == null ? 'Not Assinged' : res.physicianName;
      });
  }

  // Get member activity API
  getMemberActivity() {
    this.memberProfileService
      .getActivityByMemberId(this.memberId, this.selectedActivity)
      .subscribe(res => {
        if (res.length == 0) {
          this.memberActivityResult = false;
          this.currentActivitysList = res;
        } else {
          this.memberActivityResult = true;
          this.currentActivitysList = res;
        }
      });
  }

  // Get all notes API
  getMemberNotes() {
    this.memberProfileService
      .getMemberNotesByMemberId(this.memberId)
      .subscribe(res => {
        if (res.length == 0) {
          this.memberNotesResult = false;
          this.memberNotesList = res;
        } else {
          this.memberNotesResult = true;
          this.memberNotesList = res;
        }
      });
  }

  // Get all member claims rejection API
  getMemberRejections(fromDate, toDate) {    
    this.isInit =false
    let startDate =
      fromDate == (null || undefined || '')
        ? null
        : moment.utc(moment(fromDate, 'MM/DD/YYYY').startOf('day')).format();;
    let endDate =
      toDate == (null || undefined || '')
        ? null
        : moment.utc(moment(toDate, 'MM/DD/YYYY').endOf('day')).format();;
    if (startDate != null || endDate != null) {
      if (this.dateValidation()) {
        this.memberProfileService
          .getRejectionClaimsByMemberId(this.memberId, startDate, endDate)
          .subscribe(res => {
            if (res.length == 0) {
              this.memberRejectionResult = false;
              this.memberRejectionList = res;
            } else {
              this.memberRejectionResult = true;
              this.memberRejectionList = res;
            }
          });
      }
    } else {
      this.memberProfileService
        .getRejectionClaimsByMemberId(this.memberId, startDate, endDate)
        .subscribe(res => {
          if (res.length == 0) {
            this.memberRejectionResult = false;
            this.memberRejectionList = res;
          } else {           
            this.memberRejectionResult = true;
            this.memberRejectionList = res;
            localStorage.setItem('claimId', res.id);
          }
        });
    }
  }

  // Get all member claim history API
  getMemberClaimHistory() {
    this.claimHistoryService
      .getSavedClaimHistoryByMemberId(this.memberId)
      .subscribe(res => {
        if (res.length == 0) {
          this.claimHistoryResult = false;
          this.claimHistoryList = res;
        } else {
          this.claimHistoryResult = true;
          this.claimHistoryList = res;
        }
      });
  }

   // Get claims by date API
   getClaimHistoryByDates1() { 
    this.filterData = [];
    this.claimHistoryService
      .getClaimHistoryByDates(
        this.memberId,
        this.datePipe.transform(this.startDate3, 'MM/dd/yyyy'),
        this.datePipe.transform(this.endDate3, 'MM/dd/yyyy')
      )
      .subscribe(
        res => {
          this.data = res;
          if (this.data.length === 0) {
            this.filterDataHistory = this.data;
            this.noDataHistory = true;
          } else {
            this.noDataHistory = false;
            this.filterDataHistory = this.data;
          }
        },
        error => {
          this.notifier.notify('error', this.error);
        }
      );
  }
  // Download the pdf API
  getGeneratedClaimsHistoryPdf() {
    this.claimHistoryService
      .getPDFGenerated(
        this.memberId,
        this.datePipe.transform(this.claimsdDto.startDate, 'MM/dd/yyyy'),
        this.datePipe.transform(this.claimsdDto.endDate, 'MM/dd/yyyy')
      )
      .subscribe(res => {
        saveAs(res, 'ClaimHistory.pdf');
      });
  }
  getClaimHistory() {
    this.claimHistoryService.getClaimHistory(this.memberId).subscribe(
      res => {
        this.data = res;
        if (this.data.length === 0) {
          this.filterDataHistory = this.data;
          this.noDataHistory = true;
        } else {
          this.filterDataHistory = this.data;
          this.noDataHistory = false;
        }
      },
      error => {
        this.notifier.notify('error', error);
      }
    );
  }
  // Get all claims API
  getClaimRecords() {
    this.claimHistoryService.getClaimHistory(this.memberId).subscribe(
      res => {
        this.claimRecordsList = [];
        if (res.length == 0) {
          this.claimRecordsResult = false;
          this.claimRecordsList = res;
        } else {
          this.claimRecordsResult = true;
          this.claimRecordsList = res;
        }
      },
      () => {}
    );
  }

  // Get claim by dates API
  getClaimHistoryByDates(item) {
    this.filterData = [];
    this.data = [];
    this.claimHistoryService
      .getClaimHistoryByDates(
        this.memberId,
        this.datePipe.transform(item.fromDate, 'MM/dd/yyyy'),
        this.datePipe.transform(item.toDate, 'MM/dd/yyyy')
      )
      .subscribe(res => {
        if (res.length == 0) {
          this.filterData = res;
          // this.noData = true;
        } else {
          // this.noData = false;
          this.filterData = res;
          this.data = res;
          var arr = [];
          for (var i = 0; i < this.data.length; i++) {
            let newArray = {
              'Date Filled': '',
              Drug: '',
              NDC: '',
              Quantity: '',
              Days: '',
              Price: ''
            };
            newArray['Date Filled'] = this.datePipe.transform(
              this.data[i]['item1']['transactionDate'],
              'MM/dd/yyyy'
            );
            newArray.Drug = this.data[i]['item2'];
            newArray.NDC = this.data[i]['item1']['ndc'];
            newArray.Quantity = this.data[i]['item1']['metricQuantity'];
            newArray.Days = this.data[i]['item1']['daysSupply'];
            newArray.Price = '$' + this.data[i]['item1']['amountPaid'];
            arr.push(newArray);
          }
          this.exportAsXLSX(arr);
        }
      });
  }

  handlePDFClick() {
    this.getGeneratedClaimsHistoryPdf();
  }
  handleExcelClick() {
    this.exportAsXLSXHistory();
  }

  // Select change function
  onChangeActivityHistory(newSelection) {
    this.selectedActivity = newSelection;
    if (this.selectedActivity === '1') {
      this.exportAsXLSXHistory();
    }
    if (this.selectedActivity === '2') {
      // this.exportAsPDF();
      this.getGeneratedClaimsHistoryPdf();
    }
    this.saveCliamsHistory();
  }
   // Save claim details API
   saveCliamsHistory() {
    const fileName =
      this.datePipe.transform(this.claimsdDto.startDate, 'MM/dd/yyyy') +
      '-' +
      this.datePipe.transform(this.claimsdDto.endDate, 'MM/dd/yyyy');
    this.saveClaimsHistoryDto.memberId = this.memberId;
    this.saveClaimsHistoryDto.fromDate = this.claimsdDto.startDate;
    this.saveClaimsHistoryDto.toDate = this.claimsdDto.endDate;
    this.saveClaimsHistoryDto.filename = fileName;
    this.saveClaimsHistoryDto.format = '.exel';
    this.saveClaimsHistoryDto.adjusterId = this.adjusterId;
    this.claimHistoryService
      .saveCliamsHistory(this.saveClaimsHistoryDto)
      .subscribe(
        () => {},
        error => {
          this.notifier.notify('error',error);
        }
      );
  }

  // Download the excel sheet function
  exportAsXLSXHistory() {
    this.commanUtils.exportAsExcel('exportable1', 'ClaimsHistory');
  }

  // Print the claim data
  print(): void {
    this.commanUtils.printFunction('exportable1', 'ClaimHistory');
  }
  dates2History(e) {
    //  e.startDate = moment(e.startDate, 'YYYY-MM-DDTHH:mm:ss.SSS').format('YYYY-MM-DD');
    //  e.endDate = moment(e.endDate, 'YYYY-MM-DDTHH:mm:ss.SSS').format('YYYY-MM-DD');
  }

  // Date validation
  dateChange() {
    this.toDate = true;
  }
    // Get all member details API
    getMemberDetail() {
      this.claimHistoryService
        .getMemberDetails(this.memberId)
        .subscribe(array => {
          array.forEach(res => {
            this.memberDetailInfo.memberId = res.item2.memberId;
            this.memberDetailInfo.name =
              res.item2.firstName + ' ' + res.item2.lastName;
            this.memberDetailInfo.cardholderNumber =
              res.item1.cardholderNumber == null
                ? ''
                : res.item1.cardholderNumber;
            this.memberDetailInfo.dateOfBirth =
              res.item2.dateOfBirth == null
                ? ''
                : this.datePipe.transform(res.item2.dateOfBirth, 'MM/dd/yyyy');
            //  this.memberDetailInfo.gender = res.item2.gender === '1' ? 'Male' : 'Female';
            if (res.item2.gender.includes('0')) {
              this.memberDetailInfo.gender = 'Trans';
            } else if (res.item2.gender.includes(1)) {
              this.memberDetailInfo.gender = 'Male';
            } else {
              this.memberDetailInfo.gender = 'Female';
            }
            this.memberDetailInfo.address1 =
              res.item2.address1 == null ? '' : res.item2.address1;
            this.memberDetailInfo.address2 = res.item2.address2;
            this.memberDetailInfo.phoneNumber = res.item2.phoneNumber;
            this.memberDetailInfo.groupNumber =
              res.item2.groupNumber == null ? '' : res.item2.groupNumber;
            this.memberDetailInfo.emailAddress =
              res.item2.emailAddress == null ? '' : res.item2.emailAddress;
            this.memberDetailInfo.startDate =
              res.item2.startDate == null
                ? ''
                : this.datePipe.transform(res.item2.startDate, 'MM/dd/yyyy');
            this.memberDetailInfo.endDate =
              res.item2.endDate == null
                ? ''
                : this.datePipe.transform(res.item2.endDate, 'MM/dd/yyyy');
            this.memberDetailInfo.ffStartDate =
              res.item2.ffStartDate == null
                ? '----'
                : this.datePipe.transform(res.item2.ffStartDate, 'MM/dd/yyyy');
            this.memberDetailInfo.ffEndDate =
              res.item2.ffEndDate == null
                ? '----'
                : this.datePipe.transform(res.item2.ffEndDate, 'MM/dd/yyyy');
            this.memberDetailInfo.phnType = res.item2.phoneNumberOf;
            this.memberDetailInfo.city = res.item2.city;
            this.memberDetailInfo.state = res.item2.state;
            this.memberDetailInfo.zipCode = res.item2.zipCode;
            this.memberDetailInfo.groups = res.item2.groups;
            this.memberDetailInfo.ffGroupNumber = res.item2.ffGroupNumber;
  
            if (this.memberDetailInfo.phnType === '1') {
              this.memberDetailInfo.phnType = '(H)';
            } else if (this.memberDetailInfo.phnType === '2') {
              this.memberDetailInfo.phnType = '(W)';
            } else if (this.memberDetailInfo.phnType === '3') {
              this.memberDetailInfo.phnType = '(M)';
            } else {
            }
          });
          this.memberDetailInfo.groups.forEach(item => {
            if (item.ffId == 1) {
              this.memberDetailInfo.groupNumber = item.groupNumber;
            } else {
              this.memberDetailInfo.ffGroupNumber = item.groupNumber;
            }
          });
          if (this.memberDetailInfo.phoneNumber == '') {
            this.memberDetailInfo.phnType = '';
          } else {
            this.memberDetailInfo.phoneNumber = this.commanUtils.convertToInternational(
              this.memberDetailInfo.phoneNumber
            );
          }
        });
    }

   // Get list of adjusters
   getAllAdjusterById() {
    this.memService
      .getAllAdjusterByClientId(this.rID, this.adjusterId)
      .subscribe(res => {
        this.adjData = res;
        this.adjId = this.adjData;
      });
  }
  getAdjusterById() { 
    
    this.memService
      .getAllAdjusterByClientId(this.rID, this.adjusterId)
      .subscribe(res => {
        this.adjsData = res;
        this.adjsId = this.adjsData;
      });
  }
  // Get all Member profile detials API
  getMemberProfileInfo() {
    this.memberProfileService.getByMemberId(this.memberId).subscribe(res => {
      this.memberInfoDetials.memberDto = res[0]['item1'];
      this.memberInfoDetials.memberDetailDto = res[0]['item2'];
      if(this.memberInfoDetials.memberDto.clientId>0){
        this.rID = this.memberInfoDetials.memberDto.clientId;
      }
      this.getAdjusterById();
      if (!this.memberInfoDetials.memberDto.isAssignedNewAdjuster) {
        if(this.memberInfoDetials.memberDto.clientId>0){
          this.rID = this.memberInfoDetials.memberDto.clientId;
          this.getAllAdjusterById();
        }else{
          this.memService
          .getClientByClaim(this.memberInfoDetials.memberDto.ffClientId)
          .subscribe(res1 => {
            this.rID = res1;
            this.getAllAdjusterById();
          });
        }        
      }

      this.memberInfo.memberId = this.memberInfoDetials.memberDetailDto.memberId;
      this.memberInfo.name =
        this.memberInfoDetials.memberDetailDto.firstName +
        ' ' +
        this.memberInfoDetials.memberDetailDto.lastName;
      this.memberInfoDetials.memberDetailDto.dateOfBirth =
        this.memberInfoDetials.memberDetailDto.dateOfBirth == null
          ? ''
          : moment(
              this.memberInfoDetials.memberDetailDto.dateOfBirth,
              'YYYY-MM-DD hh:mm:ss A Z'
            ).format('MM/DD/YYYY');
      // this.memberInfoDetials.memberDetailDto.address1 = res.item2.address1 == null ? res.item2.address2 : res.item2.address1;

      this.memberInfoDetials.memberDetailDto.startDate =
        this.memberInfoDetials.memberDetailDto.startDate == null
          ? ''
          : moment(
              this.memberInfoDetials.memberDetailDto.startDate,
              'YYYY-MM-DD hh:mm:ss A Z'
            ).format('MM/DD/YYYY');
      // this.memberInfoDetials.memberDetailDto.dateOfInjury = this.memberInfoDetials.memberDetailDto.dateOfInjury == null ? '' : moment(this.memberInfoDetials.memberDetailDto.dateOfInjury, 'YYYY-MM-DD hh:mm:ss A Z').format('MM/DD/YYYY');

      this.memberInfoDetials.memberDetailDto.endDate =
        this.memberInfoDetials.memberDetailDto.endDate == null
          ? ''
          : moment(
              this.memberInfoDetials.memberDetailDto.endDate,
              'YYYY-MM-DD hh:mm:ss A Z'
            ).format('MM/DD/YYYY');
      // this.memberInfoDetials.memberDetailDto.ssn = this.memberInfoDetials.memberDetailDto.ssn;
      this.memberInfo.ssn = this.memberInfoDetials.memberDetailDto.ssn.slice(
        -4
      );
      // this.cardHolder = this.memberInfoDetials.memberDto.cardholderNumber;
      this.memberInfoDetials.memberDetailDto.phoneNumber = this.commanUtils.convertToInternational(
        this.memberInfoDetials.memberDetailDto.phoneNumber
      );
      this.memberInfoDetials.memberDetailDto.employerPhoneNumber = this.commanUtils.convertToInternational(
        this.memberInfoDetials.memberDetailDto.employerPhoneNumber
      );
      this.memberInfoDetials.memberDetailDto.groups = res[0].item2.groups;
      this.memberInfoDetials.memberDetailDto.ffgroupNumber =
        res[0].item2.ffGroupNumber;
      this.memberInfoDetials.memberDetailDto.rxGroup = res[0].item2.rxGroup;
      this.memberInfoDetials.memberDetailDto.rxBin = res[0].item2.rxBin;
      this.memberInfoDetials.memberDetailDto.pcn = res[0].item2.pcn;
      this.memberInfoDetials.memberDetailDto.claimNumber =
        res[0].item2.claimNumber;
        this.memberInfoDetials.memberDetailDto.clientClaimNumber =
        res[0].item2.clientClaimNumber;
      this.memberInfoDetials.memberDetailDto.dateOfInjury =
        res[0].item2.dateOfInjury;
      this.memberInfoDetials.memberDetailDto.phntype =
        res[0].item2.phoneNumberOf;
      this.memberInfoDetials.memberDetailDto.ePhntype =
        res[0].item2.employerPhoneNumberOf;
      this.memberInfoDetials.memberDetailDto.groups.forEach(element => {
        if (element.ffId == 1) {
          this.memberInfoDetials.memberDetailDto.groupNumber =
            element.groupNumber;
        } else {
          this.memberInfoDetials.memberDetailDto.ffGroupNumber =
            element.groupNumber;
        }
      });

      this.memberInfoDetials.memberDetailDto.ffStartDate =
        res[0].item2.ffStartDate;

      if (
        this.memberInfoDetials.memberDetailDto.ffStartDate === '' ||
        this.memberInfoDetials.memberDetailDto.ffStartDate === 'Invalid date' ||
        this.memberInfoDetials.memberDetailDto.ffStartDate == null
      ) {
        this.memberInfoDetials.memberDetailDto.ffStartDate = '';
      } else {
        this.memberInfoDetials.memberDetailDto.ffStartDate = moment(
          this.memberInfoDetials.memberDetailDto.ffStartDate,
          'YYYY-MM-DD hh:mm:ss A Z'
        ).format('MM/DD/YYYY');
      }

      if (
        this.memberInfoDetials.memberDetailDto.depositDateTime == '' ||
        this.memberInfoDetials.memberDetailDto.depositDateTime ===
          'Invalid date' ||
        this.memberInfoDetials.memberDetailDto.depositDateTime == null
      ) {
        this.memberInfoDetials.memberDetailDto.depositDateTime = '';
      } else {
        this.memberInfoDetials.memberDetailDto.depositDateTime = moment(
          this.memberInfoDetials.memberDetailDto.depositDateTime,
          'YYYY-MM-DD hh:mm:ss A Z'
        ).format('MM/DD/YYYY');
      }
      if (
        this.memberInfoDetials.memberDetailDto.dateOfInjury == '' ||
        this.memberInfoDetials.memberDetailDto.dateOfInjury ===
          'Invalid date' ||
        this.memberInfoDetials.memberDetailDto.dateOfInjury == null
      ) {
        this.memberInfoDetials.memberDetailDto.dateOfInjury = '';
      } else {
        this.memberInfoDetials.memberDetailDto.dateOfInjury = moment(
          this.memberInfoDetials.memberDetailDto.dateOfInjury,
          'YYYY-MM-DD hh:mm:ss A Z'
        ).format('MM/DD/YYYY');
      }

      if (
        this.memberInfoDetials.memberDetailDto.accountBalanceDate == '' ||
        this.memberInfoDetials.memberDetailDto.accountBalanceDate ===
          'Invalid date' ||
        this.memberInfoDetials.memberDetailDto.accountBalanceDate == null
      ) {
        this.memberInfoDetials.memberDetailDto.accountBalanceDate = '';
      } else {
        this.memberInfoDetials.memberDetailDto.accountBalanceDate = moment(
          this.memberInfoDetials.memberDetailDto.accountBalanceDate,
          'YYYY-MM-DD hh:mm:ss A Z'
        ).format('MM/DD/YYYY');
      }

      this.memberInfoDetials.memberDetailDto.ffEndDate = res[0].item2.ffEndDate;

      if (
        this.memberInfoDetials.memberDetailDto.ffEndDate == '' ||
        this.memberInfoDetials.memberDetailDto.ffEndDate === 'Invalid date' ||
        this.memberInfoDetials.memberDetailDto.ffEndDate == null
      ) {
        this.memberInfoDetials.memberDetailDto.ffEndDate = '';
      } else {
        this.memberInfoDetials.memberDetailDto.ffEndDate = moment(
          this.memberInfoDetials.memberDetailDto.ffEndDate,
          'YYYY-MM-DD hh:mm:ss A Z'
        ).format('MM/DD/YYYY');
      }
      this.num = '01';
      if (
        this.memberInfoDetials.memberDetailDto.ffGroupNumber === '' ||
        this.memberInfoDetials.memberDetailDto.ffGroupNumber === undefined ||
        this.memberInfoDetials.memberDetailDto.ffGroupNumber === null
      ) {
        this.FFNum = false;
      } else {
        this.FFNum = true;
      }
      if (
        this.memberInfoDetials.memberDetailDto.groupNumber === '' ||
        this.memberInfoDetials.memberDetailDto.groupNumber === undefined ||
        this.memberInfoDetials.memberDetailDto.groupNumber == null
      ) {
        this.OnNum = false;
      } else {
        this.OnNum = true;
      }
      if (res[0].item2.gender == 0) {
        this.memberInfoDetials.memberDetailDto.gender = 'Trans';
      } else if (res[0].item2.gender == 1) {
        this.memberInfoDetials.memberDetailDto.gender = 'Male';
      } else {
        this.memberInfoDetials.memberDetailDto.gender = 'Female';
      }

      if (this.memberInfoDetials.memberDetailDto.phntype === '1') {
        this.memberInfoDetials.memberDetailDto.phntype = '(H)';
      } else if (this.memberInfoDetials.memberDetailDto.phntype === '2') {
        this.memberInfoDetials.memberDetailDto.phntype = '(W)';
      } else if (this.memberInfoDetials.memberDetailDto.phntype === '3') {
        this.memberInfoDetials.memberDetailDto.phntype = '(M)';
      } else {
      }
      if (this.memberInfoDetials.memberDetailDto.ePhntype === '1') {
        this.memberInfoDetials.memberDetailDto.ePhntype = '(H)';
      } else if (this.memberInfoDetials.memberDetailDto.ePhntype === '2') {
        this.memberInfoDetials.memberDetailDto.ePhntype = '(W)';
      } else if (this.memberInfoDetials.memberDetailDto.ePhntype === '3') {
        this.memberInfoDetials.memberDetailDto.ePhntype = '(M)';
      } else {
      }
      if (this.memberInfoDetials.memberDetailDto.employerPhoneNumber === '') {
        this.memberInfoDetials.memberDetailDto.ePhntype = '';
      } else {
        this.memberInfoDetials.memberDetailDto.employerPhoneNumber;
      }

      if (this.memberInfoDetials.memberDetailDto.phoneNumber === '') {
        this.memberInfoDetials.memberDetailDto.phntype = '';
      } else {
        this.memberInfoDetials.memberDetailDto.phoneNumber;
      }
    });
  }

  // Export to excel function
  exportAsXLSX(a) {
    // this.commanUtils.exportAsExcel(a, 'ClaimsHistory');
    this.excelService.exportAsExcelFile(a, 'ClaimsHistory');
  }

  // Request prior auth function
  priorAuth() {
    // this.med = true
    this.memberService.setDrugName(this.preferedDrugs.ndcNumber);
    this.getClaimByNDCNumber(this.preferedDrugs.ndcNumber);
    this.updatePreferrenceDateRange();
  }

  // Get claim by NDC number API
  getClaimByNDCNumber(drugName) {
    this.memberProfileService
      .getClaimByNDCNumber(this.memberId, drugName)
      .subscribe(res => {
        if (res > 0) {
          localStorage.setItem('claimId', res);
          this.router.navigate(['adjuster/PendingAuthorizations']);
        } else {
          this.notifier.notify('error', 'No Claim is associated to this drug');
        }
        $('#myModal11123').modal('hide');
      });
  }
  getAdjustId(id) {
    this.adjId = id;
  }
  // Assign claims to og FFgroup
  postClaimsToAdjusters() {
    this.memService.postClaimsToAdjusters(this.rID, this.adjId, this.memberId, this.memberInfoDetials.memberDto.ffClientId)
      .subscribe(res => {
        if (res) {
          this.notifier.notify(
            'success',
            'Successfully transferred the claim.'
          );
          //this.router.navigate(['adjuster/home']);
          setTimeout(() => {
            this.router.navigate(['adjuster/home']);
          }, 2000);
        } else {
          this.notifier.notify('error', 'Could not transfer the claim.');
        }
      });
  }

  // pdf
  getGeneratedPdf(item) {
    this.claimHistoryService
      .getPDFGenerated(
        item.memberId,
        this.datePipe.transform(item.fromDate, 'MM/dd/yyyy'),
        this.datePipe.transform(item.toDate, 'MM/dd/yyyy')
      )
      .subscribe(res => {
        saveAs(res, 'ClaimHistory.pdf');
      });
  } 
  getPhysician(physicianNumber){   
    this.physicianInfo = {}; 
    this.physicianData.npiNumber = physicianNumber;
    this.doctorSercice.getPhysicianDetails(this.memberId, this.physicianData).subscribe(res => {    
      this.physicianInfo ={};
      if (res.length === 0) {
        this.physicianInfo = {};
      } else {
        this.physicianInfo = res[0];
      }
    },
      error => {
        this.physicianInfo = {};
      });
  }
  // Redirect to pending auth depending on claim
  redirectToPendingClaimsPage(rejectedClaim) {
    // this.memberService.setDrugName(rejectedClaim.drugNDCNumber)
    localStorage.setItem('claimId', rejectedClaim.id);
    // this.getClaimByNDCNumber(rejectedClaim.drugNDCNumber)
    this.router.navigate(['/adjuster/PendingAuthorizations']);
  }

  // Reset  modal to default
  resetModal() {
    this.description = '';
    this.newNotes = true;
    this.noteTitle = 'Add';
    this.isNNum = false;
  }

  // get detail on row click
  rowClick(note) {
    this.removeCheckModel = 'note';
    this.memberNoteObject.id = note.id;
  }
 

  // Set doctor details to default
  setDoctorEdit() {
    this.startDate2 = this.preferedDateDto.startDate;
    this.endDate2 = this.preferedDateDto.endDate;
  }
  // Set details to default
  setBlked() {
    this.startDate2 = this.preferedDateDto.startDate;
    this.endDate2 = this.preferedDateDto.endDate;
  }
  // Set details to default
  setpBlked() {
    this.startDate2 = this.preferedDateDto.startDate;
    this.endDate2 = this.preferedDateDto.endDate;
  }

  // Set details to default
  setBlkdEdit(blk) {
    this.startDate2 = this.preferedDateDto.startDate;
    this.endDate2 = this.preferedDateDto.endDate;
  }

  // Validation starts
  validate(item) {
    if (item.firstName === '') {
      this.isFname = true;
      this.fnameErr = 'First Name is requried';
    } else {
      this.isFname = this.commanUtils.aplhanumeric(item.firstName);
      if (this.isFname) this.fnameErr = 'Please Enter Alphanumeric value';
    }
    if (item.lastName === '') {
      this.isLname = true;
      this.lnameErr = 'Last Name is requried';
    } else {
      this.isLname = this.commanUtils.aplhanumeric(item.lastName);
      if (this.isLname) this.lnameErr = 'Please Enter Alphanumeric value';
    }

    if (item.employer === '') {
      this.isEname = false;
    } else {
      this.isEname = this.commanUtils.aplhanumeric(item.employer);
      if (this.isEname) this.EnameErr = 'Please Enter Alphanumeric value';
    }

    if (item.phoneNumber === '') {
      this.phneErr = false;
    } else {
      if (item.phoneNumber.length < 12) {
        this.phneErr = true;
        this.phnMsg = 'Phone Number must be at least 10 characters';
      } else {
        this.phneErr = this.commanUtils.phoneNumberFormatValidation(
          item.phoneNumber
        );
        if (this.phneErr)
          this.phnMsg =
            'Phone number must be numeric and in this format xxx-xxx-xxxx';
      }
    }

    if (item.employerPhoneNumber === '') {
      this.phneErr1 = false;
    } else {
      if (item.employerPhoneNumber.length < 12) {
        this.phneErr1 = true;
        this.phnMsg1 = 'Employee Phone Number must be at least 10 characters';
      } else {
        this.phneErr1 = this.commanUtils.phoneNumberFormatValidation(
          item.employerPhoneNumber
        );
        if (this.phneErr1)
          this.phnMsg1 =
            'Employee Phone number must be numeric and in this format xxx-xxx-xxxx';
      }
    }

    const reg = /^\w+([-+.']\w+)*@\w+([-. ]\w+)*\.\w+([-. ]\w+)*$/;
    if (item.emailAddress === '') {
      this.emailErrz = false;
    } else {
      if (reg.test(item.emailAddress)) {
        this.emailErrz = false;
      } else {
        this.emailErrz = true;
        this.msg =
          'Please enter a vaild email in the following format: abc@email.com';
      }
    }
  }

  phoneChange2(event) {
    this.memberInfoDetials.memberDetailDto.phoneNumber = this.commanUtils.onKeyChangeNumberFormat(
      event,
      this.memberInfoDetials.memberDetailDto.phoneNumber,
      3,
      7
    );
  }

  employerphoneChange2(event) {
    this.memberInfoDetials.memberDetailDto.employerPhoneNumber = this.commanUtils.onKeyChangeNumberFormat(
      event,
      this.memberInfoDetials.memberDetailDto.employerPhoneNumber,
      3,
      7
    );
  }

  dateOFBirth(item) {
    if (moment(item.dateOfBirth, 'MM-DD-YYYY', true).isValid()) {
      if (item.dateOfBirth > this.maxDate1 || item.dateOfBirth < this.minDate) {
        this.dateErr = true;
        this.dateMsg =
          'Please enter valid date between current date and 01/01/1900';
      } else {
        this.dateErr = false;
      }
    } else {
      this.dateErr = true;
      this.dateMsg = 'Please enter date in the proper format MM/DD/YYYY';
    }
  }

  dateOfInjury(item) {
    if (moment(item, 'MM-DD-YYYY', true).isValid()) {
      if (item > this.maxDate1) {
        this.dateErr1 = true;
        this.dateMsg1 =
          'Please enter valid date between current date and 01/01/1900';
      } else {
        this.dateErr1 = false;
        // }
      }
    } else {
      this.dateErr1 = true;
      this.dateMsg1 = 'Please enter date in the proper format MM/DD/YYYY';
    }
  }

  // Date validation starts
  dates(e) {
    var a = moment(e.startDate, 'YYYY-MM-DD hh:mm:ss A Z').format('YYYY-MM-DD');
    var b = moment(e.endDate, 'YYYY-MM-DD hh:mm:ss A Z').format('YYYY-MM-DD');

    if (e.endDate == '') {
    } else {
      if (b > a) {
        this.expErr = false;
      } else {
        this.expErr = true;
      }
    }
  }
  datesFF(e) {
    var a = moment(e.ffStartDate, 'YYYY-MM-DD hh:mm:ss A Z').format(
      'YYYY-MM-DD'
    );
    var b = moment(e.ffEndDate, 'YYYY-MM-DD hh:mm:ss A Z').format('YYYY-MM-DD');
    if (e.ffEndDate == '') {
    } else {
      if (b > a) {
        this.expErrFF = false;
      } else {
        this.expErrFF = true;
      }
    }
  }
  dates2(e) {
    var a = moment(e.startDate, 'YYYY-MM-DD hh:mm:ss A Z').format('YYYY-MM-DD');
    var b = moment(e.endDate, 'YYYY-MM-DD hh:mm:ss A Z').format('YYYY-MM-DD');

    if (e.endDate == '') {
    } else {
      if (b > a) {
        this.expErr = false;
      } else {
        this.expErr = true;
      }
    }
  }
  dateFF(e) {
    var a = moment(e.ffStartDate, 'YYYY-MM-DD hh:mm:ss A Z').format(
      'YYYY-MM-DD'
    );
    var b = moment(e.ffEndDate, 'YYYY-MM-DD hh:mm:ss A Z').format('YYYY-MM-DD');
    if (e.ffEndDate == '') {
    } else {
      if (b > a) {
        this.expErrFF = false;
      } else {
        this.expErrFF = true;
      }
    }
  }

  // Date validation ends

  // validation ends

  clickedRow(n) {
    this.blkNum = n;
  }
}

// Member details DTO
export class MemberDetailInfo {
  memberId: number;
  cardholderNumber: string = '';
  name: string = '';
  firstName: string = '';
  lastName: string = '';
  gender: string = '';
  emailAddress: string = '';
  phoneNumber: string = '';
  dateOfBirth: string = '';
  groupNumber: string = '';
  startDate: string = '';
  endDate: string = '';
  address1: string = '';
  ssn: string = '';
  employerName: string = '';
  employerPhoneNumber: string = '';
  physicinName: string = '';
  physicianId: number;
  address2: any;
  ffStartDate: string;
  ffEndDate: string;
  phnType: any;
  city: any;
  state: any;
  zipCode: any;
  groups: any;
  ffGroupNumber: any;
}

// Member DTO
export class MemberDetails {
  memberDto: MemberDto = new MemberDto();
  memberDetailDto: MemberDetailsDto = new MemberDetailsDto();
}

// Member DTO
export class MemberDto {
  id: number;
  cardholderNumber: number;
  memberNumber: number;
  isActive: boolean;
  isFromPdmi: boolean;
  clientId: number;
  adjusterId: string;
  enableLowFundFeature: boolean;
  isAssignedNewAdjuster: boolean;
  ffClientId: any;
  isUnsubscribed: boolean=false;
}

// Deatils DTO
export class MemberDetailsDto {
  claimNumber: string = '';
  clientClaimNumber:string = '';
  dateOfInjury: string = '';
  depositDateTime: string = '';
  lowfunds: boolean;
  ffGroupNumber: string = '';
  ffgroupNumber: string;
  groups: Groups[];
  id: number;
  memberId: number;
  firstName: string;
  lastName: string;
  gender: string;
  emailAddress: string;
  phoneNumber: string;
  phoneNumberOf: string;
  dateOfBirth: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  ssn: string;
  employer: string;
  employerPhoneNumber: string;
  employerPhoneNumberOf: string;
  newMsaaccount: boolean;
  accountBalance: number;
  accountBalanceDate: string;
  treatingPhysician: string;
  groupNumber: string;
  startDate: string;
  endDate: string;
  isActive: boolean;
  phntype: string;
  rxGroup: string;
  rxBin: string;
  pcn: string;
  ffStartDate;
  ffEndDate;
  ePhntype: any;
}


// Rejection DTO
export class Rejections {
  startDate: Date;
  endDate: Date;
}

// Member notes DTO
export class MemberNotesObject {
  id: number = 0;
  description: string = '';
  adjusterId: string = '';
  adjusterName: string = '';
  memberId: number;
  memberName: string = '';
  adjusterActionTaken: number;
  actionItem: string = '';
}

// PrefferedDate DTO
export class PreferedDateDto {
  startDate: Date;
  endDate: Date;
}
// Claims DTO
export class ClaimsdDto {
  startDate: string;
  endDate: string;
}
// Save Claim DTO
export class SaveClaimsHistoryDto {
  fromDate: string;
  toDate: string;
  memberId: number;
  filename: string;
  format: string;
  adjusterId: string;
}