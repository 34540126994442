<!-- HEADER SECTION SATRT -->
<header class="d-flex justify-content-between align-items-center"> 
    <a class="logo" *ngIf="role == '2'" [routerLinkActive]="['active']" [routerLink]="['/client/home']"><img src="{{imgUrl}}"></a>
    <a class="logo" *ngIf="role == '1'" [routerLinkActive]="['active']" [routerLink]="['/client/home']"><img src="{{imgUrl}}"></a>
    <a class="logo" *ngIf="['3','4','5','6'].includes(role)" [routerLinkActive]="['active']" [routerLink]="['/adjuster/home']"><img src="{{imgUrl}}"></a>
    <div class="company-info">
      <p class="company-name float-left"><b>Company Name: </b><span>{{company}}</span></p>
      <!-- <button class="btn btn-sm btn-primary float-right ml-5">Show PIN</button> -->
    </div>
    <div class="profile-dropdown">
      <div class="dropdown">
        <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <span class="profile-img"><img src="https://picsum.photos/seed/picsum/200/200" alt=""></span>
          Hello! {{name}} 
        </button>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" href="#" data-toggle="modal" (click)="showPin()"  data-target="#showPinModal">
              <svg xmlns="http://www.w3.org/2000/svg" width="13.312" height="13.312" viewBox="0 0 13.312 13.312"><defs><style></style></defs><g transform="translate(0.004 0.004)"><g transform="translate(-0.004 -0.004)"><path class="a" d="M7.942,5.863A4.158,4.158,0,0,0,4.095,0,4.167,4.167,0,1,0,5.863,7.963L7.069,9.149h1.04v1.04h1.04v1.04h1.04v1.04l.915.915a.411.411,0,0,0,.291.125h1.5a.417.417,0,0,0,.416-.416V11.4a.411.411,0,0,0-.125-.291ZM3.221,4.157a.936.936,0,1,1,.936-.936A.932.932,0,0,1,3.221,4.157Z" transform="translate(0.004 0.004)"/></g></g></svg>
            Show Pin</a>
            <a class="dropdown-item" [routerLinkActive]="['active']" [routerLink]="['/changepassword']">
              <svg xmlns="http://www.w3.org/2000/svg" width="13.312" height="13.312" viewBox="0 0 13.312 13.312"><defs><style></style></defs><g transform="translate(0.004 0.004)"><g transform="translate(-0.004 -0.004)"><path class="a" d="M7.942,5.863A4.158,4.158,0,0,0,4.095,0,4.167,4.167,0,1,0,5.863,7.963L7.069,9.149h1.04v1.04h1.04v1.04h1.04v1.04l.915.915a.411.411,0,0,0,.291.125h1.5a.417.417,0,0,0,.416-.416V11.4a.411.411,0,0,0-.125-.291ZM3.221,4.157a.936.936,0,1,1,.936-.936A.932.932,0,0,1,3.221,4.157Z" transform="translate(0.004 0.004)"/></g></g></svg>
              Change Password</a>
            <a class="dropdown-item" (click)="logout()">
                <svg xmlns="http://www.w3.org/2000/svg" width="14.119" height="11.472" viewBox="0 0 14.119 11.472"><defs><style></style></defs><g transform="translate(0 -6)"><g transform="translate(0 6)"><path class="a" d="M8.6,17.472,7.258,16.148l3.552-3.53H0V10.853H10.81L7.28,7.1,8.6,6l5.515,5.736Z" transform="translate(0 -6)"/></g></g></svg>
                Sign Out</a>
        </div>
      </div>
    </div> 
    <div class="hamburger-menu" (click)="CollapseExpandMobile()"><span></span><span></span><span></span></div>
</header>
<!-- HEADER SECTION END -->    
<!-- SIDEBAR START -->
<aside class="aside" id ="mobileMenu" >
    <div id="sidebar-wrapper">
      <div class="list-group list-group-flush">
        <a *ngIf="role == '2'" [routerLinkActive]="['active']" [routerLink]="['/client/home']" class="list-group-item">
          <span class="icon icon-16 home"></span>Home
        </a>
        <a *ngIf="role == '1'" [routerLinkActive]="['active']" [routerLink]="['/admin/home']" class="list-group-item" (click)="changeLogo()">
          <span class="icon icon-16 home"></span>Home
        </a>
        
        <a *ngIf="role == '1' && !adminPage1 && !adminPage" [routerLinkActive]="['active']" [routerLink]="['/admin/errorMsg']" class="list-group-item list-group-item-action"> <span
          class="icon icon-16 errormsg"></span>Error Message
        </a>
        <a *ngIf="role == '1' && !adminPage1 && !adminPage" [routerLinkActive]="['active']" [routerLink]="['/admin/transaction']" class="list-group-item list-group-item-action"> <span
          class="icon icon-16 portaladmin"></span>Admin Dashboard
        </a>
        <a *ngIf="role == '1' && !adminPage1 && !adminPage" [routerLinkActive]="['active']" [routerLink]="['/admin/rejectioncodes']" class="list-group-item list-group-item-action"> <span
          class="icon icon-16 rejection"></span>Rejection Codes
        </a>
        <a *ngIf="role == '1' && !adminPage && !adminPage1" [routerLinkActive]="['active']" [routerLink]="['/admin/members']" class="list-group-item list-group-item-action"><span class="icon icon-16 member"></span>Members</a>
        <a *ngIf="role == '1' && adminPage" [routerLinkActive]="['active']" [routerLink]="['/client/home']" class="list-group-item" (click)="changeLogo()">
          <span class="icon icon-16 home"></span>Client
        </a>
        <a *ngIf="role == '1' && adminPage1" [routerLinkActive]="['active']" [routerLink]="['/client/home']" class="list-group-item" (click)="changeLogo()">
          <span class="icon icon-16 home"></span>Client
        </a>

        <a *ngIf="role == '1' && adminPage" [routerLinkActive]="['active']" [routerLink]="['/adjuster/home']" class="list-group-item" (click)="changeLogo()">
          <span class="icon icon-16 home"></span>Adjuster
        </a>
        <a *ngIf="role == '2' && adminPage" [routerLinkActive]="['active']" [routerLink]="['/adjuster/home']" class="list-group-item" (click)="changeLogo()">
          <span class="icon icon-16 home"></span>Adjuster
        </a>

        <a *ngIf="['3','4','5','6'].includes(role)" [routerLinkActive]="['active']" [routerLink]="['/adjuster/home']" class="list-group-item" (click)="changeLogo()">
          <span class="icon icon-16 home"></span>Home
        </a>

        
        
        <a *ngIf="['3','4','5','6'].includes(role) || role == '1' && adminPage || role == '2' && adminPage" [routerLinkActive]="['active']" [routerLink]="['/adjuster/pharmacyLocator']" class="list-group-item list-group-item-action"> <span
            class="icon icon-16 pharmacyloc"></span>Pharmacy Locator</a>

        <a *ngIf="['3','4','5','6'].includes(role) || role == '1' && adminPage || role == '2' && adminPage" [routerLinkActive]="['active']" [routerLink]="['/adjuster/physician']" class="list-group-item list-group-item-action"> <span
            class="icon icon-16 physicianlook"></span>Physician Lookup</a>

        <a *ngIf="['3','4','5','6'].includes(role) || role == '1' && adminPage || role == '2' && adminPage" [routerLinkActive]="['active']" [routerLink]="['/adjuster/DrugDictionary']" class="list-group-item list-group-item-action"> <span class="icon icon-16 drug"></span>Drug
          Dictionary</a>
        
        
        <a *ngIf="['3','4','5','6'].includes(role) || role == '1' && adminPage || role == '2' && adminPage" [routerLinkActive]="['active']" [routerLink]="['/adjuster/member']" class="list-group-item list-group-item-action"><span class="icon icon-16 member"></span>Members</a>
        <a *ngIf="role == '1' && adminPage1" [routerLinkActive]="['active']" [routerLink]="['/client/members']" class="list-group-item list-group-item-action"><span class="icon icon-16 member"></span>Members</a>
        <a *ngIf="role == '2'  && !adminPage" [routerLinkActive]="['active']" [routerLink]="['/client/members']" class="list-group-item list-group-item-action"><span class="icon icon-16 member"></span>Members</a>
        
        <a *ngIf="role == '1' && adminPage1" [routerLinkActive]="['active']" [routerLink]="['/client/reporting']" class="list-group-item list-group-item-action"><span class="icon icon-16 fas fa-folder-open"></span>Reporting</a>
        <a *ngIf="role == '2'  && !adminPage" [routerLinkActive]="['active']" [routerLink]="['/client/reporting']" class="list-group-item list-group-item-action"><span class="icon icon-16 fas fa-folder-open"></span>Reporting</a>
        <a *ngIf="['3','4','5','6'].includes(role) || role == '1' && adminPage || role == '2' && adminPage" [routerLinkActive]="['active']" [routerLink]="['/adjuster/reporting']" class="list-group-item list-group-item-action"><span class="icon icon-16 fas fa-folder-open"></span>Reporting</a>
        
        <hr>
        <a *ngIf="role == '2'  && !adminPage" (click)='news()' [routerLinkActive]="['active']" [routerLink]="['/client/news']" class="list-group-item list-group-item-action"> 
          <span class="icon icon-16 news"></span>News
          <sup><span id="bdgIcon" class="badge badge-danger">{{messages}}</span></sup>
        </a>
        <a *ngIf=" role == '1' && adminPage1" (click)='news()' [routerLinkActive]="['active']" [routerLink]="['/client/news']"  class="list-group-item list-group-item-action"> <span class="icon icon-16 news"></span>News<sup><span id="bdgIcon" class="badge badge-danger">{{messages}}</span></sup></a>
        <a *ngIf="role == '1' && !adminPage1 && !adminPage" (click)='news()' [routerLinkActive]="['active']" [routerLink]="['/admin/news']" class="list-group-item list-group-item-action"> <span class="icon icon-16 news"></span>News<sup><span id="bdgIcon" class="badge badge-danger">{{messages}}</span></sup></a>
        <a *ngIf="['3','4','5','6'].includes(role) || role == '1' && adminPage || role == '2' && adminPage" (click)='news()' [routerLinkActive]="['active']" [routerLink]="['/adjuster/news']" class="list-group-item list-group-item-action"> <span class="icon icon-16 news"></span>News
          <sup><span id="bdgIcon" class="badge badge-danger">{{messages}}</span></sup>
        </a>


        <a *ngIf="role == '3' || role == '1' && adminPage || role == '2' && adminPage" [routerLinkActive]="['active']" [routerLink]="['/adjuster/vacation']" class="list-group-item list-group-item-action"> <span
            class="icon icon-16 vacation"></span>Vacation</a>

        <a *ngIf="role == '1' && !adminPage1 && !adminPage" [routerLinkActive]="['active']" [routerLink]="['/admin/contactus']" class="list-group-item list-group-item-action"> <span class="icon icon-16 contactus"></span>Contact
          Us</a>

          <a *ngIf="role == '2' && !adminPage" [routerLinkActive]="['active']" [routerLink]="['/client/contactus']" class="list-group-item list-group-item-action"> <span class="icon icon-16 contactus"></span>Contact
            Us</a>

            <a *ngIf=" role == '1' && adminPage1" [routerLinkActive]="['active']" [routerLink]="['/client/contactus']" class="list-group-item list-group-item-action"> <span class="icon icon-16 contactus"></span>Contact
              Us</a>
              <a *ngIf="['3','4','5','6'].includes(role) || role == '1' && adminPage || role == '2' && adminPage" [routerLinkActive]="['active']" [routerLink]="['/adjuster/contactus']" class="list-group-item list-group-item-action"> <span class="icon icon-16 contactus"></span>Contact
                Us</a>
        <a *ngIf="role == '1' && adminPage1" [routerLinkActive]="['active']" [routerLink]="['/client/uploadLogo']" class="list-group-item list-group-item-action"><span class="icon icon-16 Upload"></span>Upload Logo</a>
        <a *ngIf="role == '2' && !adminPage" [routerLinkActive]="['active']" [routerLink]="['/client/uploadLogo']" class="list-group-item list-group-item-action"><span class="icon icon-16 Upload"></span>Upload Logo</a>

        <a (click)="CollapseExpandMobile();showPin()" class="list-group-item list-group-item-action m-show" href="#" data-toggle="modal" data-target="#showPinModal">
            <svg xmlns="http://www.w3.org/2000/svg" width="13.312" height="13.312" viewBox="0 0 13.312 13.312"><defs><style></style></defs><g transform="translate(0.004 0.004)"><g transform="translate(-0.004 -0.004)"><path class="a" d="M7.942,5.863A4.158,4.158,0,0,0,4.095,0,4.167,4.167,0,1,0,5.863,7.963L7.069,9.149h1.04v1.04h1.04v1.04h1.04v1.04l.915.915a.411.411,0,0,0,.291.125h1.5a.417.417,0,0,0,.416-.416V11.4a.411.411,0,0,0-.125-.291ZM3.221,4.157a.936.936,0,1,1,.936-.936A.932.932,0,0,1,3.221,4.157Z" transform="translate(0.004 0.004)"/></g></g></svg>
          Show Pin</a>
          <a class="list-group-item list-group-item-action m-show" [routerLinkActive]="['active']" [routerLink]="['/changepassword']">
            <svg xmlns="http://www.w3.org/2000/svg" width="13.312" height="13.312" viewBox="0 0 13.312 13.312"><defs><style></style></defs><g transform="translate(0.004 0.004)"><g transform="translate(-0.004 -0.004)"><path class="a" d="M7.942,5.863A4.158,4.158,0,0,0,4.095,0,4.167,4.167,0,1,0,5.863,7.963L7.069,9.149h1.04v1.04h1.04v1.04h1.04v1.04l.915.915a.411.411,0,0,0,.291.125h1.5a.417.417,0,0,0,.416-.416V11.4a.411.411,0,0,0-.125-.291ZM3.221,4.157a.936.936,0,1,1,.936-.936A.932.932,0,0,1,3.221,4.157Z" transform="translate(0.004 0.004)"/></g></g></svg>
            Change Password</a>
          <a class="list-group-item list-group-item-action m-show" (click)="logout()">
              <svg xmlns="http://www.w3.org/2000/svg" width="14.119" height="11.472" viewBox="0 0 14.119 11.472"><defs><style></style></defs><g transform="translate(0 -6)"><g transform="translate(0 6)"><path class="a" d="M8.6,17.472,7.258,16.148l3.552-3.53H0V10.853H10.81L7.28,7.1,8.6,6l5.515,5.736Z" transform="translate(0 -6)"/></g></g></svg>
              Sign Out</a>


      </div>
      <button (click)="toggleMenu()" class="btn-aside"></button>
    </div>
  </aside>
  <!-- /SIDEBAR END -->
     <!-- Show Pin Modal -->
<div class="modal fade" id="showPinModal" tabindex="-1" role="dialog" aria-labelledby="showPinModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title" id="showPinModalLabel">PIN Information</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form>
            <div class="form-row">
              <div class="col-6 col-md-4">
                <input type="text" readonly class="form-control-plaintext form-control-sm pl-3 pl-md-0" value="Current PIN: {{pin}}">
                <!-- <label>Current PIN: {{pin}}</label> -->
              </div>
              <div class="col-6 col-md-4"><input type="text" class="form-control form-control-sm c-pin" cMinLength="4" cMaxLength="4" required id="chosenPin" placeholder="Create PIN" name="chosenPin" [(ngModel)]="chosenPin" numbersOnly></div>
              <div class="col-12 col-md-4 mt-3 mt-md-0"> <button class="btn btn-sm btn-primary btn-block" (click)="getChosenPin(chosenPin)">Save PIN</button></div>
          </div>
        </form>
      </div>
      
    </div>
  </div>
</div>
 <!-- Modal Ends -->