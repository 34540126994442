import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MemberService {
  data: any;
  url = environment.apiBase;
  saveButton;
  memberId = localStorage.getItem('mId');
  userId;
  item: any;

  constructor(private http: HttpClient) {
    this.userId = localStorage.getItem('userId');
  }

  setMemberId(memberId) {
    this.memberId = memberId;
  }

  getMemberId() {
    return this.memberId;
  }

  setSaveButton(saveButton) {
    this.saveButton = saveButton;
  }

  getSaveButton() {
    return this.saveButton;
  }

  getMemberQuickDetails(body, id): Observable<any> {
    return this.http
      .get(
        this.url +
          'api/Members/QuickSearch?adjusterUserId=' +
          id +
          '&LastName=' +
          body.lastName +
          '&FirstName=' +
          body.firstName +
          '&MemberCardNumber=' +
          body.memberCardNumber +
          '&ClaimNumber=' +
          body.claims +
          '&DateOfBirth=' +
          body.dob +
          '&SSN=' +
          body.last4DigitsofoSSN +
          '&isAssigned=' + body.isAssigned
      )
      .pipe(tap(res => {}));
  }

  getPin(id, pin): Observable<any> {
    return this.http
      .get(this.url + '/api/Clients/Pin/' + id + '/' + pin)
      .pipe(tap(res => {}));
  }

  getChosenPin(id, chosenPin): Observable<any> {

    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http
      .post(this.url + 'api/Clients/GetChosenPin/' + id + '/' + chosenPin, { headers, responseType: 'text' })
      .pipe(tap(res => { }));
  }

  getAllMemberQuickDetails(body): Observable<any> {
    return this.http
      .get(this.url + 'api/Members/QuickSearch?adjusterUserId=' + body.userId +
      '&isAssigned=' + body.isAssigned)
      .pipe(catchError(this.handleError));
  }
  getClientByClaim(id): Observable<any> {
    return this.http
      .get(this.url + 'api/Members/GetFfGroupClientId?claimId=' + id)
      .pipe(catchError(this.handleError));
  }

  postMemberDetails(body): Observable<any> {
    return this.http.post(this.url + 'api/Members', body).pipe(tap(res => {}));
  }

  postClaimsToAdjusters(cId, aId, mId, clId): Observable<any> {
    return this.http
      .put(
        this.url +
          'api/Members/AddMemberToAdjuster?clientId=' +
          cId +
          '&adjusterUserId=' +
          aId + '&memberId=' +
          mId + '&claimId=' + clId,
        ''
      )
      .pipe(tap(res => {}));
  }

  updateMemberDetails(body): Observable<any> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http
      .post(this.url + 'api/Members', body, { headers, responseType: 'text' })
      .pipe(tap(res => {}));
  }
  updateSMSSubscribe(memberid,phonenumber): Observable<any> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http
      .post(this.url + 'api/Members/SMSSubscribe/'+memberid+'/'+phonenumber,  { headers, responseType: 'text' })
      .pipe(tap(res => {}));
  }
  getAllMemberDetails(body): Observable<any> {
    return this.http
      .get(this.url + 'api/Members/MembersList?adjusterUserId=' + body.adjId+
      '&showAll=' + body.showAll
      )
      .pipe(tap(res => {}));
  }
  //
  getByMemberId(member): Observable<any> {
    return this.http
      .get(this.url + 'api/Members?memberid=' + member)
      .pipe(tap(res => {}));
  }

  deleteMember(id): Observable<any> {
    return this.http
      .put(this.url + 'api/Members/Delete?id=' + id, '')
      .pipe(tap(res => {}));
  }

  getImmediateAttention(body): Observable<any> {
    return this.http
      .get(
        this.url + 'api/Users/ItemsNeedImmediateAttention?adjusterUserId=' + body.adjusterUserId +
        '&isAssigned=' + body.isAssigned +
        '&searchText=' + body.searchText
      )
      .pipe(tap(res => {}));
  }

  getRecentActivity(id): Observable<any> {
    return this.http
      .get(this.url + 'api/Users/RecentActivities?adjusterUserId=' + id)
      .pipe(tap(res => {}));
  }

  get(): Observable<any> {
    return this.http
      .get(this.url + 'api/Users/ItemsNeedImmediateAttention')
      .pipe(tap(res => {}));
  }

  getMemberTreatedPhysican(memberId): Observable<any> {
    return this.http
      .get(this.url + 'api/Members/' + memberId + '/LatestClaim')
      .pipe(catchError(this.handleError));
  }

  getMemberNotesByMemberId(memberId): Observable<any> {
    return this.http
      .get(this.url + 'api/Members/' + memberId + '/Notes')
      .pipe(catchError(this.handleError));
  }

  getActivityByMemberId(memberId, filter): Observable<any> {
    return this.http
      .get(
        this.url +
          'api/Members/' +
          memberId +
          '/CurrentActivity?filterId=' +
          filter
      )
      .pipe(catchError(this.handleError));
  }

  getRejectionClaimsByMemberId(memberId, startDate, endDate): Observable<any> {
    return this.http
      .get(
        this.url +
          'api/Members/' +
          memberId +
          '/RejectedClaims?startDate=' +
          startDate +
          '&endDate=' +
          endDate
      )
      .pipe(catchError(this.handleError));
  }

  saveMemberNote(body): Observable<any> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http
      .post(this.url + 'api/Members/Notes', body, {
        headers,
        responseType: 'text'
      })
      .pipe(catchError(this.handleError));
  }

  updateMemberNote(body): Observable<any> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http
      .put(this.url + 'api/Members/Notes', body, {
        headers,
        responseType: 'text'
      })
      .pipe(catchError(this.handleError));
  }

  deleteMemberNote(noteId): Observable<any> {
    return this.http
      .delete(this.url + 'api/Members/Notes?memberNotesId=' + noteId, {
        responseType: 'text'
      })
      .pipe(tap(res => {}));
  }
  TakeNoAction(claimId): Observable<any> {

    return this.http    
      .put(this.url + 'api/Claims/TakeNoAction?claimId=' + claimId, '',{ responseType: 'text' })
      .pipe();
  }
  getAllClaimsById(body): Observable<any> {
    return this.http.get(this.url + 'api/Claims/' + body).pipe();
  }

  postApproval(body): Observable<any> {
    return this.http
      .put(this.url + 'api/Claims/Approve', body, { responseType: 'text' })
      .pipe();
  }

  rejectApproval(body): Observable<any> {
    return this.http
      .put(this.url + 'api/Claims/Reject', body, { responseType: 'text' })
      .pipe();
  }

  deleteMemberClaimHistoryLog(memberClaimId): Observable<any> {
    return this.http
      .delete(
        this.url +
          'api/Members/ClaimHistoryLog?memberClaimHistoryId=' +
          memberClaimId,
        { responseType: 'text' }
      )
      .pipe(catchError(this.handleError));
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error

      if (error.status >= 400 && error.status < 500) {
        errorMessage = 'Action failed due to bad request';
      } else if (error.status >= 500) {
        errorMessage = 'Service Not Available! Please Try Later!!!';
      }
    }
    return throwError(errorMessage);
  }

  getClaimByNDCNumber(memId, num): Observable<any> {
    return this.http
      .get(
        this.url +
          'api/Members/' +
          memId +
          '/GetLatestClaimByDrug?drugNDCNumber=' +
          num
      )
      .pipe(tap(res => {}));
  }

  getGroupsById(id): Observable<any> {
    return this.http
      .get(this.url + 'api/Clients/AdjusterGroups/' + id)
      .pipe(tap(res => {}));
  }

  getAdjusterByClientId(id, adj): Observable<any> {
    return this.http
      .get(this.url + 'api/Clients/LoadAdjusters/' + id + '?adjusterId=' + adj)
      .pipe(tap(res => {}));
  }

  saveVacation(body): Observable<any> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http
      .post(this.url + 'api/Clients/Vacation', body, {
        headers,
        responseType: 'text'
      })
      .pipe();
  }

  getVacationData(id, adj): Observable<any> {
    return this.http
      .get(
        this.url +
          'api/Clients/LoadVacationData?clientId=' +
          id +
          '&AdjusterId=' +
          adj
      )
      .pipe(tap(res => {}));
  }

  getAllAdjusterByClientId(id, adj): Observable<any> {
    return this.http
      .get(
        this.url +
          'api/Clients/LoadAllVacationAdjusters/' +
          id +
          '?adjusterId=' +
          adj
      )
      .pipe(tap(res => {}));
  }

  getAllClients(): Observable<any> {
    return this.http.get(this.url + 'api/Clients').pipe(tap(res => {}));
  }

  getItemcall(): any {
    return this.item;
  }

  setItemcall(val) {
    this.item = val;
  }
}
