import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneratePasswordService } from '../generatepaasword/generatepassword.service';
import { NotifierService } from 'angular-notifier';
import { PreferedDates } from '../Adjuster/drug-lookup/drug-lookup.component';
import * as moment from 'moment';
import { cDto } from '../Adjuster/attention/attention.component';
import { DrugLookupService } from '../Adjuster/drug-lookup/drug-lookup.service';
import { LoaderService } from '../loader/loader.service';
import { LoginService } from '../login/login.service';
import { MemberService } from '../Adjuster/member/member.service';
import { PreferedPhysicianDto } from '../Adjuster/physician-lookup/physician-lookup.component';
import { PhysicianLookupService } from '../Adjuster/physician-lookup/physician-lookup.service';
import { ClaimHistoryService } from '../Adjuster/claims-history/claims-history.service';
import { DatePipe } from '@angular/common';
// Global jQuery declaration
declare let $;

@Component({
  selector: 'app-register',
  templateUrl: './preApproved.component.html',
  styleUrls: ['./preApproved.component.css']
})
export class PreApprovedComponent implements OnInit {
  // variables
  hide: boolean;
  userId;
  any;
  preferedDatesDto: PreferedDates = new PreferedDates();
  preferedPhysicianDto: PreferedPhysicianDto = new PreferedPhysicianDto();
  minDate: Date = new Date();
  toDate: boolean;
  cres: cDto = new cDto();
  display: boolean;
  pDto: preApproved = new preApproved();
  password = '';
  type: null;
  display2: any;
  loginError: any;
  locked: boolean;
  errorStatus: boolean;
  error: any;
  adjusterId = localStorage.getItem('userId');
  msg: any;
  errorMsg: any;
  errDisplay: boolean;
  errMsgs: any;
  opt: number;
  msg1: any;
  errorMsg1: any;

  constructor(
    private physicianLookupService: PhysicianLookupService,
    private generatePasswordService: GeneratePasswordService,
    private memberService: MemberService,
    private loginService: ClaimHistoryService,
    private notifier: NotifierService,
    private route: ActivatedRoute,
    private router: Router,
    private drugLookupService: DrugLookupService,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.userId = this.route.snapshot.queryParamMap.get('UserId');

    localStorage.setItem('preId', this.userId);
    // this.userId = this.userId.replace(/ /g, '+');
    // this.validate()

    this.router.navigate(['/PreApprovedClaim']);
  }

  changeTodefaultDates(startDate?:Date) {
    this.preferedDatesDto.startDate = startDate ==null ? new Date(): startDate;
    this.preferedDatesDto.endDate = new Date(this.preferedDatesDto.startDate);
    if (this.opt === 1) {
      this.preferedDatesDto.startDate.setDate(this.preferedDatesDto.startDate.getDate() + 3);
    }
    if(this.opt === 4){
      this.preferedDatesDto.endDate.setDate(this.preferedDatesDto.startDate.getDate() + 3);
    }else{      
      this.preferedDatesDto.endDate.setMonth(this.preferedDatesDto.startDate.getMonth() + 6);
    } 
  }

  changeTodefaultBlockedDates() {
    this.preferedDatesDto.startDate = new Date();
    this.preferedDatesDto.endDate = new Date();
    this.preferedDatesDto.endDate.setDate(
      this.preferedDatesDto.startDate.getDate()
    );
  }

  login() {
    // this.display = true    
    const body = {
      key: this.password,
      value: localStorage.getItem('preId')
    };
    this.loginService.getClaimByPin(body).subscribe(
      res => {        
        this.pDto = res;
        this.pDto.startDate = new Date(this.pDto.startDate);
        this.type = this.pDto.requestType;
        localStorage.setItem('token', this.pDto.jwtToken);

        this.display = true;
        if (this.type === 'Approve') {
          this.approve();
        }
        if (this.type === 'Deny') {
          this.RejectApprove();
        }
        if (this.type === 'AddToPreferredList') {
          this.opt = 1;
          this.changeTodefaultDates();
          $('#addpre').modal({
            backdrop: 'static',
            keyboard: false,
            show: true
          });
        }
        if (this.type === 'AddTophysician') {
          this.opt = 2;
          this.changeTodefaultDates();
          $('#addpre').modal({
            backdrop: 'static',
            keyboard: false,
            show: true
          });
        }

        if (this.type === 'AddDocDrug') {
          this.opt = 3;
          this.changeTodefaultDates(this.pDto.startDate);
          $('#addpre').modal({
            backdrop: 'static',
            keyboard: false,
            show: true
          });
        }
        if (this.type === 'AddToBlockList') {
          this.opt = 5;
          this.changeTodefaultBlockedDates();
          $('#addpre').modal({
            backdrop: 'static',
            keyboard: false,
            show: true
          });
        }
      },
      error => {
        this.errorStatus = true;
        this.error = error.error;

        // document.getElementById('myModal').style.display='none'
        this.notifier.notify('error', this.error);
        //  this.loginError = this.error.message
        //  this.locked = this.error.isAccountLocked

        //  if (this.locked) {
        //    $('#myModalz').modal('show');
        //  }
      }
    );
  }

  // validate claim API
  validate(): any {
    this.generatePasswordService.validateClaim(this.userId).subscribe(
      res => {
        this.pDto = res;
        this.type = this.pDto.requestType;
        if (res.isFromPreAppoved === true) {
        } else {
          // this.notifier.notify('error', 'Claim already approved!')
          // setTimeout(() => {
          //   this.router.navigate(['login']);
          // }, 2000);
        }
      },
      error => {
        this.errDisplay = true;
        this.errMsgs = error.error;
      }
    );
  }

  approve() {
    this.opt = 4;
    this.changeTodefaultDates(this.pDto.startDate);
    $('#addpre').modal({
      backdrop: 'static',
      keyboard: false,
      show: true
    });
  }

  // Approve claim API
  PostApprove() {
    let stDate = new Date(this.preferedDatesDto.startDate);
    let eDate = new Date(this.preferedDatesDto.endDate);
    const body = {
      claimId: this.pDto.claimId,
      memberId: this.pDto.memberId,
      adjusterId: this.adjusterId,
      adjusterName: this.pDto.adjusterName,
      drugName: this.pDto.drugName,
      startDate: stDate,
      endDate: eDate,
      ndcNumber: this.cres.drugNDCNumber
    };
    this.memberService.postApproval(body).subscribe(
      res => {
        // this.notifier.notify('success', res);
        this.display2 = 1;
        this.msg = res;
        this.clear();
      },
      error => {
        this.errorMsg = error.error;
        this.display2 = 5;
        this.clear();
      }
    );
  }

  // Reject claim API
  RejectApprove() {
    const body = {
      claimId: this.pDto.claimId,
      memberId: this.pDto.memberId,
      adjusterId: this.adjusterId,
      adjusterName: this.pDto.adjusterName,
      drugName: this.pDto.drugName
    };
    this.memberService.rejectApproval(body).subscribe(
      res => {
        // this.notifier.notify('success', res);
        this.msg = res;
        this.display2 = 2;
        this.clear();
      },
      error => {
        this.errorMsg = error.error;
        this.display2 = 1;
        this.clear();
      }
    );
  }

  // Add to preffered list API
  postprefDrug(e) {
    let stDate = this.datePipe.transform(
      this.preferedDatesDto.startDate,
      'MM/dd/yyyy'
    );
    let eDate = this.datePipe.transform(
      this.preferedDatesDto.endDate,
      'MM/dd/yyyy'
    );
    if (this.opt === 3 || this.opt === 4) {
    } else {
      let csdate = new Date();
    let cedate = new Date(csdate);
    cedate.setDate(csdate.getDate() + 3);
      const body1 = {
        claimId: this.pDto.claimId,
        memberId: this.pDto.memberId,
        adjusterId: this.adjusterId,
        adjusterName: this.pDto.adjusterName,
        drugName: this.pDto.drugName,
        startDate: csdate,
        endDate: eDate,
        ndcNumber: this.cres.drugNDCNumber
      };
      this.memberService.postApproval(body1).subscribe(res => {});
    }
    const body = {
      ndcNumber: this.pDto.ndcNumber,
      gpiNumber: this.pDto.gpiNumber,
      drugName: this.pDto.drugName,
      memberId: this.pDto.memberId,
      memberName: this.pDto.memberName,
      validityStartDate: stDate,
      validityEndDate: eDate
    };
    this.drugLookupService.postDrug(body).subscribe(
      res => {
        this.msg =
          'Successfully medication is approved and added to preferred drug list';
        // this.notifier.notify('success', 'Successfully added drug to preferred list');
        if (this.opt === 3 || this.opt === 4) {
          this.display2 = 7;
        } else {
          this.display2 = 3;
        }
        this.clear();
      },
      error => {
        this.errorMsg = error.error;
        this.display2 = 5;
        this.clear();
      }
    );
  }

  // Block Drug API
  blockDrug() {
    let stDate = this.datePipe.transform(
      this.preferedDatesDto.startDate,
      'MM/dd/yyyy'
    );
    let eDate = this.datePipe.transform(
      this.preferedDatesDto.endDate,
      'MM/dd/yyyy'
    );

    const body1 = {
      claimId: this.pDto.claimId,
      memberId: this.pDto.memberId,
      adjusterId: this.adjusterId,
      adjusterName: this.pDto.adjusterName,
      drugName: this.pDto.drugName
    };
    this.memberService.rejectApproval(body1).subscribe(res => {});
    const body = {
      gpiNumber: this.pDto.gpiNumber,
      ndcNumber: this.pDto.ndcNumber,
      drugName: this.pDto.drugName,
      memberId: this.pDto.memberId,
      memberName: this.pDto.memberName,
      validityStartDate: stDate,
      validityEndDate: eDate
    };
    this.drugLookupService.blockDrugs(body).subscribe(
      res => {
        //this.notifier.notify('success', 'Successfully drug is blocked ');
        this.display2 = 4;
        this.msg =
          'Successfully medication is denied & added to blocked medication list';
        this.clear();
      },
      error => {
        this.errorMsg = error.error;
        if (this.opt == 3) {
          this.display2 = 7;
        } else {
          this.display2 = 5;
        }
        this.clear();
      }
    );
  }

  postPhyPreList(e) {
    let stDate = this.datePipe.transform(
      this.preferedDatesDto.startDate,
      'MM/dd/yyyy'
    );
    let eDate = this.datePipe.transform(
      this.preferedDatesDto.endDate,
      'MM/dd/yyyy'
    );    
    if (this.opt === 3 || this.opt === 4) {
    } else {
        let csdate = new Date();
        let cedate = new Date(csdate);
        cedate.setDate(csdate.getDate() + 3);
      const body1 = {
        claimId: this.pDto.claimId,
        memberId: this.pDto.memberId,
        adjusterId: this.adjusterId,
        adjusterName: this.pDto.adjusterName,
        drugName: this.pDto.drugName,
        startDate: csdate,
        endDate: eDate,
      ndcNumber: this.cres.drugNDCNumber
      };
      this.memberService.postApproval(body1).subscribe(res => {});
    }
    const body = {
      physicianName: this.pDto.physicianName,
      memberId: this.pDto.memberId,
      memberName: this.pDto.memberName,
      validityStartDate: stDate,
      validityEndDate: eDate,
      physicianDetails: {
        npiNumber: this.pDto.physicianId
      }
    };
    this.physicianLookupService.createPreferedPhysician(body).subscribe(
      res => {
        if (this.opt === 3 || this.opt === 4) {
          this.display2 = 7;
        } else {
          this.display2 = 6;
        }
        this.msg =
          'Successfully medication is approved and added to preferred physician list';
        this.msg1 =
          'Successfully medication is approved and added to preferred physician list';
        this.clear();
      },
      error => {
        this.errorMsg1 = error.error;
        if (this.opt === 3) {
          this.display2 = 7;
        } else {
          this.display2 = 5;
        }
        this.clear();
      }
    );
  }

  PostBoth(e) {
    let csdate = this.datePipe.transform(
      this.preferedDatesDto.startDate,
      'MM/dd/yyyy'
    );
    let cedate = this.datePipe.transform(
      this.preferedDatesDto.endDate,
      'MM/dd/yyyy'
    );   
    const body1 = {
      claimId: this.pDto.claimId,
      memberId: this.pDto.memberId,
      adjusterId: this.adjusterId,
      adjusterName: this.pDto.adjusterName,
      drugName: this.pDto.drugName,
      startDate: csdate,
      endDate: cedate,
      RequestType: this.opt,
      ndcNumber: this.cres.drugNDCNumber
    };
    this.memberService.postApproval(body1).subscribe(res => {});
    this.postPhyPreList(e);
    this.postprefDrug(e);
    this.display2 = 7;
  }

  // Date changes
  updateMyDate(newdate) {
    const newD_ = Object.assign(newdate.value);
    this.preferedDatesDto.endDate = new Date(newD_);
    if (this.opt === 5) {
      this.preferedDatesDto.endDate.setDate(
        this.preferedDatesDto.endDate.getDate()
      );
    } else {
      this.preferedDatesDto.endDate.setDate(
        this.preferedDatesDto.endDate.getDate() + 90
      );
    }
  }

  // Reset function
  resetDate() {
    this.preferedDatesDto = new PreferedDates();
    this.display = false;
    this.password = '';
    this.router.navigate(['PreApprovedClaim']);

    // let win = window.open('','_blank'); /* url = “” or “about:blank”; target=”_self” */
    // win.close();
  }

  // Date change function
  dateChange() {
    this.toDate = true;
  }

  clear() {
    localStorage.clear();
  }

  validateDate(e) {
    let aa = moment(e.startDate, 'YYYY-MM-DDTHH:mm:ss.SSS').format(
      'YYYY-MM-DD'
    );
    let b = moment(e.endDate, 'YYYY-MM-DDTHH:mm:ss.SSS').format('YYYY-MM-DD');

    if (this.opt === 5) {
      let isAfter = moment(b).isAfter(aa);
      if (isAfter) {
        this.notifier.notify('error', 'To date should be same as From date');
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 2000);
      } else {
        this.blockDrug();
      }
    } else {
      if (aa >= b) {
        this.notifier.notify('error', 'To date should be more then From date');
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 2000);
      } else {
        if (this.opt === 1) {
          this.postprefDrug(e);
        } else if (this.opt === 2) {
          this.postPhyPreList(e);
        } else if (this.opt === 3) {
          this.PostBoth(e);
        } else if (this.opt === 4) {
          //this.PostApprove();
          this.PostBoth(e);
        }
      }
    }
  }
}

export class preApproved {
  memberName;
  claimId: 0;
  memberId: 0;
  adjusterId: null;
  adjusterName: null;
  email: null;
  drugName: null;
  isFromPreAppoved: false;
  requestType: null;
  gpiNumber: null;
  ndcNumber: null;
  physicianId: null;
  physicianName: null;
  jwtToken: null;
  startDate:Date;
}
