<!-- REGISTER SECTION START -->
<section class="register-section">
  <div class="container-fluid" id="body" >
    
      <!-- REGISTER FORM SECTION START -->
      <div class="register-wrapper" >
        <div class="register-inner">
          <div style="width:100%;">
            <a href="#" id="logo" style="margin-top: 25px;">
              <img alt="CompPBM - PHARMACY MANAGEMENT" />
            </a>
          </div>
            
            <div class="register-form">              
              <form name="form" id="resetForm" autocomplete="off" (ngSubmit)="onSubmit(f);" #f="ngForm" novalidate >
                <p>Register Account</p>
                <div class="form-group">

                  <input type="text" id="inputFirstName" class="form-control txt" [(ngModel)]="firstName" name="firstName" placeholder="First Name" #firstNameValidate="ngModel" required autofocus />
              <div *ngIf="(firstNameValidate.invalid && firstNameValidate.touched) || (formSubmitAttempt && !firstNameValidate.touched)" >
                 <div *ngIf="firstNameValidate.errors?.required" style="margin-top: 2px;color: red" >First Name is required</div>
            </div>

                </div>

                <div class="form-group" >
                  <input type="text" id="inputLastName" class="form-control txt" [(ngModel)]="lastName" name="lastName" #lastNameValidate="ngModel" placeholder="Last Name" required />  
                  <div *ngIf="(lastNameValidate.invalid && lastNameValidate.touched) || (formSubmitAttempt && !lastNameValidate.touched)" >
                    <div *ngIf="lastNameValidate.errors?.required" style="margin-top: 2px;color: red">Last Name is required</div>
               </div>            
                </div>

                <div class="form-group">
                  <input type="email" id="inputEmail"  class="form-control txt" [(ngModel)]="username" name="username" email #userNameValidate="ngModel" placeholder="Email address"  required >
                  <div *ngIf="(userNameValidate.invalid && userNameValidate.touched) || (formSubmitAttempt && !userNameValidate.touched)" >
                    <div *ngIf="userNameValidate.errors?.required" style="margin-top: 2px;color: red">Email is required</div>
                    <div *ngIf="userNameValidate.errors?.email" style="margin-top: 2px;color: red">Please enter a vaild email in the
                      following format: 'abc@email.com'</div>
               </div>      
                </div>
                <div class="form-group">
                  <input type="password"  id="inputPassCode"  class="form-control txt" [(ngModel)]="passCode" name="passCode" #passCodeValidate="ngModel"  placeholder="Passcode" required />
                  <div *ngIf="(passCodeValidate.invalid && passCodeValidate.touched) || (formSubmitAttempt && !passCodeValidate.touched)">
                    <div *ngIf="passCodeValidate.errors?.required" style="margin-top: 2px;color: red">Passcode is required</div>
               </div>   
              </div>

                <button id="btnRegisterPortalAdmin" type="submit" class="btn btn-primary btn-block" name="command" value="Submit" >Register</button>               
                
              </form>
              
            </div>
          
        </div>
      </div>
      <!-- REGISTER FORM SECTION END -->     
    
  </div>
</section>
<input name="__RequestVerificationToken" type="hidden" value="CfDJ8BowgskRhzhAnv1ZHuooAUNaO0gtZgStKMRN6jsCT02H8pdS7uN7DNimLkaM_tEe1NWkP8UYg5W02-zvGzAr6T5tEI_qRTshrNuwmol-aTwWgxmLMqMODuo_7JPl8WIUtkHz1EEQTqtU6AGBNFgLBN8Nfr9T0JmNSfRtPnPy7dn4IM2HhqMYkZ8zHjaIUQFDgw">
<notifier-container></notifier-container>
<!-- REGISTER SECTION END -->