import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Subject, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { HeaderService } from '../header/header.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "../header/header.service";
var LoginService = /** @class */ (function () {
    function LoginService(http, router, headerService) {
        this.http = http;
        this.router = router;
        this.headerService = headerService;
        this.url = environment.apiBase;
        this.userID = null;
        this.imgSource = new BehaviorSubject('');
        this.imgUrl = this.imgSource.asObservable();
        // Login service
        this._userActionOccured = new Subject();
    }
    LoginService.prototype.updatedImgSource = function (imgUrl) {
        this.imgSource.next(imgUrl);
    };
    Object.defineProperty(LoginService.prototype, "userActionOccured", {
        get: function () {
            return this._userActionOccured.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    LoginService.prototype.notifyUserAction = function () {
        this._userActionOccured.next();
    };
    LoginService.prototype.login = function (body) {
        var _this = this;
        localStorage.setItem('email', body.email);
        var body1 = {
            email: body.email.trim(),
            password: body.password.trim()
        };
        return this.http.post(this.url + 'api/Account/Login', body1).pipe(tap(function (res) {
            body1 = {
                email: '',
                password: ''
            };
            localStorage.setItem('clientId', res.clientId);
            localStorage.setItem('roleId', res.roleId);
            localStorage.setItem('token', res.token);
            localStorage.setItem('name', res.firstName);
            localStorage.setItem('first', res.isPwdVerified);
            localStorage.setItem('clientName', res.clientName);
            localStorage.setItem('userId', res.userId);
            _this.headerService.updatedCompanyName(res.clientName);
            // StorageManager.
        }));
    };
    LoginService.prototype.getImageUrl = function (submainName) {
        return this.http.get(this.url + ("api/Account/GetLogoByDomainAsync/LogoByDomain/" + submainName), { responseType: 'text' }).pipe(tap(function (res) {
        }));
    };
    LoginService.prototype.getImageUrlClientId = function (clientId) {
        return this.http.get(this.url + ("api/Clients/GetCustomerLogoByClientId/" + clientId), { responseType: 'text' }).pipe(tap(function (res) {
        }));
    };
    LoginService.prototype.logout = function () {
        localStorage.clear();
        // this.router.navigate(['/']);
    };
    LoginService.ngInjectableDef = i0.defineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.inject(i1.HttpClient), i0.inject(i2.Router), i0.inject(i3.HeaderService)); }, token: LoginService, providedIn: "root" });
    return LoginService;
}());
export { LoginService };
