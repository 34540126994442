import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var GeneratePasswordService = /** @class */ (function () {
    function GeneratePasswordService(http) {
        this.http = http;
        this.url = environment.apiBase;
    }
    GeneratePasswordService.prototype.reset = function (data) {
        return this.http
            .put(this.url + 'api/Account/ResetPassword', data, {
            responseType: 'text'
        })
            .pipe(tap(function (res) { }));
    };
    GeneratePasswordService.prototype.validate = function (id) {
        var token = {
            userId: 'string',
            value: id
        };
        return this.http
            .post(this.url + 'api/Account/ValidateUser/ValidateUser', token, {
            responseType: 'text'
        })
            .pipe(tap(function (res) { }));
    };
    GeneratePasswordService.prototype.validateClaim = function (id) {
        return this.http
            .get(this.url + 'api/Claims/ValidateAutoApproveClaim?id=' + id)
            .pipe(tap(function (res) { }));
    };
    GeneratePasswordService.ngInjectableDef = i0.defineInjectable({ factory: function GeneratePasswordService_Factory() { return new GeneratePasswordService(i0.inject(i1.HttpClient)); }, token: GeneratePasswordService, providedIn: "root" });
    return GeneratePasswordService;
}());
export { GeneratePasswordService };
