<!-- <div class="PbmFooter ">
    <div class="row">


    </div>
</div> -->
<!-- FOOTER START -->
<footer id="footer">
    <div class="container-fluid">
      <div class="row float-right text-right">
        <div class="col-12">
          <p>POWERED BY</p>
          <img src="assets/images/logo-dashboard.png" alt="">
          <p class="p1">1-844-987-0011</p>
        </div>
      </div>
    </div>
  </footer>
  <!-- FOOTER END -->