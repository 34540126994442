/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./service-down.component";
var styles_ServiceDown = [".servicedown-page[_ngcontent-%COMP%]{\n        padding:10px;\n    }\n    .divserverDown[_ngcontent-%COMP%]{\n    border-radius: 5px;\n    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.5);\n    max-width: 500px;\n    background-color: #fff;\n    padding:10px;\n   margin: 5% auto auto auto;\n}\n.title-primary[_ngcontent-%COMP%]{\n  margin-bottom: 16px;\n  font-size: 24px;\n  color:#136ab2;\n}"];
var RenderType_ServiceDown = i0.ɵcrt({ encapsulation: 0, styles: styles_ServiceDown, data: {} });
export { RenderType_ServiceDown as RenderType_ServiceDown };
export function View_ServiceDown_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "servicedown-page"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "divserverDown"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 9, "div", [["class", "col-md-12 text-center "]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "form-group title-primary"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Internal server error"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Please contact Administrator"])), (_l()(), i0.ɵeld(7, 0, null, null, 4, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 3, "a", [["class", "btn btn-link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 9).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(9, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(10, 1), (_l()(), i0.ɵted(-1, null, ["Back to Home"]))], function (_ck, _v) { var currVal_2 = _ck(_v, 10, 0, "/"); _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 9).target; var currVal_1 = i0.ɵnov(_v, 9).href; _ck(_v, 8, 0, currVal_0, currVal_1); }); }
export function View_ServiceDown_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "service-down", [], null, null, null, View_ServiceDown_0, RenderType_ServiceDown)), i0.ɵdid(1, 114688, null, 0, i3.ServiceDown, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ServiceDownNgFactory = i0.ɵccf("service-down", i3.ServiceDown, View_ServiceDown_Host_0, {}, {}, []);
export { ServiceDownNgFactory as ServiceDownNgFactory };
