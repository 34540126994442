import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  data: any;
  url = environment.apiBase;

  showLoader = true;

  constructor(private http: HttpClient) {
  }

  getRecentTransactionSearch(body): Observable<any> {
    return this.http
      .get(
        this.url +
          'api/Claims/GetRecentTransactionSearch?pagesize=' +
          body.pageSize +
          '&page=' +body.page
      )
  }

  getReversalSearch(body): Observable<any> {
    return this.http
    .get(
      this.url +
        'api/Claims/GetReversalSearch?ClaimNumber=' +
        body.claimNumber +
        '&CardholderNumber=' +
        body.cardholderNumber +
        '&AuthorizationNumber=' +
        body.authorizationNumber +
        '&MemberFirstName=' +
        body.firstName +
        '&MemberLastName=' +
        body.lastName
    )
  }

  getAWPSearch(body): Observable<any> {
    return this.http
    .get(
      this.url +
        'api/Claims/GetAWPSearch?NDCNumber=' +
        body.nDCNumber +
        '&GPI=' +
        body.gPI +
        '&pagesize=' +
          body.pageSize +
          '&page=' +body.page
    )
  }

  getLoader(): any {
    return this.showLoader;
  }

  setLoader(value) {
    this.showLoader = value;
  }

}
