import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ChangePasswordService = /** @class */ (function () {
    function ChangePasswordService(http) {
        this.http = http;
        this.url = environment.apiBase;
    }
    ChangePasswordService.prototype.reset = function (data) {
        return this.http
            .put(this.url + 'api/Account/ResetPassword', data, {
            responseType: 'text'
        })
            .pipe(tap(function (res) { }));
    };
    ChangePasswordService.ngInjectableDef = i0.defineInjectable({ factory: function ChangePasswordService_Factory() { return new ChangePasswordService(i0.inject(i1.HttpClient)); }, token: ChangePasswordService, providedIn: "root" });
    return ChangePasswordService;
}());
export { ChangePasswordService };
