import { throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UnsubscribeService = /** @class */ (function () {
    function UnsubscribeService(http) {
        this.http = http;
        this.url = environment.apiBase;
    }
    UnsubscribeService.prototype.getUnscbscription = function (Id) {
        return this.http
            .get(this.url + 'api/MemberCard/SMSUnSubscription?id=' + Id)
            .pipe(catchError(this.handleError));
    };
    UnsubscribeService.prototype.handleError = function (error) {
        var errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = "Error: " + error.error.message;
        }
        else {
            // server-side error
            if (error.status >= 400 && error.status < 500) {
                errorMessage = 'Action failed due to bad request';
            }
            else if (error.status >= 500) {
                errorMessage = 'Action failed due to server side issue';
            }
        }
        return throwError(errorMessage);
    };
    UnsubscribeService.ngInjectableDef = i0.defineInjectable({ factory: function UnsubscribeService_Factory() { return new UnsubscribeService(i0.inject(i1.HttpClient)); }, token: UnsubscribeService, providedIn: "root" });
    return UnsubscribeService;
}());
export { UnsubscribeService };
