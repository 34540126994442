import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MemberService } from '../member/member.service';
import * as moment from 'moment';
import {
  MemberDto,
  MemberDetailsDto
} from '../view-member/view-member.component';
import { NotifierService } from 'angular-notifier';
import { PreferedDates } from '../drug-lookup/drug-lookup.component';
import { DrugLookupService } from '../drug-lookup/drug-lookup.service';
import { CommanUtils } from 'src/app/Utils/commanUtils.service';
import { ViewMemberDetialsService } from '../view-member/view-member.service';
import { PhysicianLookupService } from '../physician-lookup/physician-lookup.service';
import { DatePipe } from '@angular/common';

// jQuery declaration
declare var $;
@Component({
  selector: 'app-attention',
  templateUrl: './attention.component.html',
  styleUrls: ['./attention.component.css']
})
export class AttentionComponent implements OnInit {
  // Vaiables
  p1: number = 1;
  p2: number = 1;
  p3: number = 1;
  p4: number = 1;
  @ViewChild('abc') nameField: ElementRef;
  memberId;
  memDto: MemberDto = new MemberDto();
  memDDto: MemberDetailsDto = new MemberDetailsDto();
  cId = localStorage.getItem('claimId');
  cres: cDto = new cDto();
  selClaim = 0;
  preferedDatesDto: PreferedDates = new PreferedDates();
  minDate: Date = new Date();
  drugName = '';
  prior: boolean = false;
  val = 0;
  toDate: boolean;
  reject = [];
  a = '0';
  drugListResult: boolean;
  preferedDrugsData = [];
  physicianData = [];
  docListResult: boolean;
  blkDList = [];
  blkPList = [];
  expErr: boolean;
  clientId = localStorage.getItem('clientId');
  userId: string;
  adjData: any;
  clntData: any;
  cName = '';
  adjName = '';
  clntid: any;
  adjId: any;
  FFClientID: any;
  rID: any;
  canEdit:boolean=true;
  constructor(
    private memberDetailsService: ViewMemberDetialsService,
    private doctorSercice: PhysicianLookupService,
    private medicinService: DrugLookupService,
    private drugLookupService: DrugLookupService,
    private notifier: NotifierService,
    private router: Router,
    private memberService: MemberService,
    private commanUtils: CommanUtils,
    private datePipe: DatePipe
  ) {
    localStorage.setItem('mId', this.memberId);
    this.memberId = localStorage.getItem('memberId');
  }

  ngOnInit() {
    $(document).ready(function() {
      $(window).scrollTop(0);
    });
    if(localStorage.getItem('roleId')=='4' || localStorage.getItem('roleId')=='5'){
      this.canEdit=false;
    }
    if (
      localStorage.getItem('roleId') === '1' ||
      localStorage.getItem('roleId') === '2'
    ) {
      this.userId = localStorage.getItem('adjId');
    } else {
      this.userId = localStorage.getItem('userId');
    }
    this.drugName = '';
    this.getMemberDetials();
    this.getAllClaimById();
    if (this.selClaim === 1) {
      // this.PostApprove();
      this.RejectApprove();
    }
    this.drugName = this.memberDetailsService.getDrugName();
    if (this.drugName === '') {
      this.prior = false;
    } else {
      this.prior = true;
      this.memberDetailsService.setDrugName('');
    }
    this.getPreferedMedicins();
    this.getMemberPreferredDoctor();
    this.getphyBlockedList();
    this.getDrugBlockedList();
    this.getAllClients();
   /* this.memberService.getClientByClaim(this.cId).subscribe(res => {
      this.rID = res;
      // this.getAllAdjusterById(res);
    });*/
  }

  // Get list of adjusters
  getAllClients() {
    this.memberService.getAllClients().subscribe(res => {
      this.clntData = res.transactionObject;
    });
  }

  // Get list of adjusters
  getAllAdjusterById() {
    this.clntid = this.rID;
    this.memberService
      .getAllAdjusterByClientId(this.rID, this.userId)
      .subscribe(res => {
        this.adjData = res;
        this.adjId = this.adjData;
      });
  }

  // Get member deatails of all members API
  getMemberDetials() {
    this.memberService.getByMemberId(this.memberId).subscribe(res => {
      this.memDto = res[0].item1;
      if(!this.memDto.isAssignedNewAdjuster){
        if(this.memDto.clientId>0){
          this.rID = this.memDto.clientId;
          this.getAllAdjusterById();
        }else{
          this.memberService.getClientByClaim(this.cId).subscribe(res => {
            this.rID = res;
            this.getAllAdjusterById();
          });
        }       
      }
      this.memDDto = res[0].item2;
      this.memDDto.dateOfBirth = moment(
        this.memDDto.dateOfBirth,
        'YYYY-MM-DDTHH:mm:ss.SSS'
      ).format('MM-DD-YYYY');
      // this.memDDto.accountBalanceDate = moment(this.memDDto.accountBalanceDate, 'YYYY-MM-DDTHH:mm:ss.SSS').format('YYYY-MM-DD')
      this.memDDto.startDate = moment(
        this.memDDto.startDate,
        'YYYY-MM-DDTHH:mm:ss.SSS'
      ).format('MM-DD-YYYY');
      this.memDDto.endDate = moment(
        this.memDDto.endDate,
        'YYYY-MM-DDTHH:mm:ss.SSS'
      ).format('MM-DD-YYYY');
      this.memDDto.ffStartDate = moment(
        this.memDDto.ffStartDate,
        'YYYY-MM-DDTHH:mm:ss.SSS'
      ).format('MM-DD-YYYY');
      this.memDDto.ffEndDate = moment(
        this.memDDto.ffEndDate,
        'YYYY-MM-DDTHH:mm:ss.SSS'
      ).format('MM-DD-YYYY');
      this.memDDto.startDate === '' ? '----' : this.memDDto.startDate;
      this.memDDto.endDate === '' ? '----' : this.memDDto.endDate;
      this.memDDto.ffStartDate === '' ? '----' : this.memDDto.startDate;
      this.memDDto.ffEndDate === '' ? '----' : this.memDDto.endDate;
      this.memDDto.dateOfBirth === 'Invalid Date'
        ? ''
        : this.memDDto.dateOfBirth;
      this.memDDto.accountBalanceDate === 'Invalid Date'
        ? ''
        : this.memDDto.accountBalanceDate;
      if (this.memDDto.phoneNumberOf === '1') {
        this.memDDto.phoneNumberOf = '(H)';
      } else if (this.memDDto.phoneNumberOf === '2') {
        this.memDDto.phoneNumberOf = '(W)';
      } else if (this.memDDto.phoneNumberOf === '3') {
        this.memDDto.phoneNumberOf = '(M)';
      } else {
      }
      if (this.memDDto.phoneNumber === '') {
        this.memDDto.phoneNumberOf = '';
      } else {
        this.memDDto.phoneNumber = this.commanUtils.convertToInternational(
          this.memDDto.phoneNumber
        );
      }

      this.memDDto.groups.forEach(item => {
        if (item.ffId === 1) {
          this.memDDto.groupNumber = item.groupNumber;
        } else {
          this.memDDto.ffGroupNumber = item.groupNumber;
        }
      });
    });
  }

  // Get Claim details by claim id API
  getAllClaimById() {
    this.memberService.getAllClaimsById(this.cId).subscribe(res => {
      if (res === null) {
      } else {
        this.cres = res;
        if (res.rejectReasons === null) {
          this.reject = [];
        } else {
          this.reject = res.rejectReasons;
        }
      }
    });
  }

  // Select action from dropdown
  selection(event: any) {
    this.a = event.target.value;
    if (this.a === '1') {
      $('#myModal1').modal({
        backdrop: 'static',
        keyboard: false,
        show: true
      });
      $('#abc2').focus();
      // this.a = '0'
    }
    if (this.a === '2') {
      this.RejectApprove();
    }
    if (this.a === '3') {
      // this.prferd()
    }
    if (this.a === '4') {
      this.blok();
    }
    if (this.a === '5') {
      this.noAction();
    }
  }

  // approve
  apprv() {
    this.a = '1';
    $('#myModal1').modal({
      backdrop: 'static',
      keyboard: false,
      show: true
    });
    $('#abc2').focus();
  }

  // Preffered function
  prferd() {
    this.a = '3';
    $('#myModal1').modal({
      backdrop: 'static',
      keyboard: false,
      show: true
    });
    $('#abc2').focus();
  }

  // Block function
  blok() {
    this.a = '4';
    this.dateChange;
    $('#myModal1').modal({
      backdrop: 'static',
      keyboard: false,
      show: true
    });
    $('#abc').focus();
  }
  noAction()
  {
    $('#PA_modal').modal('hide');
    this.memberService.TakeNoAction(this.cId).subscribe(res => {
      this.notifier.notify('success', res);
      setTimeout(() => {
        this.router.navigate(['adjuster/home']);
      }, 2000);
    },
    error => {
      this.notifier.notify('error', error.error);
    });  
}
  // Approve claim API
  PostApprove(e) {
    var stDate = new Date(this.preferedDatesDto.startDate);
    var eDate = new Date(this.preferedDatesDto.endDate);
    //  e.startDate = moment(e.startDate, 'YYYY-MM-DDTHH:mm:ss.SSS').format('YYYY-MM-DD');
    //  e.endDate = moment(e.endDate, 'YYYY-MM-DDTHH:mm:ss.SSS').format('YYYY-MM-DD');
    const body = {
      claimId: this.cId,
      memberId: this.memberId,
      adjusterId: this.cres.adjusterId,
      adjusterName: this.cres.adjusterName,
      drugName: this.cres.drugName,
      startDate: stDate,
      endDate: eDate,
      ndcNumber: this.cres.drugNDCNumber
    };
    this.memberService.postApproval(body).subscribe(
      res => {
        this.notifier.notify('success', res);
        setTimeout(() => {
          this.router.navigate(['adjuster/home']);
        }, 2000);
      },
      error => {
        this.notifier.notify('error', error.error);
      }
    );
  }

  // Reject claim API
  RejectApprove() {
    this.a = '2';
    const body = {
      claimId: this.cId,
      memberId: this.memberId,
      adjusterId: this.cres.adjusterId,
      adjusterName: this.cres.adjusterName,
      drugName: this.cres.drugName
    };
    this.memberService.rejectApproval(body).subscribe(
      res => {
        this.notifier.notify('success', res);
        setTimeout(() => {
          this.router.navigate(['adjuster/home']);
        }, 2000);
      },
      error => {
        this.notifier.notify('error', error.error);
      }
    );
  }

  // Add to preffered list API
  postprefDrug(e) {
    var stDate = this.datePipe.transform(
      this.preferedDatesDto.startDate,
      'MM/dd/yyyy'
    );
    var eDate = this.datePipe.transform(
      this.preferedDatesDto.endDate,
      'MM/dd/yyyy'
    );
    var csdate = new Date();
    var cedate = new Date(csdate);
    cedate.setDate(csdate.getDate() + 3);
    const body1 = {
      claimId: this.cId,
      memberId: this.memberId,
      adjusterId: this.cres.adjusterId,
      adjusterName: this.cres.adjusterName,
      drugName: this.cres.drugName,
      startDate: stDate,
      endDate: eDate,
      ndcNumber: this.cres.drugNDCNumber
    };
    this.memberService.postApproval(body1).subscribe(res => {});
    const body = {
      gpiNumber: this.cres.gpiNumber,
      ndcNumber: this.cres.drugNDCNumber,
      drugName: this.cres.drugName,
      memberId: this.cres.memberId,
      memberName: this.cres.memberName,
      validityStartDate: stDate,
      validityEndDate: eDate
    };
    this.drugLookupService.postDrug(body).subscribe(
      res => {
        this.prior = true;
        this.notifier.notify(
          'success',
          'Successfully drug added to preferred list'
        );
        setTimeout(() => {
          this.router.navigate(['adjuster/home']);
        }, 2000);
      },
      error => {
        this.prior = true;
        this.notifier.notify('error', error.error);
      }
    );
  }

  // Block Drug API
  blockDrug() {
    var stDate = this.datePipe.transform(
      this.preferedDatesDto.startDate,
      'MM/dd/yyyy'
    );
    var eDate = this.datePipe.transform(
      this.preferedDatesDto.endDate,
      'MM/dd/yyyy'
    );

    const body1 = {
      claimId: this.cId,
      memberId: this.memberId,
      adjusterId: this.cres.adjusterId,
      adjusterName: this.cres.adjusterName,
      drugName: this.cres.drugName
    };
    this.memberService.rejectApproval(body1).subscribe(res => {});
    const body = {
      gpiNumber: this.cres.gpiNumber,
      ndcNumber: this.cres.drugNDCNumber,
      drugName: this.cres.drugName,
      memberId: this.cres.memberId,
      memberName: this.cres.memberName,
      validityStartDate: stDate,
      validityEndDate: eDate
    };
    this.drugLookupService.blockDrugs(body).subscribe(
      res => {
        this.notifier.notify('success', 'The medication has been blocked successfully ');
        setTimeout(() => {
          this.router.navigate(['adjuster/home']);
        }, 2000);
      },
      error => {
        this.notifier.notify('error', error.error);
      }
    );
  }

  // Date changes
  updateMyDate(newdate) {
    const newD_ = Object.assign(newdate.value);
    this.preferedDatesDto.endDate = new Date(newD_);
    if (this.a === '4') {
      this.preferedDatesDto.endDate.setDate(
        this.preferedDatesDto.endDate.getDate()
      );
    } else {
      this.preferedDatesDto.endDate.setDate(
        this.preferedDatesDto.endDate.getDate() + 90
      );
    }
    var stDate = this.datePipe.transform(
      this.preferedDatesDto.startDate,
      'MM/dd/yyyy'
    );
    var eDate = this.datePipe.transform(
      this.preferedDatesDto.endDate,
      'MM/dd/yyyy'
    );
  }

  // Reset function
  resetDate() {
    this.preferedDatesDto = new PreferedDates();
    location.reload();
  }

  // Date change function
  dateChange() {
    this.toDate = true;
  }

  // Get prefered drug API
  getPreferedMedicins() {
    this.medicinService.preferedDrugs(this.memberId).subscribe(res => {
      if (res.length === 0) {
        // this.pre = true
        this.drugListResult = false;
        this.preferedDrugsData = res;
      } else {
        this.drugListResult = true;
        this.preferedDrugsData = res;

        for (var i = 0; i < res.length; i++) {
          this.preferedDrugsData[i].createdDate = moment(
            this.preferedDrugsData[i].createdDate,
            'YYYY-MM-DDTHH:mm:ss.SSS'
          ).format('MM-DD-YYYY');
          this.preferedDrugsData[i].validityEndDate = moment(
            this.preferedDrugsData[i].validityEndDate,
            'YYYY-MM-DDTHH:mm:ss.SSS'
          ).format('MM-DD-YYYY');
          this.preferedDrugsData[i].validityStartDate = moment(
            this.preferedDrugsData[i].validityStartDate,
            'YYYY-MM-DDTHH:mm:ss.SSS'
          ).format('MM-DD-YYYY');
        }
      }
    });
  }

  // Get prfered doctor API
  getMemberPreferredDoctor() {
    this.doctorSercice.getPreferedPhysicians(this.memberId).subscribe(res => {
      if (res.length === 0) {
        this.docListResult = false;
        this.physicianData = res;
      } else {
        this.docListResult = true;
        this.physicianData = res;
        for (var i = 0; i < res.length; i++) {
          this.physicianData[i].createdOn = moment(
            this.physicianData[i].createdOn,
            'YYYY-MM-DDTHH:mm:ss.SSS'
          ).format('MM-DD-YYYY');
          this.physicianData[i].validityEndDate = moment(
            this.physicianData[i].validityEndDate,
            'YYYY-MM-DDTHH:mm:ss.SSS'
          ).format('MM-DD-YYYY');
          this.physicianData[i].validityStartDate = moment(
            this.physicianData[i].validityStartDate,
            'YYYY-MM-DDTHH:mm:ss.SSS'
          ).format('MM-DD-YYYY');
        }
      }
    });
  }

  // Drug Blocked List
  getDrugBlockedList() {
    this.memberDetailsService
      .getBlockedDrugList(this.memberId)
      .subscribe(res => {
        this.blkDList = res;
        this.blkDList.forEach(item => {
          item.createdDate = moment(
            item.createdDate,
            'YYYY-MM-DDTHH:mm:ss.SSS'
          ).format('MM/DD/YYYY');
        });
      });
  }

  // Phy Blocked List
  getphyBlockedList() {
    this.memberDetailsService
      .getBlockedPhyList(this.memberId)
      .subscribe(res => {
        this.blkPList = res;
        this.blkPList.forEach(item => {
          item.createdOn = moment(
            item.createdOn,
            'YYYY-MM-DDTHH:mm:ss.SSS'
          ).format('MM/DD/YYYY');
        });
      });
  }

  getAdjustId(id) {
    this.adjId = id;
  }

  // Assign claims to og FFgroup
  postClaimsToAdjusters() {
    this.memberService
      .postClaimsToAdjusters(this.clntid, this.adjId, this.memberId, this.cId)
      .subscribe(res => {
        if (res) {
          this.notifier.notify(
            'success',
            'Successfully transferred the claim.'
          );
          //this.router.navigate(['adjuster/home']);
          setTimeout(() => {
            this.router.navigate(['adjuster/home']);
          }, 2000);
        } else {
          this.notifier.notify('error', 'Could not transfer the claim.');
        }
      });
  }

  validateDate(e) {
    let aa = moment(e.startDate, 'YYYY-MM-DDTHH:mm:ss.SSS').format(
      'YYYY-MM-DD'
    );
    let b = moment(e.endDate, 'YYYY-MM-DDTHH:mm:ss.SSS').format('YYYY-MM-DD');

    if (this.a === '3') {
      //  let isAfter = moment(b).isSameOrAfter(aa);
      if (aa >= b) {
        this.notifier.notify('error', 'To date should be more then From date');
        this.expErr = true;
      } else {
        this.expErr = false;
        this.postprefDrug(e);
      }
    } else if (this.a === '4') {
      let isAfter = moment(b).isAfter(aa);
      if (isAfter) {
        this.notifier.notify('error', 'To date should be same as From date');
        this.expErr = true;
      } else {
        this.expErr = false;
        this.blockDrug();
      }
    } else {
      this.PostApprove(e);
    }
  }
}

// Claim DTO
export class cDto {
  commonUses = [];
  gpiNumber: string = '';
  adjusterId: string = '';
  adjusterName: string = '';
  commonReasons: string = '';
  cost: number;
  days: number;
  drugNDCNumber: string = '';
  drugName: string = '';
  id: number;
  memberCardHolderNumber: string = '';
  memberId: number;
  memberName: string = '';
  number: string;
  pharmacyDateTime: string = '';
  physicianNPINumber: string = '';
  physicianName: string = '';
  quantity: number;
  rejectReasons: RejectReasons;
  productDescription: string = '';
}

// RejectionReason DTO
export class RejectReasons {
  code: string = '';
  description: string = '';
}
