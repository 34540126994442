import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var PortalAdminGuard = /** @class */ (function () {
    function PortalAdminGuard(router) {
        this.router = router;
    }
    PortalAdminGuard.prototype.canActivate = function (route, state) {
        if (localStorage.getItem('token') &&
            localStorage.getItem('roleId') === '1') {
            // logged in so return true
            return true;
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/']);
        return false;
    };
    PortalAdminGuard.ngInjectableDef = i0.defineInjectable({ factory: function PortalAdminGuard_Factory() { return new PortalAdminGuard(i0.inject(i1.Router)); }, token: PortalAdminGuard, providedIn: "root" });
    return PortalAdminGuard;
}());
export { PortalAdminGuard };
