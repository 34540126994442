import { Component, OnInit } from '@angular/core';
import { LoginService } from './login.service';
import { Routes, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { LoaderService } from '../loader/loader.service';

// jQuery declaration
declare var $;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  // variables
  hide: boolean = false;
  username: any;
  password: any;
  registerForm: FormGroup;
  submitted = false;
  rollId = localStorage.getItem('rollId');
  error: any;
  loginError;
  errorStatus: boolean = false;
  locked: boolean = false;
  googleResponse;
  btn: boolean = false;
  isFromPreAppoved = localStorage.getItem('isFromPreAppoved');
  emailErrz: boolean;
  imgUrl;

  constructor(
    private notifier: NotifierService,
    private loaderService: LoaderService,
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private router: Router
  ) {}

  ngOnInit() {
    let submainName = window.location.hostname.split(".")[0];
    this.loginService.getImageUrl(submainName).subscribe(res => {
      this.imgUrl = res;
    });

    $(function() {
      $('#noSpacesField').bind('input', function() {
        $(this).val(function(_, v) {
          return v.replace(/\s+/g, '');
        });
      });
    });
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(8)]]
    });
  }

  get f() {
    return this.registerForm.controls;
  }

  // Onsubmit (login function)
  onSubmit() {
    this.submitted = true;
    //  stop here if form is invalid
    if (this.registerForm.invalid) {
      this.errorStatus = false;
      return;
    }

    // document.getElementById('myModal').style.display='block'
    this.loginService.login(this.registerForm.value).subscribe(
      res => {
        if(localStorage.getItem('navFlag') != undefined || localStorage.getItem('navFlag') === '1' ){
          this.router.navigate(['adjuster/memberprofile']);
        }else{
          if (res.roleId == 1) {
            if (!res.isPwdVerified) {
              this.router.navigate(['changepassword']);
              // document.getElementById('myModal').style.display='none'
            } else {
              this.loaderService.show();
              this.router.navigate(['admin/home']);
            }
            // document.getElementById('myModal').style.display='none'
          } else if (res.roleId == 2) {
            // for first time user
            if (!res.isPwdVerified) {
              this.router.navigate(['changepassword']);
              // document.getElementById('myModal').style.display='none'
            } else {
              this.router.navigate(['client/home']);
              // document.getElementById('myModal').style.display='none'
            }
          } else {
            if (!res.isPwdVerified) {
              this.router.navigate(['changepassword']);
              // document.getElementById('myModal').style.display='none'
            } else {
              if (this.isFromPreAppoved) {
                this.router.navigate(['adjuster/PendingAuthorizations']);
              }else {
                this.router.navigate(['adjuster/home']);
              }
  
        }
       
            // document.getElementById('myModal').style.display='none'
          }
        }
      },
      error => {
        this.errorStatus = true;
        this.error = error.error;
        // document.getElementById('myModal').style.display='none'
        this.notifier.notify('error', this.error.message);
        this.loginError = this.error.message;
        this.locked = this.error.isAccountLocked;
        if (this.locked) {
          $('#myModalz').modal('show');
        }
      }
    );
  }

  validatez() {
    const reg = /^\w+([-+.']\w+)*@\w+([-. ]\w+)*\.\w+([-. ]\w+)*$/;
    if (reg.test(this.registerForm.value.email.trim())) {
      this.emailErrz = false;
    } else {
      this.emailErrz = true;
    }
  }

  // Google captch function
  resolved(captchaResponse: string) {
    this.googleResponse = captchaResponse;
    if (this.googleResponse != null && this.registerForm.value) {
      this.btn = true;
    } else {
      this.btn = false;
    }
  }
}
