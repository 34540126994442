import { throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PhysicianLookupService = /** @class */ (function () {
    function PhysicianLookupService(http) {
        this.http = http;
        this.url = environment.apiBase;
    }
    PhysicianLookupService.prototype.getPhysicianDetails = function (member, body) {
        return this.http
            .get(this.url +
            'api/Physicians/' +
            member +
            '?firstName=' +
            body.firstName +
            '&LastName=' +
            body.lastName +
            '&speciality=' +
            body.specialization +
            '&city=' +
            body.city +
            '&state=' +
            body.state +
            '&zipCode=' +
            body.zip +
            '&npiNumber=' +
            body.npiNumber)
            .pipe();
    };
    PhysicianLookupService.prototype.createPreferedPhysician = function (body) {
        return this.http
            .post(this.url + 'api/Physicians', body, { responseType: 'text' })
            .pipe();
    };
    PhysicianLookupService.prototype.getPreferedPhysicians = function (memberId) {
        return this.http
            .get(this.url + 'api/Physicians?memberId=' + memberId)
            .pipe();
    };
    PhysicianLookupService.prototype.blockPreferedPhysician = function (body) {
        return this.http
            .put(this.url + 'api/Physicians', body, { responseType: 'text' })
            .pipe();
    };
    PhysicianLookupService.prototype.getSpecializationsList = function () {
        return this.http.get(this.url + 'api/Physicians/Specialization').pipe();
    };
    PhysicianLookupService.prototype.getGeolocation = function (body) {
        return this.http
            .get(this.url + 'api/Physicians/GeoLocation?address=' + body)
            .pipe();
    };
    PhysicianLookupService.prototype.getMemberDetails = function (memberId) {
        return this.http.get(this.url + 'api/Members?memberid=' + memberId).pipe();
    };
    PhysicianLookupService.prototype.deletePhysician = function (body) {
        var options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            body: body,
            responseType: 'text'
        };
        return this.http
            .delete(this.url + 'api/Physicians', options)
            .pipe(tap(function (res) { return res; }));
    };
    PhysicianLookupService.prototype.updatePhysician = function (body) {
        var headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.http
            .post(this.url + 'api/Physicians/UpdatePhysicianPreference', body, {
            headers: headers,
            responseType: 'text'
        })
            .pipe();
    };
    PhysicianLookupService.prototype.handleError = function (error) {
        var errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = "Error: " + error.error.message;
        }
        else {
            // server-side error
            if (error.status >= 400 && error.status < 500) {
                errorMessage = 'Action failed due to bad request';
            }
            else if (error.status >= 500) {
                errorMessage = 'Service Not Available! Please Try Later!!!';
            }
        }
        return throwError(errorMessage);
    };
    PhysicianLookupService.prototype.getPdfDownload = function (body) {
        var query = '0?firstName=' +
            body.firstName +
            '&LastName=' +
            body.lastName +
            '&speciality=' +
            body.specialization +
            '&city=' +
            body.city +
            '&state=' +
            body.state +
            '&zipCode=' +
            body.zip;
        var value = {
            key: 0,
            value: query + '&clientId=' + localStorage.getItem('clientId')
        };
        return this.http
            .post(this.url + 'api/Physicians/PhysicianResultAsPdf', value, {
            responseType: 'blob'
        })
            .pipe(catchError(this.handleError));
    };
    PhysicianLookupService.ngInjectableDef = i0.defineInjectable({ factory: function PhysicianLookupService_Factory() { return new PhysicianLookupService(i0.inject(i1.HttpClient)); }, token: PhysicianLookupService, providedIn: "root" });
    return PhysicianLookupService;
}());
export { PhysicianLookupService };
