/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./forgotpassword.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "../../../node_modules/ng-recaptcha/recaptcha/recaptcha.component.ngfactory";
import * as i5 from "ng-recaptcha/recaptcha/recaptcha.component";
import * as i6 from "ng-recaptcha/recaptcha/recaptcha-loader.service";
import * as i7 from "ng-recaptcha/recaptcha/recaptcha-settings";
import * as i8 from "@angular/router";
import * as i9 from "../../../node_modules/angular-notifier/angular-notifier.ngfactory";
import * as i10 from "angular-notifier";
import * as i11 from "./forgotpassword.component";
import * as i12 from "../login/login.service";
import * as i13 from "./forgetpassword.service";
var styles_ForgotpasswordComponent = [i0.styles];
var RenderType_ForgotpasswordComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ForgotpasswordComponent, data: {} });
export { RenderType_ForgotpasswordComponent as RenderType_ForgotpasswordComponent };
function View_ForgotpasswordComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["style", "font-size: 80%;color: #dc3545;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please enter a valid email"]))], null, null); }
export function View_ForgotpasswordComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 37, "div", [["id", "body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 34, "div", [["class", "reset-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 33, "div", [["class", "form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "login-logo form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "img-responsive img-fluid"], ["style", "cursor: pointer;width: 125px;"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 30, "div", [["class", "title-secondary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Forgot your Password?"])), (_l()(), i1.ɵeld(8, 0, null, null, 11, "div", [["class", "divreset-options text-left title-secondary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 6, "div", [["style", "margin-bottom:10px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 5, "input", [["id", "chkEmail"], ["name", "email"], ["placeholder", "Email"], ["type", "text"], ["value", "ThroughEmail"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "blur"], [null, "ngModelChange"], [null, "input"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 11)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 11)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("blur" === en)) {
        var pd_4 = (_co.validateEmail(_co.email) !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = ((_co.email = $event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(13, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(15, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForgotpasswordComponent_1)), i1.ɵdid(17, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 1, "div", [["class", "reset-options-desc"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" We will send you the instructions to your email "])), (_l()(), i1.ɵeld(20, 0, null, null, 2, "div", [["class", "form-label-group"], ["style", "margin-bottom:30px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "re-captcha", [["siteKey", "6LfexH0UAAAAABkLfl_wv-VfW6FBWvjAwsUu_XIa"]], [[1, "id", 0]], [[null, "resolved"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("resolved" === en)) {
        var pd_0 = (_co.resolved($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_RecaptchaComponent_0, i4.RenderType_RecaptchaComponent)), i1.ɵdid(22, 4374528, null, 0, i5.RecaptchaComponent, [i1.ElementRef, i6.RecaptchaLoaderService, i1.NgZone, [2, i7.RECAPTCHA_SETTINGS]], { siteKey: [0, "siteKey"] }, { resolved: "resolved" }), (_l()(), i1.ɵeld(23, 0, null, null, 2, "div", [["class", "divreset-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 1, "button", [["class", "btn btn-primary"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.forget() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Continue"])), (_l()(), i1.ɵeld(26, 0, null, null, 9, "div", [["class", "title-secondary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["or"])), (_l()(), i1.ɵeld(29, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Continue with "])), (_l()(), i1.ɵeld(31, 0, null, null, 4, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 32).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(32, 671744, null, 0, i8.RouterLinkWithHref, [i8.Router, i8.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(33, 1), (_l()(), i1.ɵeld(34, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sign In"])), (_l()(), i1.ɵeld(36, 0, null, null, 1, "notifier-container", [["class", "notifier__container"]], null, null, null, i9.View_ɵf_0, i9.RenderType_ɵf)), i1.ɵdid(37, 245760, null, 0, i10.ɵf, [i1.ChangeDetectorRef, i10.ɵg, i10.NotifierService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = "email"; var currVal_9 = _co.email; _ck(_v, 13, 0, currVal_8, currVal_9); var currVal_10 = _co.emailErr; _ck(_v, 17, 0, currVal_10); var currVal_12 = "6LfexH0UAAAAABkLfl_wv-VfW6FBWvjAwsUu_XIa"; _ck(_v, 22, 0, currVal_12); var currVal_16 = _ck(_v, 33, 0, "/"); _ck(_v, 32, 0, currVal_16); _ck(_v, 37, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.imgUrl, ""); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 15).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 15).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 15).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 15).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 15).ngClassValid; var currVal_6 = i1.ɵnov(_v, 15).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 15).ngClassPending; _ck(_v, 10, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_11 = i1.ɵnov(_v, 22).id; _ck(_v, 21, 0, currVal_11); var currVal_13 = (!_co.btn && !_co.emailErr); _ck(_v, 24, 0, currVal_13); var currVal_14 = i1.ɵnov(_v, 32).target; var currVal_15 = i1.ɵnov(_v, 32).href; _ck(_v, 31, 0, currVal_14, currVal_15); }); }
export function View_ForgotpasswordComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-drug-lookup", [], null, null, null, View_ForgotpasswordComponent_0, RenderType_ForgotpasswordComponent)), i1.ɵdid(1, 114688, null, 0, i11.ForgotpasswordComponent, [i12.LoginService, i10.NotifierService, i13.ForgotpasswordService, i8.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ForgotpasswordComponentNgFactory = i1.ɵccf("app-drug-lookup", i11.ForgotpasswordComponent, View_ForgotpasswordComponent_Host_0, {}, {}, []);
export { ForgotpasswordComponentNgFactory as ForgotpasswordComponentNgFactory };
