import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {tap} from 'rxjs/operators'
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ForgotpasswordService {
    url=environment.apiBase
    mail = encodeURIComponent(localStorage.getItem('email'))

    constructor(private http:HttpClient){

    }

    forgotpwd(mail):Observable<any>{
        return this.http.put(this.url+"api/Account/ForgotPassword?email="+mail,'').pipe(tap(res=>{

        }))
    }

}