import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { HeaderService } from '../header/header.service';
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  data: any;
  url = environment.apiBase;
  userID = null;
  private imgSource = new BehaviorSubject<any>('');
  imgUrl = this.imgSource.asObservable();

  constructor(private http: HttpClient, private router: Router,private headerService: HeaderService) {

  }

  updatedImgSource(imgUrl: any) {
    this.imgSource.next(imgUrl);
  }

  // Login service
  _userActionOccured: Subject<void> = new Subject();
  get userActionOccured(): Observable<void> {
    return this._userActionOccured.asObservable();
  }

  notifyUserAction() {
    this._userActionOccured.next();
  }

  login(body): Observable<any> {
    localStorage.setItem('email', body.email);
    var body1 = {
      email: body.email.trim(),
      password: body.password.trim()
    };

    return this.http.post(this.url + 'api/Account/Login', body1).pipe(
      tap(res => {
        body1 ={
          email:'',
          password:''
        }
        localStorage.setItem('clientId', res.clientId);
        localStorage.setItem('roleId', res.roleId);
        localStorage.setItem('token', res.token);
        localStorage.setItem('name', res.firstName);
        localStorage.setItem('first', res.isPwdVerified);
        localStorage.setItem('clientName', res.clientName);
        localStorage.setItem('userId', res.userId);
        this.headerService.updatedCompanyName(res.clientName);

        // StorageManager.

      })
    );
  }

  getImageUrl(submainName: string): Observable<any> {
    return this.http.get(this.url + `api/Account/GetLogoByDomainAsync/LogoByDomain/${submainName}`, { responseType: 'text' }).pipe(
      tap(res => {
      })
    );
  }

  getImageUrlClientId(clientId: string): Observable<any> {
    return this.http.get(this.url + `api/Clients/GetCustomerLogoByClientId/${clientId}`, { responseType: 'text' }).pipe(
      tap(res => {
      })
    );
  }


  logout() {
    localStorage.clear();
    // this.router.navigate(['/']);
  }
}
