import { throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var MemberService = /** @class */ (function () {
    function MemberService(http) {
        this.http = http;
        this.url = environment.apiBase;
        this.memberId = localStorage.getItem('mId');
        this.userId = localStorage.getItem('userId');
    }
    MemberService.prototype.setMemberId = function (memberId) {
        this.memberId = memberId;
    };
    MemberService.prototype.getMemberId = function () {
        return this.memberId;
    };
    MemberService.prototype.setSaveButton = function (saveButton) {
        this.saveButton = saveButton;
    };
    MemberService.prototype.getSaveButton = function () {
        return this.saveButton;
    };
    MemberService.prototype.getMemberQuickDetails = function (body, id) {
        return this.http
            .get(this.url +
            'api/Members/QuickSearch?adjusterUserId=' +
            id +
            '&LastName=' +
            body.lastName +
            '&FirstName=' +
            body.firstName +
            '&MemberCardNumber=' +
            body.memberCardNumber +
            '&ClaimNumber=' +
            body.claims +
            '&DateOfBirth=' +
            body.dob +
            '&SSN=' +
            body.last4DigitsofoSSN +
            '&isAssigned=' + body.isAssigned)
            .pipe(tap(function (res) { }));
    };
    MemberService.prototype.getPin = function (id, pin) {
        return this.http
            .get(this.url + '/api/Clients/Pin/' + id + '/' + pin)
            .pipe(tap(function (res) { }));
    };
    MemberService.prototype.getChosenPin = function (id, chosenPin) {
        var headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.http
            .post(this.url + 'api/Clients/GetChosenPin/' + id + '/' + chosenPin, { headers: headers, responseType: 'text' })
            .pipe(tap(function (res) { }));
    };
    MemberService.prototype.getAllMemberQuickDetails = function (body) {
        return this.http
            .get(this.url + 'api/Members/QuickSearch?adjusterUserId=' + body.userId +
            '&isAssigned=' + body.isAssigned)
            .pipe(catchError(this.handleError));
    };
    MemberService.prototype.getClientByClaim = function (id) {
        return this.http
            .get(this.url + 'api/Members/GetFfGroupClientId?claimId=' + id)
            .pipe(catchError(this.handleError));
    };
    MemberService.prototype.postMemberDetails = function (body) {
        return this.http.post(this.url + 'api/Members', body).pipe(tap(function (res) { }));
    };
    MemberService.prototype.postClaimsToAdjusters = function (cId, aId, mId, clId) {
        return this.http
            .put(this.url +
            'api/Members/AddMemberToAdjuster?clientId=' +
            cId +
            '&adjusterUserId=' +
            aId + '&memberId=' +
            mId + '&claimId=' + clId, '')
            .pipe(tap(function (res) { }));
    };
    MemberService.prototype.updateMemberDetails = function (body) {
        var headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.http
            .post(this.url + 'api/Members', body, { headers: headers, responseType: 'text' })
            .pipe(tap(function (res) { }));
    };
    MemberService.prototype.updateSMSSubscribe = function (memberid, phonenumber) {
        var headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.http
            .post(this.url + 'api/Members/SMSSubscribe/' + memberid + '/' + phonenumber, { headers: headers, responseType: 'text' })
            .pipe(tap(function (res) { }));
    };
    MemberService.prototype.getAllMemberDetails = function (body) {
        return this.http
            .get(this.url + 'api/Members/MembersList?adjusterUserId=' + body.adjId +
            '&showAll=' + body.showAll)
            .pipe(tap(function (res) { }));
    };
    //
    MemberService.prototype.getByMemberId = function (member) {
        return this.http
            .get(this.url + 'api/Members?memberid=' + member)
            .pipe(tap(function (res) { }));
    };
    MemberService.prototype.deleteMember = function (id) {
        return this.http
            .put(this.url + 'api/Members/Delete?id=' + id, '')
            .pipe(tap(function (res) { }));
    };
    MemberService.prototype.getImmediateAttention = function (body) {
        return this.http
            .get(this.url + 'api/Users/ItemsNeedImmediateAttention?adjusterUserId=' + body.adjusterUserId +
            '&isAssigned=' + body.isAssigned +
            '&searchText=' + body.searchText)
            .pipe(tap(function (res) { }));
    };
    MemberService.prototype.getRecentActivity = function (id) {
        return this.http
            .get(this.url + 'api/Users/RecentActivities?adjusterUserId=' + id)
            .pipe(tap(function (res) { }));
    };
    MemberService.prototype.get = function () {
        return this.http
            .get(this.url + 'api/Users/ItemsNeedImmediateAttention')
            .pipe(tap(function (res) { }));
    };
    MemberService.prototype.getMemberTreatedPhysican = function (memberId) {
        return this.http
            .get(this.url + 'api/Members/' + memberId + '/LatestClaim')
            .pipe(catchError(this.handleError));
    };
    MemberService.prototype.getMemberNotesByMemberId = function (memberId) {
        return this.http
            .get(this.url + 'api/Members/' + memberId + '/Notes')
            .pipe(catchError(this.handleError));
    };
    MemberService.prototype.getActivityByMemberId = function (memberId, filter) {
        return this.http
            .get(this.url +
            'api/Members/' +
            memberId +
            '/CurrentActivity?filterId=' +
            filter)
            .pipe(catchError(this.handleError));
    };
    MemberService.prototype.getRejectionClaimsByMemberId = function (memberId, startDate, endDate) {
        return this.http
            .get(this.url +
            'api/Members/' +
            memberId +
            '/RejectedClaims?startDate=' +
            startDate +
            '&endDate=' +
            endDate)
            .pipe(catchError(this.handleError));
    };
    MemberService.prototype.saveMemberNote = function (body) {
        var headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.http
            .post(this.url + 'api/Members/Notes', body, {
            headers: headers,
            responseType: 'text'
        })
            .pipe(catchError(this.handleError));
    };
    MemberService.prototype.updateMemberNote = function (body) {
        var headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.http
            .put(this.url + 'api/Members/Notes', body, {
            headers: headers,
            responseType: 'text'
        })
            .pipe(catchError(this.handleError));
    };
    MemberService.prototype.deleteMemberNote = function (noteId) {
        return this.http
            .delete(this.url + 'api/Members/Notes?memberNotesId=' + noteId, {
            responseType: 'text'
        })
            .pipe(tap(function (res) { }));
    };
    MemberService.prototype.TakeNoAction = function (claimId) {
        return this.http
            .put(this.url + 'api/Claims/TakeNoAction?claimId=' + claimId, '', { responseType: 'text' })
            .pipe();
    };
    MemberService.prototype.getAllClaimsById = function (body) {
        return this.http.get(this.url + 'api/Claims/' + body).pipe();
    };
    MemberService.prototype.postApproval = function (body) {
        return this.http
            .put(this.url + 'api/Claims/Approve', body, { responseType: 'text' })
            .pipe();
    };
    MemberService.prototype.rejectApproval = function (body) {
        return this.http
            .put(this.url + 'api/Claims/Reject', body, { responseType: 'text' })
            .pipe();
    };
    MemberService.prototype.deleteMemberClaimHistoryLog = function (memberClaimId) {
        return this.http
            .delete(this.url +
            'api/Members/ClaimHistoryLog?memberClaimHistoryId=' +
            memberClaimId, { responseType: 'text' })
            .pipe(catchError(this.handleError));
    };
    MemberService.prototype.handleError = function (error) {
        var errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = "Error: " + error.error.message;
        }
        else {
            // server-side error
            if (error.status >= 400 && error.status < 500) {
                errorMessage = 'Action failed due to bad request';
            }
            else if (error.status >= 500) {
                errorMessage = 'Service Not Available! Please Try Later!!!';
            }
        }
        return throwError(errorMessage);
    };
    MemberService.prototype.getClaimByNDCNumber = function (memId, num) {
        return this.http
            .get(this.url +
            'api/Members/' +
            memId +
            '/GetLatestClaimByDrug?drugNDCNumber=' +
            num)
            .pipe(tap(function (res) { }));
    };
    MemberService.prototype.getGroupsById = function (id) {
        return this.http
            .get(this.url + 'api/Clients/AdjusterGroups/' + id)
            .pipe(tap(function (res) { }));
    };
    MemberService.prototype.getAdjusterByClientId = function (id, adj) {
        return this.http
            .get(this.url + 'api/Clients/LoadAdjusters/' + id + '?adjusterId=' + adj)
            .pipe(tap(function (res) { }));
    };
    MemberService.prototype.saveVacation = function (body) {
        var headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.http
            .post(this.url + 'api/Clients/Vacation', body, {
            headers: headers,
            responseType: 'text'
        })
            .pipe();
    };
    MemberService.prototype.getVacationData = function (id, adj) {
        return this.http
            .get(this.url +
            'api/Clients/LoadVacationData?clientId=' +
            id +
            '&AdjusterId=' +
            adj)
            .pipe(tap(function (res) { }));
    };
    MemberService.prototype.getAllAdjusterByClientId = function (id, adj) {
        return this.http
            .get(this.url +
            'api/Clients/LoadAllVacationAdjusters/' +
            id +
            '?adjusterId=' +
            adj)
            .pipe(tap(function (res) { }));
    };
    MemberService.prototype.getAllClients = function () {
        return this.http.get(this.url + 'api/Clients').pipe(tap(function (res) { }));
    };
    MemberService.prototype.getItemcall = function () {
        return this.item;
    };
    MemberService.prototype.setItemcall = function (val) {
        this.item = val;
    };
    MemberService.ngInjectableDef = i0.defineInjectable({ factory: function MemberService_Factory() { return new MemberService(i0.inject(i1.HttpClient)); }, token: MemberService, providedIn: "root" });
    return MemberService;
}());
export { MemberService };
