import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var RegisterPortalAdminService = /** @class */ (function () {
    function RegisterPortalAdminService(http) {
        this.http = http;
        this.url = environment.apiBase;
    }
    RegisterPortalAdminService.prototype.registerPortalAdmin = function (body) {
        return this.http
            .post(this.url + 'api/Account/PortalAdmin', body, {
            responseType: 'text'
        })
            .pipe(catchError(function (err) {
            return throwError(err);
        }));
    };
    RegisterPortalAdminService.ngInjectableDef = i0.defineInjectable({ factory: function RegisterPortalAdminService_Factory() { return new RegisterPortalAdminService(i0.inject(i1.HttpClient)); }, token: RegisterPortalAdminService, providedIn: "root" });
    return RegisterPortalAdminService;
}());
export { RegisterPortalAdminService };
