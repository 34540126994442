import { Component, OnInit } from '@angular/core';
import { ForgotpasswordService } from './forgetpassword.service';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { LoginService } from '../login/login.service';

@Component({
  selector: 'app-drug-lookup',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
  // variables
  googleResponse;
  btn: boolean = false;
  email;
  error: any;
  loginError: any;
  emailErr: boolean = false;
  imgUrl: any;

  constructor(
    private loginService: LoginService,
    private notifier: NotifierService,
    private forgotpasswordService: ForgotpasswordService,
    private router: Router
  ) {}

  ngOnInit() {
    let submainName = window.location.hostname.split(".")[0];
    this.loginService.getImageUrl(submainName).subscribe(res => {
      this.imgUrl = res;
    });
  }

  // Forgot password API
  forget() {
    const body = decodeURIComponent(this.email);
    this.forgotpasswordService.forgotpwd(body).subscribe(
      res => {},
      error => {
        this.error = error;
        this.loginError = this.error.status;
        if (this.loginError == 400) {
          this.notifier.notify('error', this.error.error);
        }
        if (this.loginError == 200) {
          this.notifier.notify('success', this.error.error.text);
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 2000);
        } else {
        }
      }
    );
  }

  // Google captcha API
  resolved(captchaResponse: string) {
    this.googleResponse = captchaResponse;
    if (this.googleResponse != null && this.email) {
      this.btn = true;
    } else {
      this.btn = false;
    }
  }

  // Validation starts
  validateEmail(e) {
    var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (re.test(e)) {
      this.emailErr = false;
    } else {
      this.emailErr = true;
    }
  }

  // validation ends
}
