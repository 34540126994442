<!-- LOGIN SECTION START -->
<section class="login-section">
  <div class="container-fluid" id="body">
    <div class="row no-gutters">
      <!-- LOGIN FORM SECTION START -->
      <div class="col-12 col-md-12 col-lg-5 login-wrapper">
        <div class="login-inner">
          <div>
            <a href="#" id="logo">
              <img alt="CompPBM - PHARMACY MANAGEMENT" />
            </a>
            <h2>Welcome to your Pharmacy Manager!</h2>
            <div class="login-form">
              <form [formGroup]="registerForm" (ngSubmit)="!emailErrz && onSubmit()">
                <p>Login to your Account</p>
                <div class="form-group">
                  <input type="text" placeholder="Email" formControlName="email" (blur)="validatez()"
                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                  <div *ngIf="submitted  && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                  </div>
                  <div *ngIf="!f.email.errors">
                    <div *ngIf="emailErrz" style="margin-top: 2px;color: red">Please enter a vaild email in the
                      following format: 'abc@email.com'</div>
                  </div>
                </div>
                <div class="form-group">
                  <input placeholder="password" formControlName="password" class="form-control"
                    [type]="hide ? 'text' : 'password'" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                  <!-- <span class="input-group-text" style="cursor: pointer; background: #453f40; border:none; color: white; position:absolute;top:0;right: -1px;padding: 0 4px;height:34px;"> 
                        <mat-icon matSuffix class="md-18 pointer-hand" >{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                    </span>-->
                  <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">Password is required</div>
                  </div>
                  <div *ngIf="!f.valid">
                    <div *ngIf="errorStatus && !locked && !emailErrz" style="margin-top: 2px;color: red">
                      {{loginError}}
                    </div>
                  </div>
                </div>
                <p><a class="forgot-pw float-right" [routerLink]="['/forgotpassword']">Forgot Password?</a></p>
                <div class="form-group float-right">
                </div>
                <div class="reCaptcha" id="cid">
                  <!-- <img src="assets/images/robot.png" width="200" alt="captcha"> -->
                  <re-captcha (resolved)="resolved($event)" siteKey="6LfexH0UAAAAABkLfl_wv-VfW6FBWvjAwsUu_XIa">
                  </re-captcha>
                </div>
                <button [disabled]="!btn" class="btn btn-primary btn-block">Login</button>
              </form>
            </div>
            <div class="text-center">
              <h5>Browser Recommendations</h5>
              <p class="login-info">To use the CompPBM portal, we recommend <br>
                you to use Google Chrome Version 68.0 or higher.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- LOGIN FORM SECTION END -->
      <!-- RIGHT SLIDER START -->
      <div class="col-12 col-md-12 col-lg-7 banner-section">
        <!-- Desktop Slider -->
        <div id="carouselExampleSlidesOnly" class="carousel slide m-hide" data-ride="carousel" data-interval="30000">
          <div class="carousel-inner">
            <div class="carousel-item active"
              style="background-image: url('assets/images/PIlls-in-Hand_Optimized.jpg')"></div>
            <div class="carousel-item" style="background-image: url('assets/images/SignIn-Option3.jpg')"></div>
            <div class="carousel-item" style="background-image: url('assets/images/BlueWhitePills_Optimized.jpg')">
            </div>
          </div>
        </div>

        <!-- Mobile Slider -->
        <div id="carouselExampleSlidesOnly" class="carousel slide m-show" data-ride="carousel" data-interval="30000">
          <div class="carousel-inner">
            <div class="carousel-item active" style="background-image: url('assets/images/slider-1-mob.jpg')"></div>
            <div class="carousel-item" style="background-image: url('assets/images/slider-2-mob.jpg')"></div>
            <div class="carousel-item" style="background-image: url('assets/images/slider-3-mob.jpg')"></div>
          </div>
        </div>


      </div>
      <!-- RIGHT SLIDER END -->
    </div>
  </div>
</section>
<!-- LOGIN SECTION END -->