import { NgModule } from '@angular/core';

import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './guard/auth.guard';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { PortalAdminGuard } from './guard/portalAdmin.guard';
import { GeneratepasswordComponent } from './generatepaasword/generatepassword.component';
import { AdjusterGuard } from './guard/adjusters.guard';
import { CardComponent } from './card/card.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { ServiceDown } from './service-down.component';
// import { ContactusComponent } from './contactus/contactus.component';
import { PreApprovedComponent } from './preApproved/preApproved.component';
import { ViewMemberComponent } from './Adjuster/view-member/view-member.component';
import { RegisterPortalAdminComponent } from './registerportaladmin/registerportaladmin.component';

// import { ViewerComponent } from './viewer/viewer.component';
import  {  TelerikReportingModule  }  from  '@progress/telerik-angular-report-viewer';
export const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'changepassword', component: ChangepasswordComponent },
  { path: 'generatenewpassword/:id', component: GeneratepasswordComponent },
  { path: 'PreApprovedClaim/:id', component: PreApprovedComponent },
  { path: 'PreApprovedClaim', component: PreApprovedComponent },
  { path: 'generatenewpassword', component: GeneratepasswordComponent },
  { path: 'forgotpassword', component: ForgotpasswordComponent },
  { path: 'card', component: CardComponent },
  { path: 'serviceDown', component: ServiceDown },
  { path: 'unsubscribe', component: UnsubscribeComponent },
  // { path: 'viewer', component: ViewerComponent },
  {
    path: 'admin',
    loadChildren: './Admin/admin.module#AdminModule',
    canActivate: [PortalAdminGuard]
  },
  {
    path: 'adjuster',
    loadChildren: './Adjuster/adjuster.module#AdjusterModule',
    canActivate: [AdjusterGuard]
  },
  {
    path: 'client',
    loadChildren: './Client/client.module#ClientModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'portaladmin',
    component: RegisterPortalAdminComponent
  }
  // { path: 'contactus', component: ContactusComponent },
];

@NgModule({
  imports: [
    TelerikReportingModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
