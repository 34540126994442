import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UnsubscribeService } from './unsubscribe.service';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.css']
})
export class UnsubscribeComponent implements OnInit {
  //Variables
  data = [];
  userId = '';
  success: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private unsubscribeService: UnsubscribeService
  ) {
    this.route.params.subscribe();
  }

  ngOnInit() {
    this.userId = this.route.snapshot.queryParamMap.get('UserId');
    if (this.userId === '') {
    } else {
      this.router.navigate(['unsubscribe']);
      this.Unsuscribe();
    }
  }

  //Unsubsribe function
  Unsuscribe() {
    this.unsubscribeService.getUnscbscription(this.userId).subscribe(res => {
      this.data = res.errorMessage;
      this.success = true;
    });
  }
}
