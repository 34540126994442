import { throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CardService = /** @class */ (function () {
    function CardService(http) {
        this.http = http;
        this.url = environment.apiBase;
    }
    CardService.prototype.getMemberCardTemplate = function (Id) {
        return this.http
            .get(this.url + 'api/MemberCard/GetCard?id=' + Id)
            .pipe(catchError(this.handleError));
    };
    CardService.prototype.handleError = function (error) {
        var errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = "Error: " + error.error.message;
        }
        else {
            // server-side error
            if (error.status >= 400 && error.status < 500) {
                errorMessage = 'Action failed due to bad request';
            }
            else if (error.status >= 500) {
                errorMessage = 'Action failed due to server side issue';
            }
        }
        return throwError(errorMessage);
    };
    CardService.ngInjectableDef = i0.defineInjectable({ factory: function CardService_Factory() { return new CardService(i0.inject(i1.HttpClient)); }, token: CardService, providedIn: "root" });
    return CardService;
}());
export { CardService };
