import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegisterPortalAdminService {
  constructor(private http: HttpClient) {}
  url = environment.apiBase;

  registerPortalAdmin(body): Observable<any> {
    return this.http
      .post(this.url + 'api/Account/PortalAdmin', body, {
        responseType: 'text'
      })
      .pipe(
        catchError(err => {
          return throwError(err);
      }));
  }  
}
