import { Component, OnInit, Input,Output,EventEmitter } from '@angular/core';

import { HeaderService } from './header.service';
import { Router } from '@angular/router';
import { LoginService } from '../login/login.service';

// jQuery declaration
declare var $;
declare var document: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  // variables
  chosenPin: string;
  pin = "1234";
  @Input('parentData') adminPage: boolean;
  @Input('parentData1') adminPage1: boolean;  
  @Output() isMenuCollapsed = new EventEmitter<boolean>();
  isResposive = false;
  showHead: boolean = false;
  showHeads: boolean = false;
  _isMenuCollapsed: boolean = false;
  activeNavMenu: boolean=false;
  isMobileMenuToggle:boolean=false;
  subPath;
  role = localStorage.getItem('roleId');
  name = localStorage.getItem('name');
  company : any;
  messages: any;
  userId: any;
  imgUrl: any;

  constructor(
    private headerService: HeaderService,
    private router: Router,
    private loginService: LoginService
  ) {}

  ngOnInit() {    
    if (this.role === '2' || this.role === '3' || this.role === '4'|| this.role === '5' || this.role === '6') {
      this.loadScript();
    }
    this.userId = localStorage.getItem('userId');    
  }
  ngOnChanges(changes: any) {    
    var clintHome = localStorage.getItem('clintHome');
    if (this.adminPage1) {
      localStorage.setItem('adjId', clintHome);
    }

    if (
      localStorage.getItem('roleId') === '1' ||
      localStorage.getItem('roleId') === '2'
    ) {
      this.userId = localStorage.getItem('userId');
    }

    if ((localStorage.getItem('roleId') === '1' && (this.adminPage || this.adminPage1)) || (localStorage.getItem('roleId') === '2' && this.adminPage1)) {
      this.userId = localStorage.getItem('adjId');
    }

    if (localStorage.getItem('roleId') === '3' || localStorage.getItem('roleId') === '4' || localStorage.getItem('roleId') === '5'|| localStorage.getItem('roleId') === '6' ) {
      this.userId = localStorage.getItem('userId');
    }

    this.headerService.newsCount.subscribe(userCartItemCount => {
      this.messages = userCartItemCount;
    });
    this.headerService.companyName.subscribe(companyName => {
      this.company = companyName;
    });
  

    this.headerService.getUnreadNews(this.userId).subscribe(x => {
      this.headerService.updatedNewsCount(x);
    });
  }
  ngAfterViewInit() {
    this.loginService.imgUrl.subscribe(newImageUrl => {
      this.imgUrl = newImageUrl;
    });
  }

  // Check for responsive screen
  myFunction() {
    if (this.isResposive) {
      this.isResposive = false;
    } else {
      this.isResposive = true;
    }
  }
 toggleMenu(){  
  this._isMenuCollapsed =!this._isMenuCollapsed;
   this.isMenuCollapsed.emit(this._isMenuCollapsed);
 }
  news() {
    //    setTimeout(() => {
    //      location.reload()
    //  }, 1000);
  }
  showPin(){   
    this.headerService.getPin(this.userId, "0").subscribe(res => {
      this.pin = res["pin"];
    });
  }
  CollapseExpandMobile(){    
    $('.aside').toggleClass('asideExpend')
  }
  //Change logo
  changeLogo(){
    let submainName = window.location.hostname.split(".")[0];
    this.loginService.getImageUrl(submainName).subscribe(res => {
      this.loginService.updatedImgSource(res);
    });
  }
  changeActiveClass(){
    this.activeNavMenu=!this.activeNavMenu;
  }

  // Logout functionality
  logout() {
    localStorage.clear();
    var out = '1';
    localStorage.setItem('logOut', out);
  }
  regeneratePin() {
    this.headerService.getPin(this.userId, 1).subscribe(res => {
      this.pin = res;
    });
  }
  getChosenPin(chosenPin) {
     if(chosenPin){
      this.headerService.getChosenPin(this.userId, this.chosenPin).subscribe(res => {
        this.pin = res["choosenpin"];
        this.chosenPin = null
      });
    }
  }
  // Chat application
  loadScript() {
    // load script
    return new Promise((resolve, reject) => {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src =
        '//storage.googleapis.com/code.snapengage.com/js/329f56c3-4527-4814-b8a5-4f5aabb1f2cd.js';
      if (script.readyState) {
        // IE
        script.onreadystatechange = () => {
          if (
            script.readyState === 'loaded' ||
            script.readyState === 'complete'
          ) {
            script.onreadystatechange = null;
            resolve({ loaded: true, status: 'Loaded' });
          }
        };
      } else {
        // Others
        script.onload = () => {
          resolve({ loaded: true, status: 'Loaded' });
        };
      }
      script.onerror = (error: any) =>
        resolve({ loaded: false, status: 'Loaded' });
      document.getElementsByTagName('script')[0].appendChild(script);
    });
  }
  asideNavFunction(){
    $(".divlayout").toggle("aside-collapse")
  }
}
